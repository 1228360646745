import { Container, Row, Col, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Footer= () => {
    const currentYear = new Date().getFullYear();

  return (
    <footer className="d-none d-md-flex d-lg-flex d-xl-flex fixed block w-full bottom-0 p-0 m-0 text-gray-300 text-xs
                        max-md:bg-cl_bg_1 max-sm:bg-cl_bg_1 max-sm:backdrop-blur-sm max-md:backdrop-blur-sm persianFont
                        lg:bg-transparent xl:bg-transparent 2xl:bg-transparent ">
        <Container>
            <Row dir="ltr" className="py-2">
                <Col  className=" text-left ">
                  <div className="flex bg-cl_bg_6 backdrop-blur-lg w-fit rounded-lg persianFont">
                    <p className="px-2">PARS Electric Manufacturing Company  &copy; 2022 - { currentYear } </p>
                    <span>|</span>
                    <span className="px-2">
                      <LinkContainer to='/sitemap' className="hover:text-sky-400">
                        <NavDropdown.Item>
                          Site Map
                        </NavDropdown.Item>
                      </LinkContainer>
                    </span>
                  </div>
                </Col>

            </Row>
        </Container>
    </footer>
  )
}

export default Footer