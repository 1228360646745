import { Link, useLocation } from 'react-router-dom';
import { GrNext } from "react-icons/gr";

function Breadcrumbs() {

    const location = useLocation();
    const { pathname } = location;
    const segments = pathname.split('/');

    let url = '';
    const breadcrumbLink = segments.slice(0,-1).map((segment, i) => {
        url += `${segment}`;

        return (
            <>
                <Link rel="preload" key={i} to={`https://parselectric.ir/${url}`} className='inline-flex persianFont align-middle'>
                    {segment === '' ? (
                        <>
                            Home
                        </>
                        
                        ) : (
                            <div className='flex align-middle'>
                                <GrNext className='mx-2'/>{segment}
                            </div>
                            )}
                </Link>
            </>
        );
        
    });
  return breadcrumbLink;
};

export default Breadcrumbs;