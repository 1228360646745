import React, { useState } from 'react';
import { Topbar } from '../../components';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, Nav, Tab, Table, Button } from 'react-bootstrap';
import Message from '../../components/navigation/Message';
import Loader from '../../components/Loader';
import { useGetOrdersQuery } from '../../slices/ordersApiSlice';

import { IoIosCloseCircle } from "react-icons/io";

const OrderListPage = () => {

    const { data: orders, isLoading, error } = useGetOrdersQuery();


    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 bg-slate-900 overflow-scroll persianFont 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar '
            onScroll={handleScroll}
        >
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                        <Nav.Link eventKey="first">سفارشات</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="second">بزودی</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                    <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <h1>سفارشها</h1>
                            {
                                isLoading ? <Loader/> : error ? 
                                    <Message variant='danger'>{error}</Message> : (
                                        <Table striped hover responsive className="table-sm table-dark text-sm text-center align-middle  mt-2">
                                            <thead>
                                               <tr>
                                                    <th>id</th>
                                                    <th>کاربر</th>
                                                    <th>تاریخ</th>
                                                    <th>کل</th>
                                                    <th>وضعیت پرداخت</th>
                                                    <th>وضعیت ارسال</th>
                                                    <th></th>
                                                </tr> 
                                            </thead>
                                            <tbody>
                                                { orders.map((order) => (
                                                    <tr key={ order._id }>
                                                        <td>{ order._id }</td>
                                                        <td>{ order.user && order.user.name }</td>
                                                        <td>{ new Date (order.createdAt).toLocaleString('fa-IR', { timeZone: 'Asia/Tehran' })}</td>
                                                        <td>{ order.totalPrice }</td>
                                                        <td>
                                                        { order.isPaid ? (new Date(order.paidAt).toLocaleString('fa-IR', { timeZone: 'Asia/Tehran' })) : (<IoIosCloseCircle size={18} className="m-auto text-red-700"/>) }
                                                        </td>
                                                        <td>{ order.isDelivered ? (new Date(order.deliveredAt).toLocaleString('fa-IR', { timeZone: 'Asia/Tehran' })) : (<IoIosCloseCircle size={18} className="m-auto text-red-700"/>) }</td>
                                                        <td>
                                                            <LinkContainer to={`/order/${order._id}`}>
                                                                <Button className='btn-sm text-xs py-0' variant="light">
                                                                    جزئیات
                                                                </Button>
                                                            </LinkContainer>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )
                            }
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            ...
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </div>

        

    </>
  )
}

export default OrderListPage