import { useNavigate } from 'react-router-dom';
import { Badge, Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLogoutMutation } from '../../slices/userApiSlice';
import { logout } from '../../slices/authSlice';
// import { Logo } from '../../svg'
import { useSelector, useDispatch } from 'react-redux';
import Pars from '../svg/Pars';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { MdRoofing } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { RiShoppingBag4Fill } from "react-icons/ri";
import { AiFillProduct } from "react-icons/ai";
import { FaUsersCog } from "react-icons/fa";
import { FaCartFlatbed } from "react-icons/fa6";
import { TfiPencilAlt } from "react-icons/tfi";
import { BsHouseGearFill } from "react-icons/bs";
import { MdSupportAgent } from "react-icons/md";
import { LiaSignOutAltSolid } from "react-icons/lia";

const Header = () => {

    const { cartItems } = useSelector((state) => state.cart);
    const { userInfo } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [logoutApiCall] = useLogoutMutation();

    const logoutHandler = async () => {
        try {
            await logoutApiCall().unwrap();
            dispatch(logout());
            navigate('/login');
        } catch (err) {
            console.log(err);
        }
    };


  return (
    <header>
        <Navbar dir='ltr' 
            className={`fixed max-sm:top-0 max-md:top-0 z-10
                        transition-all w-screen py-1 pt-md-5 pt-xl-5 justify-content-center persianFont font-bold   
                         max-sm:bg-transparent max-md:bg-transparent persianFont
                        lg:bg-transparent xl:bg-transparent 2xl:bg-transparent persianFont`}
            expand="md"
            collapseOnSelect
        >
            <Container >
                <LinkContainer to="/">
                    <Navbar.Brand className='mr-16 ml-0 w-24'>
                        <Pars style={{ width: '100%',  mixBlendMode:'difference' }}/>
                    </Navbar.Brand>
                </LinkContainer>
                {/* <Navbar.Toggle aria-controls='basic-navbar-nav' className='custom-toggler'/> */}
                <Navbar.Collapse id="basic-navbar-nav" className="max-sm:p-14 max-md:p-14 persianFont">
                    <Nav dir='rtl' className='!text-slate-200 hidden md:flex lg:flex xl:flex 2xl:flex persianFont'>
                        
                        <LinkContainer to="/cart">
                            <Nav.Link rel="preload" className='drop-shadow-[0px_0px_1px_var(--tw-shadow-color)] shadow-black !text-slate-300 hover:!text-white font-light my-auto'>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" >سبد خرید</Tooltip>}>
                                    <span className="my-auto">
                                        <RiShoppingBag4Fill  size={20}/>
                                    </span>
                                </OverlayTrigger>
                                {
                                    cartItems.length > 0 && (
                                        <Badge pill bg='success' className='mr-1'>
                                            { cartItems.reduce((a, c) => a + c.qty, 0) }
                                        </Badge>
                                    )
                                }
                            </Nav.Link>
                        </LinkContainer>
                        { userInfo && !userInfo.isAdmin && (
                            <NavDropdown title={ userInfo.name } id='username' className='persianFont drop-shadow-[0px_0px_1px_var(--tw-shadow-color)] shadow-black !text-slate-200 hover:!text-white font-thin '>
                                <LinkContainer to='/profile'>
                                    <NavDropdown.Item className='flex persianFont  font-thin text-end text-slate-200 group/edit'>
                                        <CgProfile  className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                        <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>پروفایل</span>
                                        
                                    </NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Item onClick={ logoutHandler } className='flex persianFont font-thin text-end text-slate-200 group/edit'>
                                    <LiaSignOutAltSolid   className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                    <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>خروج</span>
                                    
                                </NavDropdown.Item>
                            </NavDropdown>
                            
                        ) 

                        }
                        {/* ADMIN LOGEDIN */}
                        { userInfo && userInfo.isAdmin && (

                            <NavDropdown title='Admin' id='adminmenu'>
                                <LinkContainer to='/admin/productlist'>
                                    <NavDropdown.Item className='flex persianFont font-thin text-end text-slate-200 group/edit'>
                                        {/* <span className='group-hover/edit:ml-2 symbol text-slate-200 ml-1 transition-all'> </span> */}
                                        <AiFillProduct className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                        <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>محصولات</span>
                                        
                                    </NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/userlist'>
                                    <NavDropdown.Item className='flex persianFont font-thin text-end text-slate-200 group/edit'>
                                        {/* <span className='group-hover/edit:ml-2 symbol text-slate-200 ml-1 transition-all'> </span> */}
                                        <FaUsersCog className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                        <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>کاربرها</span>
                                        
                                    </NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/orderlist'>
                                    <NavDropdown.Item className='flex persianFont font-thin text-end text-slate-200 group/edit'>
                                        {/* <span className='group-hover/edit:ml-2 symbol text-slate-200 ml-1 transition-all'>󰦐 </span> */}
                                        <FaCartFlatbed className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                        <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>سفارشها</span>
                                        
                                    </NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/eventlist'>
                                    <NavDropdown.Item className='flex persianFont font-thin text-end text-slate-200 group/edit'>
                                        {/* <span className='group-hover/edit:ml-2 symbol text-slate-200 ml-1 transition-all'>󰎕 </span> */}
                                        <TfiPencilAlt className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                        <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>رویدادها</span>
                                    </NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/generallist'>
                                    <NavDropdown.Item className='flex persianFont font-thin text-end text-slate-200 group/edit'>
                                        {/* <span className='group-hover/edit:ml-2 symbol text-slate-200 ml-1 transition-all'> </span> */}
                                        <BsHouseGearFill className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                        <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>اطلاعات پایه</span> 
                                    </NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/admin/agentlist'>
                                    <NavDropdown.Item className='flex persianFont  font-thin text-end text-slate-200 group/edit'>
                                        {/* <span className='group-hover/edit:ml-2 symbol text-slate-200 ml-1 transition-all'>󰵰 </span> */}
                                        <MdSupportAgent  className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                        <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>نمایندگان</span>
                                        
                                    </NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/profile'>
                                    <NavDropdown.Item className='flex persianFont  font-thin text-end text-slate-200 group/edit'>
                                    {/* <span className='group-hover/edit:ml-2 symbol text-slate-200 ml-1 transition-all'> </span> */}
                                    <CgProfile  className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                    <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>پروفایل</span>
                                    
                                </NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Item onClick={ logoutHandler } className='flex persianFont  font-thin text-end text-slate-200 group/edit'>
                                    {/* <span className='group-hover/edit:ml-2 symbol text-slate-200 ml-1 transition-all'> </span>خروج */}
                                    <LiaSignOutAltSolid   className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                    <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>خروج</span>
                                    
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                        {/* normal user logedin */}
                        { (userInfo && !userInfo.isAdmin ) && (
                            <>
                                <LinkContainer to="/product">
                                    <Nav.Link rel="preload" className='persianFont drop-shadow-[0px_0px_1px_var(--tw-shadow-color)] shadow-black !text-slate-300 hover:!text-white my-auto'>
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" >محصولات</Tooltip>}>
                                            <span className="my-auto">
                                                <AiFillProduct  size={20}/>
                                            </span>
                                        </OverlayTrigger>    
                                    </Nav.Link>
                                </LinkContainer>
                                {/* <LinkContainer to="/">
                                    <Nav.Link className='persianFont drop-shadow-[0px_0px_15px_var(--tw-shadow-color)] shadow-black !text-slate-300 hover:!text-white font-semibold'>
                                        اطلاعیه
                                    </Nav.Link>
                                </LinkContainer> */}
                                <LinkContainer to="/">
                                    <Nav.Link rel="preload" className='drop-shadow-[0px_0px_15px_var(--tw-shadow-color)] shadow-black !text-slate-300 hover:!text-white my-auto'>
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">خانه</Tooltip>}>
                                            <span className="my-auto">
                                                <MdRoofing  size={20}/>
                                            </span>
                                        </OverlayTrigger>
                                    </Nav.Link>
                                </LinkContainer>
                            </>
                        )}
                        {/* No user logedin */}
                        { (!userInfo || (!userInfo && !userInfo.isAdmin )) && (
                            <>
                                <LinkContainer to="/login">
                                    <Nav.Link rel="preload" className='drop-shadow-[0px_0px_1px_var(--tw-shadow-color)] shadow-black !text-slate-300 hover:!text-white my-auto'>
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">پروفایل</Tooltip>}>
                                            <span className="my-auto">
                                                <CgProfile size={20}/>
                                            </span>
                                        </OverlayTrigger>
                                    </Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/product">
                                    <Nav.Link rel="preload" className='persianFont drop-shadow-[0px_0px_1px_var(--tw-shadow-color)] shadow-black !text-slate-300 hover:!text-white my-auto'>
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" >محصولات</Tooltip>}>
                                            <span className="my-auto">
                                                <AiFillProduct  size={20}/>
                                            </span>
                                        </OverlayTrigger>
                                    </Nav.Link>
                                </LinkContainer>
                                {/* <LinkContainer to="/">
                                    <Nav.Link className='persianFont drop-shadow-[0px_0px_15px_var(--tw-shadow-color)] shadow-black !text-slate-300 hover:!text-white font-semibold'>
                                        اطلاعیه
                                    </Nav.Link>
                                </LinkContainer> */}
                                <LinkContainer to="/">
                                    <Nav.Link rel="preload" className='drop-shadow-[0px_0px_1px_var(--tw-shadow-color)] shadow-black !text-slate-300 hover:!text-white my-auto'>
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">خانه</Tooltip>}>
                                            <span className="my-auto">
                                                <MdRoofing  size={20}/>
                                            </span>
                                        </OverlayTrigger>
                                    </Nav.Link>
                                </LinkContainer>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
                
            </Container>
        </Navbar>
    </header>
  )
}

export default Header;
