import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Rating from './Rating';

const TVProducts = ({ product }) => {

  const numberFormat = (value) =>
    new Intl.NumberFormat('fa-IR', {
        style: 'currency',
        currency: 'IRR'
    }).format(value);

  return (
    <Card className='my-3 p-3 bg-cl_bg_4 h-fit rounded persianFont hover:outline hover:outline-blue-700 text-gray-400 text-sm transition-all'>
      <Link rel="preload" to={`/product/${product._id}`}>
        <Card.Img src={product.image[0]} variant='top' />
      

      <Card.Body>
        <Link rel="preload" to={`/product/${product._id}`}>
          <Card.Title as='div' className='product-title truncate persianFont'>
            <strong className='persianFont'>{product.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as='div' className='text-yellow-200 text-sm persianFont'>
          <Rating
            value={product.rating/10}
            text={`${product.numReviews} نظر`}
          />
        </Card.Text>

        <Card.Text as='h3' className='persianFont text-sm'>{numberFormat(product.price)}</Card.Text>
      </Card.Body>
      </Link>
    </Card>
  );
};

export default TVProducts;



// import React from 'react';
// import { Card, Col, Image, Row } from 'react-bootstrap';
// // import BarLoader from "react-spinners/BarLoader";
// import Loader from './Loader';
// import Rating from './Rating';

// const TVProducts = ({ product, isLoading }) => {
//   // if (loading) {
//     // return <h2>Loading...</h2>;
//     // return <BarLoader  color={'#e2e2e2'} loading={loading} size={20} aria-label="Loading Spinner" data-testid="loader" className='!flex mx-auto'/>
//   //   return <Loader/>
//   // }
//   const numberFormat = (value) =>
//     new Intl.NumberFormat('fa-IR', {
//         style: 'currency',
//         currency: 'IRR'
//     }).format(value);
//   return (
//     <>
//       { isLoading ? (
//         <Loader/>
//       ) : (
//         <Row>
//         {product.map(product => (
//           <Col sm={12} md={4} lg={2} xl={2} >
//             <Card className='my-3 p-3 bg-cl_bg_4 h-56 rounded hover:outline hover:outline-blue-700 transition-all'>
//               <a href={`/product/${ product._id }`} className='w-full '>
//                 <div className='h-24 mb-1'>
//                   <Image src={`data:image/jpeg;base64,${product.image[0]}`} alt={ product.name } fluid className='m-auto h-full col-img object-cover'/>
//                 </div>
//                 <div >
//                   <h3 className='text-gray-300 truncate'>{ product.name }</h3>
//                 </div>
//                 <Rating value={product.numReviews}/>
//                 <div>
//                   <h4 className='text-gray-300 text-sm'>{ numberFormat(product.price) }</h4>
//                 </div>
//                 <div>
//                   <h4 className='text-gray-300 text-sm'> { product.countInStock > 0 ? 'موجود' : 'ناموجود' } </h4>
//                 </div>
//               </a>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//       )}
      
      
//     </>
//   );
// };

// export default TVProducts;