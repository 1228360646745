import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Loader from './Loader';
import Message from './navigation/Message';
import { useGetTopProductsQuery } from '../slices/productsApiSlice';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductCarousel = () => {

    const { data: products, isLoading, error } = useGetTopProductsQuery();

    const numberFormat = (value) =>
        new Intl.NumberFormat('fa-IR', {
            style: 'currency',
            currency: 'IRR'
        }).format(value);

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        // autoplaySpeed: 10000,
        rtl: false,
    };

  return isLoading ? <Loader/> : error ? <Message variant='danger'>{ error }</Message> : (
    <div className="slider-container">
        <Slider {...settings}>
            {products.map(product => (
                <div key={product._id} className='p-2 relative persianFont'>
                    <Link rel="preload" to={`/product/${product._id}`}>
                        <Image src={product.image[0]} alt={product.name} fluid className='w-96 m-auto rounded-lg '/>
                        <h2 className='mx-auto px-4 rounded-md text-center text-xs bg-cl_bg_6 backdrop-blur-lg justify-center'>{product.name} ({numberFormat(product.price)})</h2>
                    </Link>
                </div>
            ))}
        </Slider>
    </div>

  )
}

export default ProductCarousel;