import { Spinner } from "react-bootstrap";

function Loader() {
  return (
    <Spinner
        animation="border"
        role="status"
        style={{
            width: '3rem',
            height: '3rem',
            margin: "auto",
            display: "block",
            color: "#e2e2e2",
            
        }}
    >
    </Spinner>
  );
};

export default Loader