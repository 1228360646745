import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, Table, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Message from '../../components/navigation/Message';
import Loader from '../../components/Loader';
import { useGetProductsQuery, useCreateProductMutation, useDeleteProductMutation } from '../../slices/productsApiSlice.js';
import Paginate from '../Paginate';
import { toast } from 'react-toastify';

import { CiEdit } from "react-icons/ci";
import { TbTrashXFilled } from "react-icons/tb";

const ProductList = () => {

    const { pageNumber, keyword } = useParams();
    console.log(pageNumber)
    const { data, isLoading, error, refetch } = useGetProductsQuery({ pageNumber, keyword });

    const [createProduct, { isLoading: loadingCreate }] = useCreateProductMutation();


    const [deleteProduct, { isLoading: loadingDelete }] = useDeleteProductMutation();

    const deletehandler = async (id) => {
        if (window.confirm('آیا از حذف کردن این محصول مطمئن هستید؟')) {
            try {
                await deleteProduct(id);
                toast.success(<span className='persianFont'>محصول با موفقیت حذف شد</span>);
                refetch();
            } catch (err) {
                toast.error(err?.data?.Message || err.error);
            }
        }
    }

    const createProductHandler = async () => {
        if (window.confirm('آیا از اضافه کردن محصول جدید مطمئن هستید؟')) {
            try {
                await createProduct();
                toast.success(<span className='persianFont'>محصول با موفقیت اضافه شد</span>);
                refetch();
            } catch (err) {
                toast.error(err?.data?.Message || err.error);
            }
        }
    }
  return (
    <>
        <Row>
            <Col>
                <h1>محصولات</h1>
            </Col>
            <Col dir='ltr' className='text-start ml-4 p-0'>
                <Button className='flex btn-sm' variant="light" onClick={ createProductHandler }>
                    <span className='mr-2'>اضافه کردن محصول جدید</span><CiEdit size={18}/> 
                </Button>
            </Col>
        </Row>
        { loadingCreate && <Loader/> }
        { loadingDelete && <Loader/> }
        { isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : (
            <>
                <Row className='h-fit overflow-y-auto scrollbar'>
                    <Table variant='dark' striped hover responsive className="table-sm border-dark text-sm text-center align-middle persianFont mt-2">

                        <thead>
                            <tr>
                                <th>id</th>
                                <th>مدل</th>
                                <th>قیمت</th>
                                <th>کتگوری</th>
                                <th>برند</th>
                                <th>نگارش / حذف</th>
                            </tr> 
                        </thead>
                        <tbody>
                            { data.products.map((product) => (
                                <tr key={ product._id }>
                                    <td>{ product._id }</td>
                                    <td>{ product.name }</td>
                                    <td>{ product.price }</td>
                                    <td>{ product.category }</td>
                                    <td>{ product.brand }</td>
                                    <td>
                                        <LinkContainer to={`/admin/product/${product._id}/edit`}>
                                            <Button variant='light' className='btn-sm mx-2 !bg-transparent border-none text-gray-300 hover:text-sky-600'>
                                                <CiEdit size={18}/>
                                            </Button>
                                        </LinkContainer>
                                        <Button variant='light' onClick={ () => deletehandler(product._id) }
                                            className='btn-sm mx-2 !bg-transparent border-none text-red-300 hover:text-red-600'>
                                            <TbTrashXFilled size={18}/>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    
                </Row>
                <Paginate pages={data.pages} page={data.page} isAdmin={true} keyword={keyword ? keyword : ''}/>
            </>
            )
        }
    </>
  )
}

export default ProductList;