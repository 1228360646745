import { GENERAL_URL } from "../constants.js";
import { apiSlice } from "./apiSlice";


export const generalApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getGenerals: builder.query({
            query: () => ({
                url: `${GENERAL_URL}`,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['generals'],
        }),
        getGeneralDetails: builder.query({
            query: (generalId) => ({
                url: `${GENERAL_URL}/${generalId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        updateGeneral: builder.mutation({
            query: (data) => ({
                url: `${GENERAL_URL}/${data.generalId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Generals'],
        }),
    }),
});

export const {
    useGetGeneralsQuery,
    useGetGeneralDetailsQuery,
    useUpdateGeneralMutation,
} = generalApiSlice;