import React, { useState, useEffect } from 'react'
import { Topbar } from '../../components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/navigation/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import { useGetAgentDetailsQuery, useUpdateAgentMutation } from '../../slices/agentsApiSlice';

import { GrLinkNext } from "react-icons/gr";
import { MdUpdate } from 'react-icons/md';

const AgentEditPage = () => {

    const { id: agentId } = useParams();

    const [lx, setLx] = useState(0);
    const [ly, setLy] = useState(0);
    const [city, setCity] = useState('');
    const [name, setName] = useState('');
    const [code, setCode] = useState(123456);
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState(123456);
    const [mobilePhone, setMobilePhone] = useState(123456);

    const {
        data: agent,
        isLoading,
        error,
    } = useGetAgentDetailsQuery(agentId);

    const [updateAgent, 
        { isLoading: isUpdating }
    ] = useUpdateAgentMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if(agent) {
            setLx(agent.lx);
            setLy(agent.ly);
            setCity(agent.city);
            setName(agent.name);
            setCode(agent.code);
            setAddress(agent.address);
            setPhone(agent.phone);
            setMobilePhone(agent.mobilePhone);

        }
    }, [agent]);

    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

    const submitHandler = async (e) => {

        e.preventDefault();
        const updatedAgent = {
            agentId,
            lx,
            ly,
            city,
            name,
            code,
            address,
            phone,
            mobilePhone,
        };

        const result = await updateAgent(updatedAgent);
        if(result.error) {
            toast.error(result.error);
        } else {
            toast.success('نماینده با موفقیت آپدیت شد');
            navigate('/admin/agentlist');
        }
    }

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar/>
        </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 bg-slate-900 overflow-scroll persianFont 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar'
            onScroll={handleScroll}
        >

            <Link to='/admin/agentlist' className='flex align-middle btn btn-light my-3 w-fit'>
                <GrLinkNext className='m-auto' /><span className='persianFont '>برگشت</span>
            </Link>

            <FormContainer>
                <h1>نگارش رویداد</h1>
                { isLoading && <Loader/> }
                
                {isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : (
                    <Form onSubmit={ submitHandler }>
                        <Form.Group controlId='lx'>
                            <Form.Label>عنوان رویداد</Form.Label>
                            <Form.Control
                                type='Number'
                                placeholder='lx'
                                value={lx}
                                onChange={(e) => setLx(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='ly'>
                            <Form.Label>چکیده رویداد</Form.Label>
                            <Form.Control
                                type='Number'
                                placeholder='ly'
                                value={ly}
                                onChange={(e) => setLy(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='city'>
                            <Form.Label>استان و شهر</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='استان و شهر'
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='name'>
                            <Form.Label>نام و نام خانوادگی</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='نام و نام خانوادگی'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='code'>
                            <Form.Label>کد نمایندگی</Form.Label>
                            <Form.Control
                                type='Number'
                                placeholder='کد نمایندگی'
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='address'>
                            <Form.Label>آدرس محل کسب</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='آدرس محل کسب'
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='phone'>
                            <Form.Label>تلفن ثابت</Form.Label>
                            <Form.Control
                                type='Number'
                                placeholder='تلفن ثابت'
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='mobilePhone'>
                            <Form.Label>موبایل</Form.Label>
                            <Form.Control
                                type='Number'
                                placeholder='موبایل'
                                value={mobilePhone}
                                onChange={(e) => setMobilePhone(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group>

                        {isUpdating && <Loader/>}
                        <Button type='submit' variant='light' className='flex align-middle my-2 w-fit'>
                            <MdUpdate className='m-auto' /><span className='persianFont '>بروزرسانی</span>
                        </Button>
                    </Form>
                )}
            </FormContainer>

        </div>
    </>
  )
}

export default AgentEditPage