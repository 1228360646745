import { Alert } from 'react-bootstrap';

const Message = ({ variant, children }) => {
  return (
    <Alert variant={ variant } dir='ltr' className='persianFont rounded px-28 text-center'>
        { children }
    </Alert>
  )
}

Message.defaultProps = {
    variant: 'info',
};

export default Message