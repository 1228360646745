import * as React from "react"

const Pars = (props) => (
    <svg
    id="svg1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 143.1 23.5"
    style={{
      enableBackground: "new 0 0 143.1 23.5",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st0{fill:#c0c0c0}"}</style>
    <path
      className="st0"
      d="M31.3 4.6c-.7-.8-1.7-1.5-2.9-2.1s-2.6-1-4.2-1.2c-1.5-.4-3.2-.6-5-.7S15.8.4 14 .4s-3.5 0-5 .1-2.9.3-4.1.5-2.6.4-4 .6v21.9H9V16c1.4.1 2.6.3 3.7.3h3.7c1.3 0 2.8-.1 4.3-.3 1.4-.3 2.9-.4 4.3-.8s2.6-.8 3.7-1.5c1.1-.6 1.9-1.4 2.6-2.4.7-1 1-2.1 1-3.5.1-1.3-.3-2.4-1-3.2zm-8 5.4c-.3.6-.6 1-1 1.2-.4.4-.8.7-1.4.8-.6.1-1.2.3-1.8.4-.7 0-1.2.1-1.9.1h-1.9c-.7 0-1.2 0-1.9-.1s-1.2-.1-1.8-.3c-.6-.1-1-.1-1.4-.3-.4-.1-.7-.3-1-.3-.1-.1-.3-.1-.3-.1h-.2V5.1c1.1-.1 2.1-.3 2.9-.4 1-.1 1.8-.3 2.9-.3 1.1-.1 2.1-.1 3.2 0s2.1.3 2.9.6c.8.3 1.5.8 2.1 1.2.6.4.8 1.2.8 2.1.1.7.1 1.2-.2 1.7zM53.9.4H43.8L30 23.4h5.9l3.3-5h15.6l3.2 5h10L53.9.4zM42.5 13.1l4.6-7.2 4.3 7.2h-8.9zM72.1 23.4h8.4V4.6c1.1-.1 1.9-.3 2.9-.3.8-.1 1.8-.1 2.8-.1 1 0 2.1 0 3 .1s1.9.3 2.8.6 1.5.7 2.1 1.1c.6.6.8 1.1.8 1.9s-.3 1.5-.7 1.9-1 1-1.8 1.2c-.7.3-1.5.4-2.4.6-.8.1-1.7.1-2.6.1-1 0-1.7 0-2.4-.1s-1.5-.1-2.4-.3l-1.7 2.1 11.8 9.8h11.2l-9.5-8.4c1-.1 1.7-.4 2.4-.6.7-.3 1.5-.6 2.2-.8.8-.4 1.5-.8 2.2-1.4s1.2-1.2 1.8-1.9c.4-.7.7-1.7.7-2.6 0-1.2-.4-2.2-1.1-3.2-.7-.8-1.7-1.5-2.9-2.1s-2.6-1-4.2-1.2c-1.5-.3-3.2-.6-5-.7C88.7.2 87 .2 85.2.2c-1.8 0-3.5.1-5 .1-1.5.1-2.9.3-4.2.3s-2.5.3-4 .4l.1 22.4zM141.3 3.3c-1.8-.7-3.6-1.1-5.3-1.7-1.5-.4-3.5-.8-5.4-1.1-2.1-.4-4.3-.6-6.4-.6-2.2 0-4.6.3-6.9.8-1.9.6-3.3 1.1-4.4 2.2s-1.5 2.2-1.2 3.5c.1 1.2 1.1 2.6 2.9 3.9 1 .8 2.2 1.2 3.7 1.8 1.5.6 3.2 1 5.1 1.2 1.9.3 3.5.7 5.1 1.1 1.5.4 2.9.8 3.7 1.1 1 .4 1.2 1 1.2 1.5 0 1-.6 1.5-1.5 1.8-1.1.4-2.4.4-3.9.1-1.5-.3-3.2-.7-4.8-1.1-1.7-.6-3.2-1.1-4.8-1.7-1.5-.7-3.2-1.2-5.3-2.2l-2.8 4.8c2.1 1 3.9 1.7 5.7 2.2 1.8.6 3.7 1.1 5.9 1.5 2.2.4 4.7.7 7.2.8 2.5 0 5.1-.3 7.5-1 1.9-.8 3.3-1.7 4.3-2.6.8-1 1.1-1.8 1.1-2.9-.1-1.1-.4-1.8-1.1-2.6-.7-.8-1.2-1.5-2.2-2.2-.8-.7-1.5-1.1-2.4-1.2-.8-.1-1.8-.7-3.2-1.1s-2.9-.8-4.4-1.1c-1.5-.3-2.9-.7-4-1s-1.7-.4-1.8-.4c-1.2-.3-2.1-.7-2.5-1.1-.4-.6-.4-1.1.1-1.4.6-.3 1.5-.7 3-.6 2.1.1 4 .4 5.8 1 1.8.4 3.5 1 4.8 1.4 1.4.6 2.9 1.1 4.6 1.9l2.6-5z"
    />
  </svg>
)

export default Pars;