import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Message from '../components/navigation/Message';
import { Topbar } from '../components';
import Loader from '../components/Loader';
import { Col, Image, Row } from 'react-bootstrap';
// import Weather from '../components/Weather';
import Breadcrumbs from '../components/Breadcrumbs';
import SocialMedia from '../components/SocialMedia';
import { useGetEventDetailsQuery } from '../slices/eventsApiSlice';
import ImageGallery from "react-image-gallery";
import { PiFilePdfLight } from "react-icons/pi";
import { GrNext } from "react-icons/gr";

const Event = () => {

    const { id: eventId } = useParams();

    const { data: event, isLoading, error } = useGetEventDetailsQuery(eventId);

    // console.log('event: ',event)
    
    // Check position of Y
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

    // time 
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
          setTime(new Date());
        }, 1000);
    
        return () => clearInterval(interval);
      }, []);

      const currentYear = new Date().getFullYear();


      const downloadPdf = () => {  
        if (event.file) {  
          const dataUrl = event.file;  
          const downloadLink = document.createElement('a');  
          downloadLink.href = dataUrl;  
          downloadLink.target = '_blank';  
          downloadLink.download = `${event.title}.pdf`;  
          downloadLink.click();  
        }  
      };  

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>
        
        <div className='w-screen h-full eventBackground bgservice py-28 pb-5 pr-24 pl-4 max-sm:p-4 overflow-x-scroll scrollbar persianFont' onScroll={handleScroll}>

            <Row >
                <Col md={8}>
                    <div className='w-full my-10 p-3 z-2  bg-cl_bg_5 rounded-lg' >
                        { isLoading ? (
                                <Loader/>
                            ) : error ? (
                                <Message variant='danger'>
                                        { error?.data?.message || error.error}
                                </Message>
                            ): (
                                <div className='w-[80%] mx-auto mt-1 persianFont'>
                                    <h1 className='text-gray-100 text-4xl py-2 persianFont my-2'>{ event.title }</h1>
                                    <p className='text-gray-400 p-1 text-xs persianFont my-2'>{ new Date(event.createdAt).toLocaleString('fa-IR', { timeZone: 'Asia/Tehran' }) }</p>
                                    <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700 "></hr>
                                    <div className='py-2'>
                                        <Image src={ `${ event.figure[0] }` } alt={ event.title } className='m-auto max-h-80 persianFont'/>
                                        { event.lead? <p className='text-gray-400 text-xs p-1 my-2 persianFont'>{ event.lead }</p>:null }
                                        <p className='text-gray-100 p-1 text-justify whitespace-pre-line persianFont'>{ event.structure }</p>
                                    </div>
                                    
                                    {
                                        event.file && event.file.length > 0 && (
                                            <div dir='ltr' className='flex align-middle'>
                                                <button onClick={downloadPdf}><PiFilePdfLight className='text-red-500 text-4xl hover:text-green-500 transition-all'/></button>
                                            </div>
                                           
                                        )
                                    }
                                    
                                    <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                                    <p className='text-gray-400 text-xs text-justify persianFont'>
                                    حق چاپ و کلیه حقوق برای PARS Electric Manufacturing Company &copy; { currentYear }  محفوظ است. این مطالب را نمی توان منتشر، پخش، بازنویسی یا دوباره توزیع کرد.
                                    </p>
                                    {/* <Counter/> */}
                                </div>
                            )
                        } 
                    </div>
                </Col>
                <Col dir='ltr' md={4} className='sticky top-0 mb-24 px-3 '>
                    <Row className='px-3'>
                        <div className='w-full mt-10 p-3 z-2 overflow-x-scroll scrollbar bg-cl_bg_5 rounded-lg text-gray-400 persianFont'>
                            <div className='inline-flex align-middle w-full text-gray-400 rounded-md mb-4 p-2 text-xs borderStyle'>
                                <Breadcrumbs/> <GrNext className='mx-2' /> <div dir='rtl' className='max-w-[250px] text-right truncate'>{event && (event.title)}</div>
                            </div>
                            {/* .toLocaleString('fa-IR', { timeZone: 'Asia/Tehran' }) */}
                            <div className='mb-4 flex space-x-4'>
                                <p className=''>{time.getMonth()+1}/{time.getDate()}/{time.getFullYear()}</p>
                                <p>{time.toLocaleString('fa-IR', { timeZone: 'Asia/Tehran' })}</p>
                            </div>
                            {/* <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                            <Weather/>  */}
                            <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                            <SocialMedia/>
                        </div> 
                    </Row>
                    <Row className='px-3'>
                        
                        <div className='w-full h-fit my-4 p-3 z-2 overflow-x-scroll scrollbar bg-cl_bg_5 rounded-lg text-gray-400'>
                                
                                    { 
                                        isLoading ? (
                                            <Loader/>
                                        ) : error ? (
                                            <Message variant='danger'>
                                                    { error?.data?.message || error.error}
                                            </Message>
                                        ): (
                                        <ImageGallery 
                                            items={
                                                event.images?.map(pic => (    
                                                    {
                                                        original: `${pic}`,
                                                        thumbnail: `${pic}` 
                                                    }
                                                ))    
                                            } 
                                            showNav={false}
                                            showPlayButton={false}
                                            isRTL={true}
                                        />)
                                    }
                                
                        </div>
                    </Row>
                </Col>
            </Row>
            
            
            
            
        </div>
        
    </>
    
  )
}

export default Event;