import { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormContainer from '../components/FormContainer';
import { Topbar } from "../components";
import { saveShippingAddress } from "../slices/cartSlice";
import CheckoutSteps from "../components/CheckoutSteps";

const Shipping = () => {

    const cart = useSelector((state) => state.cart);
    const { shippingAddress } = cart;

    const [address, setAddress] = useState(shippingAddress?.address || '');
    const [city, setCity] = useState(shippingAddress?.city || '');
    const [postalCode, setPostalCode] = useState(shippingAddress?.postalCode || '');
    const [country, setCountry] = useState(shippingAddress?.country || '');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(saveShippingAddress({ address, city, postalCode, country }));
        navigate('/payment');
    }


    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
                <Topbar />
            </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 bg_gradiant overflow-scroll 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar'
            onScroll={handleScroll}
        >
            
            <FormContainer>
                <CheckoutSteps step1 step2 />
                <h1>خرید</h1>
                <Form onSubmit={submitHandler}>
                    {/* address */}
                    <Form.Group controlId="address" className="my-2">
                        <Form.Label>آدرس</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="آدرس را وارد کنید" 
                            value={address} 
                            onChange={(e) => setAddress(e.target.value)}
                            className="bg-transparent border-slate-400">
                        </Form.Control>
                    </Form.Group>

                    {/* city */}
                    <Form.Group controlId="city" className="my-2">
                        <Form.Label>شهر</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="شهر را وارد کنید" 
                            value={city} 
                            onChange={(e) => setCity(e.target.value)}
                            className="bg-transparent border-slate-400">
                        </Form.Control>
                    </Form.Group>

                    {/* postalcod */}
                    <Form.Group controlId="postalCode" className="my-2">
                        <Form.Label>کدپستی</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="شهر را وارد کنید" 
                            value={postalCode} 
                            onChange={(e) => setPostalCode(e.target.value)}
                            className="bg-transparent border-slate-400">
                        </Form.Control>
                    </Form.Group>

                    {/* country */}
                    <Form.Group controlId="country" className="my-2">
                        <Form.Label>کشور</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="شهر را وارد کنید" 
                            value={country} 
                            onChange={(e) => setCountry(e.target.value)}
                            className="bg-transparent border-slate-400">
                        </Form.Control>
                    </Form.Group>
                    <Button type="submit" variant="outline-light" className="my-2">اعمال</Button>
                </Form>
            </FormContainer>
        </div>
    </>
  )
}

export default Shipping;