import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const EventPaginate = ({ pages, page, isAdmin = false, keyword='' }) => {
    //  className="d-flex justify-content-center"

  return (
    pages > 1 && (
        <Pagination size="sm">

            {/* <LinkContainer
                key={1}
                to={!isAdmin ? keyword ? `/event/search/${keyword}/page/${1}` : `/event/page/${1}` : `/admin/eventlist/page/${1}`}
            >
                <Pagination.First /> 
            </LinkContainer> */}
            <LinkContainer
                key={page - 1}
                to={!isAdmin ? keyword ? `/event/search/${keyword}/page/${page - 1}` : `/event/page/${page - 1}` : `/admin/eventlist/page/${page - 1}`}
            >
                <Pagination.Prev />
            </LinkContainer>
            

            {/* { [...Array(pages).keys()].map((x) => (
                
                <LinkContainer
                    key={x + 1}
                    to={!isAdmin ? keyword ? `/event/search/${keyword}/page/${x + 1}` : `/event/page/${x + 1}` : `/admin/eventlist/page/${x + 1}`}
                >
                    <Pagination.Item active={x + 1 === page}>
                        {x + 1}
                    </Pagination.Item>
                </LinkContainer>
            

            ))} */}
            {/* { [...Array(pages).keys()].map((x) => ( */}
                
                <LinkContainer
                    key={page}
                    to={!isAdmin ? keyword ? `/event/search/${keyword}/page/${page}` : `/event/page/${page}` : `/admin/eventlist/page/${page}`}
                >
                    <Pagination.Item active={ page}>
                        {page}
                    </Pagination.Item>
                </LinkContainer>
            

            {/* ))} */}

            <LinkContainer
                key={page + 1}
                to={!isAdmin ? keyword ? `/event/search/${keyword}/page/${page + 1}` : `/event/page/${page + 1}` : `/admin/eventlist/page/${page + 1}`}
            >
                <Pagination.Next />
            </LinkContainer>

            {/* <LinkContainer
                key={pages}
                to={!isAdmin ? keyword ? `/event/search/${keyword}/page/${pages}` : `/event/page/${pages}` : `/admin/eventlist/page/${pages}`}
            >
              <Pagination.Last />  
            </LinkContainer> */}
            
        </Pagination>
    )
  )
}

export default EventPaginate;