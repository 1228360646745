import { Link, useNavigate } from 'react-router-dom';
import { Badge, Nav, Container, NavDropdown, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLogoutMutation } from '../slices/userApiSlice';
import { logout } from '../slices/authSlice';
import { useSelector, useDispatch } from 'react-redux';

import { CgProfile } from "react-icons/cg";
import { RiShoppingBag4Fill } from "react-icons/ri";
import { AiFillProduct } from "react-icons/ai";
import { CgMoreO } from "react-icons/cg";
import { FaUsers } from "react-icons/fa";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { ImNewspaper } from "react-icons/im";
import { MdOutlineSecurity } from "react-icons/md";
import { LiaSignOutAltSolid } from "react-icons/lia";
import { FaSitemap } from 'react-icons/fa6';



const QuickMenu= () => {
    const { cartItems } = useSelector((state) => state.cart);
    const { userInfo } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [logoutApiCall] = useLogoutMutation();

    const logoutHandler = async () => {
        try {
            await logoutApiCall().unwrap();
            dispatch(logout());
            navigate('/login')
        } catch (err) {
            console.log(err)
        }
    };
  return (
    <footer className="hidden max-sm:block fixed w-full h-16 bottom-0 p-0 text-gray-300 text-xs
                        max-sm:bg-cl_bg_1 max-sm:backdrop-blur-sm boxShadow">
        <Container >
            <Row >
                
                <Col className="text-center py-2 persianFont">
                    {/* <span className='font-thin text-2xl align-middle symbol'></span> */}
                    <NavDropdown title={ 
                        <span> 
                            <CgMoreO className='m-auto' size={25}/>
                            <p className='font-thin align-middle persianFont my-1'>بیشتر</p>
                             
                        </span> } id='username' className='!bg-unset bg-transparent drop-shadow-[0px_0px_15px_var(--tw-shadow-color)] shadow-black !text-slate-200 hover:!text-white text-xs font-thin persianFont'
                    >
                            <LinkContainer to='/sitemap'>
                                <NavDropdown.Item className='flex rotate-[5deg] persianFont font-thin text-lg text-end text-slate-200 group/edit my-2'>                       
                                    <FaSitemap className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                    <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>Site Map</span>
                                </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to='/event'>
                                <NavDropdown.Item className='flex rotate-[4deg] persianFont mr-1 font-thin text-lg text-end text-slate-200 group/edit my-2'>
                                    <ImNewspaper className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                    <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>رویدادها</span>
                                    
                                </NavDropdown.Item>
                            </LinkContainer>
                            
                            <LinkContainer to='/service'>
                                <NavDropdown.Item className='flex rotate-3 persianFont mr-2 font-thin text-lg text-end text-slate-200 group/edit my-2'>
                                    <HiMiniWrenchScrewdriver className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                    <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>پشتیبانی</span>
                                    
                                </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to='/aboutus'>
                                <NavDropdown.Item className='flex persianFont rotate-2 mr-2 font-thin text-lg text-end text-slate-200 group/edit my-2'>
                                    <FaUsers className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                    <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>درباره ما</span>
                                    
                                </NavDropdown.Item>
                            </LinkContainer>
                            
                            <LinkContainer to='/security'>
                                <NavDropdown.Item className='flex persianFont mb-10 rotate-1 mr-2 font-thin text-lg text-end text-slate-200 group/edit my-2'>
                                    <MdOutlineSecurity className='group-hover/edit:ml-2 text-slate-200 ml-1 transition-all' size={25}/>
                                    <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>امنیت</span>
                                    
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                </Col>
                <Col className="text-center py-2 persianFont">
                    <Link to='/product' >
                        <AiFillProduct className='m-auto' size={25}/>
                        <p className='font-thin align-middle persianFont  my-1'>محصولات</p> 
                    </Link>
                </Col>

                

                <Col className="text-center py-2">
                    <LinkContainer to="/cart">
                            <Nav.Link rel="preload" className='drop-shadow-[0px_0px_15px_var(--tw-shadow-color)] shadow-black !text-slate-200 hover:text-white hover:font-semibold symbol font-thin text-2xl' >
                                <RiShoppingBag4Fill className='m-auto'/>
                                {
                                    cartItems.length > 0 && (
                                        <Badge pill bg='success' className='mr-0 text-xs'>
                                            { cartItems.reduce((a, c) => a + c.qty, 0) }
                                        </Badge>
                                    )
                                }
                                <p className='font-thin align-middle text-xs persianFont my-1'>کیف خرید</p>
                            </Nav.Link>
                        </LinkContainer>
                </Col>
                <Col className="text-center py-2 persianFont">

                    {/* { userInfo ? <span className='group-hover/edit:ml-2 symbol text-slate-200 ml-1 transition-all font-thin text-2xl align-center'> </span> : null } */}
                    { userInfo ? (
                        
                        <NavDropdown title={ 
                            <span>
                                <CgProfile className='m-auto' size={25}/>
                                <p className='font-thin align-middle text-xs persianFont my-1'>{userInfo.name}</p>
                            </span>
                            
                             } id='username' dir='ltr' className='drop-shadow-[0px_0px_15px_var(--tw-shadow-color)]  shadow-black !text-slate-200 hover:!text-white text-xs font-thin persianFont'>
                            <LinkContainer to='/profile'>
                                <NavDropdown.Item className='flex -rotate-2 font-thin text-start text-lg rounded-md text-slate-200 group/edit my-2 persianFont'>
                                    
                                    <CgProfile className='group-hover/edit:mr-2 text-slate-200 mr-1 transition-all' size={25}/> 
                                    <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>پروفایل</span>
                                </NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Item onClick={ logoutHandler } className='flex persianFont font-thin text-start text-lg rounded-md text-slate-200 group/edit mb-10 -rotate-1'>
                                
                                <LiaSignOutAltSolid className='group-hover/edit:mr-2 text-slate-200 mr-1 transition-all' size={25}/>
                                <span className='px-4 rounded-md bg-cl_bg_1 backdrop-blur-sm'>خروج</span>
                            </NavDropdown.Item>
                        </NavDropdown>
                            
                    ) : (
                        <LinkContainer to="/login">
                            <Nav.Link rel="preload" className='drop-shadow-[0px_0px_15px_var(--tw-shadow-color)] shadow-black !text-slate-200 hover:text-white hover:font-semibold font-thin text-2xl'>
                                <CgProfile className='m-auto' size={25}/>
                                <p className='font-thin align-middle text-xs persianFont my-1'>پروفایل</p>
                            </Nav.Link>
                            
                        </LinkContainer>
                    ) }
                </Col>
                
                
            </Row>
        </Container>
    </footer>
  )
}

export default QuickMenu;