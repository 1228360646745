import React, { useState } from 'react';
import { Topbar } from '../../components';
import { Col, Row, Nav, Tab } from 'react-bootstrap';
import ProductList from '../../components/admin/ProductList';

const ProductListPage = () => {

    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 bg-slate-900 overflow-scroll persianFont 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar '
            onScroll={handleScroll}
        >

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row >
                <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                    <Nav.Link eventKey="first">محصولات</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link eventKey="second">دیدگاه ها</Nav.Link>
                    </Nav.Item>
                </Nav>
                </Col>
                <Col sm={9}>
                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <ProductList/>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">...</Tab.Pane>
                </Tab.Content>
                </Col>
            </Row>
            </Tab.Container>
            
        </div>
    </>
  )
}

export default ProductListPage;