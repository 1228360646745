
import { useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CheckoutSteps from '../components/CheckoutSteps';
import { Topbar } from '../components';
import Message from '../components/navigation/Message';
import Loader from '../components/Loader';
import { useCreateOrderMutation } from '../slices/ordersApiSlice';
import { clearCartItems } from '../slices/cartSlice';

import { IoIosClose } from "react-icons/io";
import { FaEquals } from "react-icons/fa6";


const PlaceOrder = () => {

    const navigate = useNavigate();
    
    const cart = useSelector((state) => state.cart);

    const [createOrder, { isLoading, error }] = useCreateOrderMutation();

    useEffect(() => {
        
        if (!cart.shippingAddress.address) {
            navigate('/shipping');
        } else if (!cart.paymentMethod) {
            navigate('/payment');
        }

    },[cart.paymentMethod, cart.shippingAddress.address, navigate]);
 
    const dispatch = useDispatch();
    const placeOrderHandler = async () => {
        try {
          const res = await createOrder({
            orderItems: cart.cartItems,
            shippingAddress: cart.shippingAddress,
            paymentMethod: cart.paymentMethod,
            itemsPrice: cart.itemsPrice,
            shippingPrice: cart.shippingPrice,
            taxPrice: cart.taxPrice,
            totalPrice: cart.totalPrice,
          }).unwrap();
          dispatch(clearCartItems());
          navigate(`/order/${res._id}`);
        } catch (err) {
          toast.error(err);
        }
      };

    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

    const numberFormat = (value) =>
        new Intl.NumberFormat('fa-IR', {
            style: 'currency',
            currency: 'IRR'
        }).format(value);

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
                <Topbar />
            </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 ordersBg overflow-scroll persianFont 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar'
            onScroll={handleScroll}
        >
        <CheckoutSteps step1 step2 step3 step4 />
        <Row className='mb-20'>
            <Col md={8}>
                <ListGroup variant='flush'>
                    <ListGroup.Item className='text-gray-100 persianFont'>
                        <h1>ارسال</h1>
                        <p>
                            <strong>آدرس: </strong>
                            {cart.shippingAddress.country}, 
                            {cart.shippingAddress.city}, 
                            {cart.shippingAddress.address}, 
                            {cart.shippingAddress.postalCode}
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item className='text-gray-100 persianFont'>
                        <h1>پرداخت</h1>
                        <p>
                            <strong>روش: </strong>
                            {cart.paymentMethod}
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item className='text-gray-100 persianFont'>
                        <h1>سفارش ها</h1>
                        {
                            cart.cartItems.length === 0 ? (
                                <Message>سبد خرید شما خالی است</Message>
                            ) : (
                                <ListGroup variant='flush'>
                                    <ListGroup.Item  className='text-gray-100 persianFont'>
                                        {
                                            cart.cartItems.map((item, index) => (
                                                
                                                <Row key={index} className='p-1 persianFont border-gray-500 border-dashed border-b-[1px] items-center'>
                                                    <Col md={1}>
                                                        <Image src={`data:image/jpeg;base64,${item.image[0]}`} alt={item.name} fluid rounded/>
                                                    </Col>
                                                    <Col>
                                                        <Link to={`/product/${item._id}`}>
                                                            {item.name}
                                                        </Link>
                                                    </Col>
                                                    <Col md={4} dir='ltr'>
                                                        {item.qty} 
                                                        <IoIosClose/> 
                                                        {item.price} 
                                                        <FaEquals /> 
                                                        {numberFormat(item.qty * item.price)}
                                                    </Col>
                                                </Row>
                                            ))  
                                        }
                                    </ListGroup.Item>
                                </ListGroup>
                            ) 
                        }
                    </ListGroup.Item>
                </ListGroup>
            </Col>
            <Col md={4}>
                <Card>
                    <ListGroup variant='flush'>
                        <ListGroup.Item >
                            <h2>خلاصه سفارش</h2>
                        </ListGroup.Item>
                        <ListGroup.Item >
                            <Row>
                                <Col>اقلام: </Col>
                                <Col>{numberFormat(cart.itemsPrice)}</Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item >
                            <Row>
                                <Col>هزینه ارسال: </Col>
                                <Col>{numberFormat(cart.shippingPrice)}</Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item >
                            <Row>
                                <Col>9% مالیات: </Col>
                                <Col>{numberFormat(cart.taxPrice)}</Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item >
                            <Row>
                                <Col>جمع کل: </Col>
                                <Col>{numberFormat(cart.totalPrice)}</Col>
                            </Row>
                        </ListGroup.Item>

                        <ListGroup.Item >
                            {error && (
                                <Message variant='danger'>{error.data.message}</Message>
                            )}
                        </ListGroup.Item>
                            
                        <ListGroup.Item >
                            <Button
                                type='button'
                                variant="outline-light"
                                disabled={cart.cartItems.length === 0}
                                onClick={placeOrderHandler}
                            >
                                اعمال
                            </Button>
                            { isLoading && <Loader/>}
                        </ListGroup.Item>

                    </ListGroup>
                </Card>
            </Col>
        </Row>
        </div>
    </>
  )
}

export default PlaceOrder;