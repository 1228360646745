import { GrLineChart } from "react-icons/gr";

export default function StockMenu() {

    return (

            <div className='h-[97vh] w-[360px] persianFont z-10 absolute right-0 my-[1.5vh] mr-[96px] rounded-lg bg-cl_bg_1 backdrop-blur-sm transition-colors persianFont'>
                <h1 className="flex persianFont text-gray-400 py-4 px-4"><GrLineChart className='ml-4' size={35}/>بورس</h1>
                <ul>
                    <li className="persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                        <a href="http://www.tsetmc.com/Loader.aspx?ParTree=151311&i=5187018329202415" className="block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden">شرکت مدیریت فناوری بورس تهران</a>
                    </li>
                    <li className="persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                        <a href="https://codal.ir/ReportList.aspx?search&Symbol=%D9%84%D9%BE%D8%A7%D8%B1%D8%B3&LetterType=-1&Isic=323002&AuditorRef=-1&PageNumber=1&Audited&NotAudited&IsNotAudited=false&Childs&Mains&Publisher=false&CompanyState=0&Category=-1&CompanyType=1&Consolidatable&NotConsolidatable" className="block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden">سامانه کدال</a>
                    </li>
                    <li className="persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                        <a href="https://psaapp.ir/login" className="block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden">سامانه امور سهام</a>
                    </li>
                </ul>
            </div>
        
        
    )

}