import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { IoMdCheckmark } from "react-icons/io";
import { LiaSignInAltSolid } from "react-icons/lia";
import { RiSignpostFill } from "react-icons/ri";
import { MdPayment } from "react-icons/md";
import { IoBagAdd } from "react-icons/io5";

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {

  return (
    <Nav className='justify-content-center mb-4'>
        <Nav.Item>
            {step1 ? (
                <LinkContainer to='/login'>
                    <Nav.Link rel="preload" className='text-xs flex align-middle'><IoMdCheckmark className='m-auto text-green-400'/><span className='persianFont'>ورودحساب</span></Nav.Link>
                </LinkContainer>
            ) : (
                <Nav.Link rel="preload" className='text-xs flex align-middle' disabled><LiaSignInAltSolid className='m-auto'/><span className='persianFont'>ورود به حساب</span></Nav.Link>
            )}
        </Nav.Item>

        <Nav.Item>
            {step2 ? (
                <LinkContainer to='/shipping'>
                    <Nav.Link rel="preload" className='text-xs flex align-middle'><IoMdCheckmark className='m-auto text-green-400'/><span className='persianFont'>اطلاعات پستی</span></Nav.Link>
                </LinkContainer>
            ) : (
                <Nav.Link rel="preload" className='text-xs flex align-middle' disabled><RiSignpostFill  className='m-auto'/><span className='persianFont'>اطلاعات پستی</span></Nav.Link>
            )}
        </Nav.Item>

        <Nav.Item>
            {step3 ? (
                <LinkContainer to='/payment'>
                    <Nav.Link rel="preload" className='text-xs flex align-middle'><IoMdCheckmark className='m-auto text-green-400'/><span className='persianFont'>پرداخت</span></Nav.Link>
                </LinkContainer>
            ) : (
                <Nav.Link className='text-xs flex align-middle' disabled><MdPayment   className='m-auto'/><span className='persianFont'>پرداخت</span></Nav.Link>
            )}
        </Nav.Item>

        <Nav.Item>
            {step4 ? (
                <LinkContainer to='/placeorder'>
                    <Nav.Link rel="preload" className='text-xs flex align-middle'><IoMdCheckmark className='m-auto text-green-400'/><span className='persianFont'>سفارش گذاری</span></Nav.Link>
                </LinkContainer>
            ) : (
                <Nav.Link className='text-xs flex align-middle' disabled><IoBagAdd className='m-auto'/><span className='persianFont'>سفارش گذاری</span></Nav.Link>
            )}
        </Nav.Item>
    </Nav>
  )
}

export default CheckoutSteps;