import React, { useState, useEffect, useRef } from 'react'
import { Topbar } from '../../components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Row } from 'react-bootstrap';
import Message from '../../components/navigation/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import { useGetGeneralDetailsQuery, useUpdateGeneralMutation } from '../../slices/generalApiSlice';
import { GrLinkNext } from "react-icons/gr";
import { MdUpdate } from "react-icons/md";

const GeneralEditPage = () => {

    const { id: generalId } = useParams();

    const [aboutUs, setAboutUs] = useState('');
    const [socialRes, setSocialRes] = useState('');
    const [security, setSecurity] = useState('');
    const [addI, setAddI] = useState('');
    const [codalPostI, setCodalPostI] = useState(0);
    const [faxI, setFaxI] = useState(0);
    const [phoneI, setPhoneI] = useState(0);
    const [addII, setAddII] = useState('');
    const [codalPostII, setCodalPostII] = useState(0);
    const [faxII, setFaxII] = useState(0);
    const [phoneII, setPhoneII] = useState(0);
    const [mainIMGtitle, setMainIMGtitle] = useState('');
    const [mainIMGLink, setMainIMGLink] = useState('');
    const [mainIMG, setMainIMG] = useState([]);
    const [secIMGtitle, setSecIMGtitle] = useState('');
    const [secIMGLink, setSecIMGLink] = useState('');
    const [secIMG, setSecIMG] = useState([]);
    const [sQ1, setSQ1,] = useState('');
    const [sQ2, setSQ2,] = useState('');
    const [sQ3, setSQ3,] = useState('');
    const [sQ4, setSQ4,] = useState('');
    const [sQ5, setSQ5,] = useState('');
    const [sQ6, setSQ6,] = useState('');
    const [secQ1, setSecQ1,] = useState('');
    const [secQ2, setSecQ2,] = useState('');
    const [secQ3, setSecQ3,] = useState('');
    const [secQ4, setSecQ4,] = useState('');
    const [secQ5, setSecQ5,] = useState('');
    const [secQ6, setSecQ6,] = useState('');
    const [chairMan, setChairMan,] = useState('');
    const [chairManPos, setChairManPos,] = useState('');
    const [chairManIMG, setChairManIMG,] = useState([]);
    const [man1, setMan1,] = useState('');
    const [man1Pos, setMan1Pos,] = useState('');
    const [man1IMG, setMan1IMG,] = useState([]);
    const [man2, setMan2,] = useState('');
    const [man2Pos, setMan2Pos,] = useState('');
    const [man2IMG, setMan2IMG,] = useState([]);
    const [man3, setMan3,] = useState('');
    const [man3Pos, setMan3Pos,] = useState('');
    const [man3IMG, setMan3IMG,] = useState([]);
    const [man4, setMan4,] = useState('');
    const [man4Pos, setMan4Pos,] = useState('');
    const [man4IMG, setMan4IMG,] = useState([]);

    const [websiteTermsOfUse, setWebsiteTermsOfUse,] = useState('');
    const [purchasePolicies, setPurchasePolicies,] = useState('');
    const [parsLegal, setParsLegal,] = useState('');
    const [ethics, setEthics,] = useState('');

    const {
        data: general,
        isLoading, 
        error,
    } = useGetGeneralDetailsQuery(generalId);

    const [updateGeneral, 
        { isLoading: isUpdating}
    ] = useUpdateGeneralMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if(general) {
            setAboutUs(general.aboutUs);
            setSocialRes(general.socialRes);
            setSecurity(general.security);
            setAddI(general.addI);
            setCodalPostI(general.codalPostI);
            setFaxI(general.faxI);
            setPhoneI(general.phoneI);
            setAddII(general.addII);
            setCodalPostII(general.codalPostII);
            setFaxII(general.faxII);
            setPhoneII(general.phoneII);
            setMainIMGtitle(general.mainIMGtitle);
            setMainIMGLink(general.mainIMGLink);
            setMainIMG(general.mainIMG);
            setSecIMGtitle(general.secIMGtitle);
            setSecIMGLink(general.secIMGLink);
            setSecIMG(general.secIMG);
            setSQ1(general.sQ1);
            setSQ2(general.sQ2);
            setSQ3(general.sQ3);
            setSQ4(general.sQ4);
            setSQ5(general.sQ5);
            setSQ6(general.sQ6);
            setSecQ1(general.secQ1);
            setSecQ2(general.secQ2);
            setSecQ3(general.secQ3);
            setSecQ4(general.secQ4);
            setSecQ5(general.secQ5);
            setSecQ6(general.secQ6);
            setChairMan(general.chairMan);
            setChairManPos(general.chairManPos);
            setChairManIMG(general.chairManIMG);
            setMan1(general.man1);
            setMan1Pos(general.man1Pos);
            setMan1IMG(general.man1IMG);
            setMan2(general.man2);
            setMan2Pos(general.man2Pos);
            setMan2IMG(general.man2IMG);
            setMan3(general.man3);
            setMan3Pos(general.man3Pos);
            setMan3IMG(general.man3IMG);
            setMan4(general.man4);
            setMan4Pos(general.man4Pos);
            setMan4IMG(general.man4IMG);

            setWebsiteTermsOfUse(general.websiteTermsOfUse);
            setPurchasePolicies(general.purchasePolicies);
            setParsLegal(general.parsLegal);
            setEthics(general.ethics);
        }
    }, [general])

    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

    const submitHandler = async (e) => {

        e.preventDefault();
        const updatedGeneral = {
            generalId,
            aboutUs,
            socialRes,
            security,
            addI,
            addII,
            codalPostI,
            codalPostII,
            faxI,
            faxII,
            phoneI,
            phoneII,
            mainIMGtitle,
            mainIMGLink,
            mainIMG,
            secIMGtitle,
            secIMGLink,
            secIMG,
            sQ1,
            sQ2,
            sQ3,
            sQ4,
            sQ5,
            sQ6,
            secQ1,
            secQ2,
            secQ3,
            secQ4,
            secQ5,
            secQ6,
            chairMan,
            chairManPos,
            chairManIMG,
            man1,
            man1Pos,
            man1IMG,
            man2,
            man2Pos,
            man2IMG,
            man3,
            man3Pos,
            man3IMG,
            man4,
            man4Pos,
            man4IMG,
            websiteTermsOfUse,
            purchasePolicies,
            parsLegal,
            ethics,
        };

        const result = await updateGeneral(updatedGeneral);
        if(result.error) {
            toast.error(result.error);
        } else {
            toast.success(<span className='persianFont'>رویداد با موفقیت آپدیت شد</span>);
            navigate('/admin/generallist');
            console.log('hhhhh')
        }
    }

    // image middleware
    function readmultifiles (files, cb) {
        let urls = [];
        const reader = new FileReader();  
        function readFile(index) {
          if( index >= files.length ) return cb(urls);
          const file = files[index];
          reader.onload = function(e) {  
            const bin = e.target.result;
            urls = [...urls,bin]
            readFile(index+1)
          }
          reader.readAsDataURL(file);
        }
        readFile(0);
    }

    const mainIMGRef = useRef(null);
    const handleMainIMGUpload = (e) => {
        const oneFile = mainIMGRef.current.files;
        readmultifiles(oneFile, function(urls) {
            setMainIMG(urls);
        })
    }
    const secIMGRef = useRef(null);
    const handleSecIMGUpload = (e) => {
        const allFiles = secIMGRef.current.files;
        readmultifiles(allFiles,function(urls){
            setSecIMG(urls);
        })
    }

    const chairManIMGRef = useRef(null);
    const handleChairManIMGUpload = (e) => {
        const allFiles = chairManIMGRef.current.files;
        readmultifiles(allFiles,function(urls){
            setChairManIMG(urls);
        })
    }
    const man1IMGRef = useRef(null);
    const handleMan1IMGUpload = (e) => {
        const allFiles = man1IMGRef.current.files;
        readmultifiles(allFiles,function(urls){
            setMan1IMG(urls);
        })
    }
    const man2IMGRef = useRef(null);
    const handleMan2IMGUpload = (e) => {
        const allFiles = man2IMGRef.current.files;
        readmultifiles(allFiles,function(urls){
            setMan2IMG(urls);
        })
    }
    const man3IMGRef = useRef(null);
    const handleMan3IMGUpload = (e) => {
        const allFiles = man3IMGRef.current.files;
        readmultifiles(allFiles,function(urls){
            setMan3IMG(urls);
        })
    }
    const man4IMGRef = useRef(null);
    const handleMan4IMGUpload = (e) => {
        const allFiles = man4IMGRef.current.files;
        readmultifiles(allFiles,function(urls){
            setMan4IMG(urls);
        })
    }

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 bg-slate-900 overflow-scroll persianFont 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar '
            onScroll={handleScroll}
        >
            <Link to='/admin/generallist' className='flex align-middle btn btn-light my-3 w-fit'>
                <GrLinkNext className='m-auto' /><span className='persianFont '>برگشت</span>
            </Link>

            <FormContainer>
                <h1>نگارش اطلاعات پایه</h1>
                { isLoading && <Loader/> }
                { isUpdating && <Loader/>}
                {isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : (

                <Form onSubmit={ submitHandler } className='persianFont'>

                    <Form.Group controlId='aboutUs'>
                        <Form.Label>درباره ما</Form.Label>
                        <Form.Control
                            as='textarea' rows={3}
                            type='text'
                            placeholder='درباره ما'
                            value={aboutUs}
                            onChange={(e) => setAboutUs(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='socialRes'>
                        <Form.Label>مسئولیت اجتماعی</Form.Label>
                        <Form.Control
                            as='textarea' rows={3}
                            type='text'
                            placeholder='مسئولیت های اجتماعی'
                            value={socialRes}
                            onChange={(e) => setSocialRes(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Row className='my-2 py-2 border'>
                        <Form.Group controlId='security'>
                            <Form.Label>امنیت</Form.Label>
                            <Form.Control
                                as='textarea' rows={3}
                                type='text'
                                placeholder='امنیت'
                                value={security}
                                onChange={(e) => setSecurity(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='websiteTermsOfUse'>
                            <Form.Label>شرایط استفاده از سایت</Form.Label>
                            <Form.Control
                                as='textarea' rows={3}
                                type='text'
                                placeholder='شرایط استفاده از سایت'
                                value={websiteTermsOfUse}
                                onChange={(e) => setWebsiteTermsOfUse(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='purchasePolicies'>
                            <Form.Label>شرایط خرید</Form.Label>
                            <Form.Control
                                as='textarea' rows={3}
                                type='text'
                                placeholder='شرایط خرید'
                                value={purchasePolicies}
                                onChange={(e) => setPurchasePolicies(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='parsLegal'>
                            <Form.Label>قوانین پارس</Form.Label>
                            <Form.Control
                                as='textarea' rows={3}
                                type='text'
                                placeholder='قوانین پارس'
                                value={parsLegal}
                                onChange={(e) => setParsLegal(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='ethics'>
                            <Form.Label>قوانین پارس</Form.Label>
                            <Form.Control
                                as='textarea' rows={3}
                                type='text'
                                placeholder='قوانین پارس'
                                value={ethics}
                                onChange={(e) => setEthics(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='sQ1'>
                            <Form.Label>سوال امنیتی 1</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='سوال امنیتی 1'
                                value={sQ1}
                                onChange={(e) => setSQ1(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='secQ1'>
                            <Form.Label>پاسخ امنیتی 1</Form.Label>
                            <Form.Control
                                as='textarea' rows={3}
                                type='text'
                                placeholder='پاسخ امنیتی 1'
                                value={secQ1}
                                onChange={(e) => setSecQ1(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='sQ2'>
                            <Form.Label>سوال امنیتی 2</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='سوال امنیتی 2'
                                value={sQ2}
                                onChange={(e) => setSQ2(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='secQ2'>
                            <Form.Label>پاسخ امنیتی 2</Form.Label>
                            <Form.Control
                                as='textarea' rows={3}
                                type='text'
                                placeholder='سوال امنیتی 2'
                                value={secQ2}
                                onChange={(e) => setSecQ2(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='sQ3'>
                            <Form.Label>سوال امنیتی 3</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='سوال امنیتی 3'
                                value={sQ3}
                                onChange={(e) => setSQ3(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='secQ3'>
                            <Form.Label>پاسخ امنیتی 3</Form.Label>
                            <Form.Control
                                as='textarea' rows={3}
                                type='text'
                                placeholder='سوال امنیتی 3'
                                value={secQ3}
                                onChange={(e) => setSecQ3(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='sQ4'>
                            <Form.Label>سوال امنیتی 4</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='سوال امنیتی 4'
                                value={sQ4}
                                onChange={(e) => setSQ4(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='secQ1'>
                            <Form.Label>پاسخ امنیتی 4</Form.Label>
                            <Form.Control
                                as='textarea' rows={3}
                                type='text'
                                placeholder='سوال امنیتی 4'
                                value={secQ4}
                                onChange={(e) => setSecQ4(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='sQ5'>
                            <Form.Label>سوال امنیتی 5</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='سوال امنیتی 5'
                                value={sQ5}
                                onChange={(e) => setSQ5(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='secQ5'>
                            <Form.Label>پاسخ امنیتی 5</Form.Label>
                            <Form.Control
                                as='textarea' rows={3}
                                type='text'
                                placeholder='سوال امنیتی 5'
                                value={secQ5}
                                onChange={(e) => setSecQ5(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='sQ6'>
                            <Form.Label>سوال امنیتی 6</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='سوال امنیتی 6'
                                value={sQ6}
                                onChange={(e) => setSQ6(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId='secQ6'>
                            <Form.Label>پاسخ امنیتی 6</Form.Label>
                            <Form.Control
                                as='textarea' rows={3}
                                type='text'
                                placeholder='سوال امنیتی 6'
                                value={secQ6}
                                onChange={(e) => setSecQ6(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>

                    </Row>
                    

                    <Form.Group controlId='addI'>
                        <Form.Label>آدرس تهران</Form.Label>
                        <Form.Control
                            as='textarea' rows={3}
                            type='text'
                            placeholder='آدرس 1'
                            value={addI}
                            onChange={(e) => setAddI(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='codalPostI'>
                        <Form.Label>کد پستی تهران</Form.Label>
                        <Form.Control
                            type='Number'
                            placeholder='کد پستی تهران '
                            value={codalPostI}
                            onChange={(e) => setCodalPostI(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='faxI'>
                        <Form.Label>فکس تهران</Form.Label>
                        <Form.Control
                            type='Number'
                            placeholder='فکس تهران'
                            value={faxI}
                            onChange={(e) => setFaxI(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='phoneI'>
                        <Form.Label>تلفن تهران</Form.Label>
                        <Form.Control
                            type='Number'
                            placeholder='تلفن تهران'
                            value={phoneI}
                            onChange={(e) => setPhoneI(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='addII'>
                        <Form.Label>آدرس تهران</Form.Label>
                        <Form.Control
                            as='textarea' rows={3}
                            type='text'
                            placeholder='آدرس 1'
                            value={addII}
                            onChange={(e) => setAddII(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='codalPostII'>
                        <Form.Label>کد پستی تهران</Form.Label>
                        <Form.Control
                            type='Number'
                            placeholder='کد پستی تهران '
                            value={codalPostII}
                            onChange={(e) => setCodalPostII(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='faxII'>
                        <Form.Label>فکس تهران</Form.Label>
                        <Form.Control
                            type='Number'
                            placeholder='فکس تهران'
                            value={faxII}
                            onChange={(e) => setFaxII(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='phoneII'>
                        <Form.Label>تلفن تهران</Form.Label>
                        <Form.Control
                            type='Number'
                            placeholder='تلفن تهران'
                            value={phoneII}
                            onChange={(e) => setPhoneII(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='mainIMGtitle'>
                        <Form.Label>عنوان</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='عنوان تصویر صفحه اول'
                            value={mainIMGtitle}
                            onChange={(e) => setMainIMGtitle(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='mainIMGLink'>
                        <Form.Label>لینک به بیرون</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='فقط ID رویداد درج شود  مثلا: 668c24eccc943bc0af064f5d'
                            value={mainIMGLink}
                            onChange={(e) => setMainIMGLink(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>



                    <Form.Group controlId='secIMGtitle'>
                        <Form.Label>عنوان</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='عنوان تصویر صفحه دوم'
                            value={secIMGtitle}
                            onChange={(e) => setSecIMGtitle(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='secIMGLink'>
                        <Form.Label>لینک به بیرون</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='فقط ID رویداد درج شود  مثلا: 668c24eccc943bc0af064f5d'
                            value={secIMGLink}
                            onChange={(e) => setSecIMGLink(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    {/* image */}
                    <Form.Group controlId='mainIMG'>
                        <Form.Label>تصویر اصلی</Form.Label>
                        <Form.Control
                                type='file'
                                multiple={true}
                                onChange={handleMainIMGUpload}
                                ref={mainIMGRef}
                                accept='image/webp'
                                className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>   
                     <Form.Group controlId='secIMG'>
                        <Form.Label>گالری</Form.Label>
                        <Form.Control
                                type='file'
                                multiple={true}
                                onChange={handleSecIMGUpload}
                                ref={secIMGRef}
                                accept='image/webp'
                                className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>   
                    {/* image */}
                    

                    <Form.Group controlId='chairMan'>
                        <Form.Label>مدیر عامل</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='مدیرعامل'
                            value={chairMan}
                            onChange={(e) => setChairMan(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='chairManPos'>
                        <Form.Label>سمت 0</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='سمت 0'
                            value={chairManPos}
                            onChange={(e) => setChairManPos(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='chairManIMG'>
                        <Form.Label>تصویر مدیرعامل</Form.Label>
                        <Form.Control
                                type='file'
                                multiple={true}
                                onChange={handleChairManIMGUpload}
                                ref={chairManIMGRef}
                                accept='image/webp'
                                className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>



                    <Form.Group controlId='man1'>
                        <Form.Label>عضو 1</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='عضو 1'
                            value={man1}
                            onChange={(e) => setMan1(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='man1Pos'>
                        <Form.Label>سمت 1</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='سمت 1'
                            value={man1Pos}
                            onChange={(e) => setMan1Pos(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='man1IMG'>
                        <Form.Label>تصویر عضو 1</Form.Label>
                        <Form.Control
                                type='file'
                                multiple={true}
                                onChange={handleMan1IMGUpload}
                                ref={man1IMGRef}
                                accept='image/webp'
                                className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>



                    <Form.Group controlId='man2'>
                        <Form.Label>عضو 2</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='عضو 2'
                            value={man2}
                            onChange={(e) => setMan2(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='man2Pos'>
                        <Form.Label>سمت 2</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='سمت 2'
                            value={man2Pos}
                            onChange={(e) => setMan2Pos(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='man2IMG'>
                        <Form.Label>تصویر عضو 2</Form.Label>
                        <Form.Control
                                type='file'
                                multiple={true}
                                onChange={handleMan2IMGUpload}
                                ref={man2IMGRef}
                                accept='image/webp'
                                className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>


                    <Form.Group controlId='man3'>
                        <Form.Label>عضو 3</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='عضو 3'
                            value={man3}
                            onChange={(e) => setMan3(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='man3Pos'>
                        <Form.Label>سمت 3</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='سمت 3'
                            value={man3Pos}
                            onChange={(e) => setMan3Pos(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='man3IMG'>
                        <Form.Label>تصویر عضو 3</Form.Label>
                        <Form.Control
                                type='file'
                                multiple={true}
                                onChange={handleMan3IMGUpload}
                                ref={man3IMGRef}
                                accept='image/webp'
                                className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>


                    <Form.Group controlId='man4'>
                        <Form.Label>عضو 4</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='عضو 4'
                            value={man4}
                            onChange={(e) => setMan4(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='man4Pos'>
                        <Form.Label>سمت 4</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='سمت 4'
                            value={man4Pos}
                            onChange={(e) => setMan4Pos(e.target.value)}
                            className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId='man4IMG'>
                        <Form.Label>تصویر عضو 4</Form.Label>
                        <Form.Control
                                type='file'
                                multiple={true}
                                onChange={handleMan4IMGUpload}
                                ref={man4IMGRef}
                                accept='image/webp'
                                className="bg-transparent border-slate-400 "
                        ></Form.Control>
                    </Form.Group>

                    <Button type='submit' variant='light' className='flex align-middle my-2 w-fit'>
                        <MdUpdate className='m-auto' /><span className='persianFont '>بروزرسانی</span>
                    </Button>
                </Form>
                )}
            </FormContainer> 
        </div>
    </>
  )
}

export default GeneralEditPage;