// import { useMemo, useState } from "react";
import { useGetProductsQuery } from "../../slices/productsApiSlice";
import Message from "./Message";
// import BarLoader from "react-spinners/BarLoader";
import Loader from "../Loader";
import { Link, useParams } from "react-router-dom";
import SearchBox from "../SearchBox";

export default function TVMenu() {
    const { pageNumber, keyword } = useParams();
    const { data, isLoading, error } = useGetProductsQuery({ keyword, pageNumber });
    
    // const [search, setSearch] = useState("");

    // const filteredUsers = useMemo(() => {
    //     if (search) {
    //     return products.filter(
    //         (product) =>
    //         product.name
    //             .toLowerCase()
    //             .indexOf(search.toLocaleLowerCase()) > -1
    //     );
    //     }
    //     return products;
    // }, [products, search]);

    return (
        <>
            <div className='h-[97vh] w-[360px] z-10 absolute right-0 my-[1.5vh] mr-[96px] rounded-lg bg-cl_bg_1 backdrop-blur-sm transition-colors persianFont'>
                <div className="input-group w-full p-4">
                    {/* <input 
                        className="form-control bg-transparent rounded-none border-slate-700 text-gray-200 "
                        placeholder="جستجوی مدل یا سایز..."
                        type="search" 
                        name="search" 
                        value={search} 
                        onChange={e => setSearch(e.target.value)}
                    /> */}
                    <SearchBox/>
                    {/* <h1 className="persianFont text-gray-400 py-4 px-4"><span className='symbol'>  </span>تلویزیون</h1> */}

                </div>
                { isLoading ? (
                    <>
                        {/* <BarLoader  color={'#e2e2e2'} size={20} aria-label="Loading Spinner" data-testid="loader" className='!flex mx-auto'/> */}
                        <Loader/>
                            </>
                    ) : error ? (
                        <Message variant='danger'>
                            { error?.data?.message || error.error}
                        </Message>
                    ) : (
                        <ul >
                            {
                            // filteredUsers.length > 0 ?
                            //     (filteredUsers && filteredUsers.map(product => (
                                data.products.map((product) => (
                                <li 
                                    key={product._id} 
                                    className="hidden text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all persianFont nthChild"
                                >
                                    <Link to={`/product/${product._id}`} className='block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden persianFont'>
                                            {product.name}
                                    </Link>
                                </li>
                                ))
                            // ): null
                            }   
                        </ul>
                    )}
            </div> 
        </>
    )
}

