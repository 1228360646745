import React, { useState } from 'react';
import { useGetAgentsQuery } from '../slices/agentsApiSlice';
import { Topbar } from '../components';
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Loader from '../components/Loader';
import { Row } from 'react-bootstrap';

import { HiDocumentPlus } from "react-icons/hi2";
import { HiDocumentMagnifyingGlass } from "react-icons/hi2";

const Service = () => {

    const { data: agents, isLoading } = useGetAgentsQuery();
    // console.log('agents: ',agents)
    // Check position of Y
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };
    // custom ICON
    const Pmarker = new L.Icon({
        iconUrl: require('../svg/pdot.webp'),
        // iconRetinaUrl: require('../svg/pdot.webp'),
        iconAnchor: [
            17.5, 41
        ],
        popupAnchor: [
            0, -42
        ],
        tooltipAnchor: [0, 42],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(35, 41),
        className: 'leaflet-div-icon'
    });
    /* https://medium.com/@ujjwaltiwari2/a-guide-to-using-openstreetmap-with-react-70932389b8b1 */
    
  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>

        
        
        <div className='w-screen h-full bgservice pt-28 pb-5 pr-24 pl-4 max-sm:p-4 overflow-x-scroll scrollbar persianFont' onScroll={handleScroll}>
            <Row className='text-gray-300 mb-2 persianFont'>
                <div className='w-full mt-10 bg-zinc-800 z-2 overflow-x-scroll scrollbar persianFont' >
                    { isLoading ? (
                            <Loader/>
                        ) : (
                        <MapContainer center={[33.414, 51.553]} zoom={5} scrollWheelZoom={true}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <MarkerClusterGroup showCoverageOnHover={false}>
                                { agents?.map(agent => (
                                        <Marker position={[agent.lx, agent.ly]} className="!bg-transparent" icon={Pmarker}>
                                            <Popup>
                                                <div className='text-gray-200 direct text-center text-lg persianFont'>
                                                    <strong>{agent.name}</strong>
                                                </div>
                                                <div className='text-gray-200 direct text-center persianFont'>
                                                    کدنمایندگی: {agent.code}
                                                </div>
                                                <div className='text-gray-200 direct text-center persianFont'>
                                                    تلفن: {agent.phone}
                                                </div>
                                                <div className='text-gray-200 direct text-center persianFont'>
                                                    آدرس: {agent.address}
                                                </div>
                                            </Popup>
                                        </Marker>
                                ))}
                            </MarkerClusterGroup>
                        </MapContainer>
                )} 
                </div>
            </Row>
            <Row className='text-gray-300 my-2 persianFont'>
                <a href="http://service.parselectric.ir/Consoles/VirtualReception/" className='flex group/edit w-fit transition-all'>
                    <span className='flex group-hover/edit:text-blue-500 transition-all persianFont'> <HiDocumentPlus size={25}/> ثبت درخواست نصب </span>
                </a>
            </Row>
            <Row className='text-gray-300 my-2 persianFont'>
                <a href="http://service.parselectric.ir/Consoles/Recommend/" className='flex group/edit w-fit transition-all'>
                    <span className='flex group-hover/edit:text-blue-500 transition-all persianFont'><HiDocumentMagnifyingGlass size={25}/>رسیدگی به شکایات </span>
                </a>
            </Row>
            
        </div>
        
    </>
    
  )
}

export default Service;