import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Topbar } from '../components';
import FormContainer from '../components/FormContainer';
import Loader from '../components/Loader';
import { useRegisterMutation } from '../slices/userApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';

import generateNo from '../utils/generateNo.js'
const Register = () => {

    const [name, setName] = useState('');

    const [nID, setNiD] = useState('');
    const [mobile, setMobile] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [code, setCode] = useState('');
    const [sentCode, setSentCode] = useState('1');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [register, { isLoading }] = useRegisterMutation();

    const { userInfo } = useSelector((state) => state.auth);

    const { search } = useLocation();
    const sp = new URLSearchParams(search);
    const redirect = sp.get('redirect') || '/';

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [userInfo, redirect, navigate]);

    const submitHandler = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error('پسورد مطابقت ندارد!');
            return
        } else {
            try {
                const res = await register({ name, nID, mobile, email, password }).unwrap();
                dispatch(setCredentials({ ...res, }));
                navigate(redirect);
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };
    // compare code
    const compareCode = (code, sentCode) => {
        if (code/1 === sentCode/1) {
            return true;   
        } else {
            return false;
        }
    };

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
                <Topbar />
            </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 accountBg overflow-scroll persianFont 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar pb-48'
            onScroll={handleScroll}
        >
            
            <FormContainer>
                <strong><h1 className="text-center persianFont">خوش آمدید!</h1></strong>
                <h2 className="text-center persianFont">ایجاد حساب کاربری</h2>
                <Form onSubmit={submitHandler} >

                    {/* name */}
                    <Form.Group controlId="name" className="my-1">
                        <Form.Label>نام و نام خانوادگی</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="نام خود را وارد نمائید"
                            value={name}
                            onChange={(e) => setName(e.target.value)} 
                            className="bg-transparent border-slate-400"
                        ></Form.Control>
                    </Form.Group>

                    {/* national ID */}
                    <Form.Group controlId="nID" className="my-1">
                        <Form.Label>کد ملی</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="کد ملی را وارد نمائید"
                            value={nID}
                            onChange={(e) => setNiD(e.target.value)} 
                            className="bg-transparent border-slate-400"
                        ></Form.Control>
                    </Form.Group>

                    {/* mobile */}
                    <Form.Group controlId="mobile" className="my-1">
                        <Form.Label>تلفن همراه</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="09XXXXXXXXX"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)} 
                            className="bg-transparent border-slate-400"
                        ></Form.Control>
                    </Form.Group>

                    {/* email */}
                    <Form.Group controlId="email" className="my-1">
                        <Form.Label>آدرس ایمیل</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="ایمیل را وارد نمائید"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} 
                            className="bg-transparent border-slate-400"
                        ></Form.Control>
                    </Form.Group>

                    {/* password */}
                    <Form.Group controlId="password" className="my-1">
                        <Form.Label>رمز ورود</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="پسورد را وارد نمائید"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)} 
                            className="bg-transparent border-slate-400"
                        ></Form.Control>
                    </Form.Group>

                    {/* confirm password */}
                    <Form.Group controlId="confirmPassword" className="my-1">
                        <Form.Label>تایید رمز ورود</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="پسورد را تائید نمائید"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                            className="bg-transparent border-slate-400"
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="number" className="my-1">
                        <Row>
                            <Form.Label>احراز هویت</Form.Label>
                            <Col md={4}> 
                                <Button variant="outline-light" className="my-1 text-xs pb-2" onClick={() => setSentCode(generateNo())}>ارسال کد</Button>
                                {/* ****** = ****** */}
                            </Col>  
                            <Col md={8}>
                            <Form.Control
                                type="text"
                                placeholder="کد ارسال شده را وارد نمائید"
                                value={code}
                                onChange={(e) => setCode(e.target.value)} 
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                            </Col>
                            {/* <Col md={2}> 
                                <Button className="my-1 symbol text-xs pb-2" onClick={() => compareCode(code, sentCode)}></Button>
                            </Col>   */}
                        </Row>
                    </Form.Group>
                    {
                        compareCode(code, sentCode) ? 
                        <Button type="submit" variant="outline-light" className="my-3 px-4 pb-2 text-xs" disabled={ isLoading }>ایجاد حساب کاربری</Button> 
                        : null
                    }
                    
                    { isLoading && <Loader/> }
                </Form>
                <Row className="py-1">
                    <Col>
                        حساب کاربری دارید! <Link to={ redirect ? `/login?redirect=${redirect}` : '/login' } className="hover:text-white transition-all">ورود به حساب کاربری</Link>
                    </Col>
                </Row>
            </FormContainer>
        </div>
    </>
  )
}

export default Register;