import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import  { Provider } from 'react-redux';
import store from './store.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import 'bootstrap/dist/js/bootstrap';
import './assets/styles/index.css';
import './assets/js/searchfn.js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PrivateRoute from './components/PrivateRoute.jsx';
import AdminRoute from './components/AdminRoute.jsx';
import Home from './pages/Home';
import TVproduct from './pages/TVproduct';
import TVprd from './pages/TVprd.jsx';
import Service from './pages/Service.jsx';
import Event from './pages/Event.jsx';
import Events from './pages/Events.jsx';
import Cart from './pages/Cart.jsx';
import Login from './pages/Login.jsx';
import Register from './pages/Register.jsx';
import Shipping from './pages/Shipping.jsx';
import Payment from './pages/Payment.jsx';
import PlaceOrder from './pages/PlaceOrder.jsx';
import Order from './pages/Order.jsx';
import Profile from './pages/Profile.jsx';
import OrderListPage from './pages/admin/OrderListPage.jsx';
import ProductListPage from './pages/admin/ProductListPage.jsx';
import ProductEditPage from './pages/admin/ProductEditPage.jsx';
import EventListPage from './pages/admin/EventListPage.jsx';
import EventEditPage from './pages/admin/EventEditPage.jsx';
import UserListPage from './pages/admin/UserListPage.jsx';
import UserEditPage from './pages/admin/UserEditPage.jsx';
import GeneralListPage from './pages/admin/GeneralListPage.jsx';
import GeneralEditPage from './pages/admin/GeneralEditPage.jsx';
import AboutUs from './pages/AboutUs.jsx';
import Security from './pages/Security.jsx';
import AgentListPage from './pages/admin/AgentListPage.jsx';
import AgentEditPage from './pages/admin/AgentEditPage.jsx';
import Responsibility from './pages/Responsibility.jsx';
import WebsiteTermsOfUse from './pages/WebsiteTermsOfUse.jsx';
import PurchasePolicies from './pages/PurchasePolicies.jsx';
import ParsLegal from './pages/ParsLegal.jsx';
import Ethics from './pages/Ethics.jsx';
import SiteMap from './pages/SiteMap.jsx';


const router = createBrowserRouter (
  createRoutesFromElements (
    <Route path='/' element={<App />}>
      <Route index={true} path='/' element={<Home/>}/>
      <Route path='/search/:keyword' element={<Home/>}/>
      <Route path='/search/:keyword/page/:pageNumber' element={<Home/>}/>
      {/* <Route path='/event/:keyword' element={<Home/>}/>
      <Route path='/event/:keyword/page/:pageNumber' element={<Home/>}/> */}
      <Route path='/product/:id' element={<TVproduct/>}/>
      <Route path='/product' element={<TVprd/>}/>
      <Route path='/product/search/:keyword' element={<TVprd/>}/>
      <Route path='/product/page/:pageNumber' element={<TVprd/>}/>
      <Route path='/product/search/:keyword/page/:pageNumber' element={<TVprd/>}/>
      <Route path='/service' element={<Service/>}/>
      <Route path='/event/:id' element={<Event/>}/>
      <Route path='/event' element={<Events/>}/>
      {/* <Route path='/event/search/:keyword' element={<Events/>}/> */}
      <Route path='/event/page/:pageNumber' element={<Events/>}/>
      {/* <Route path='/event/search/:keyword/page/:pageNumber' element={<Events/>}/> */}
      <Route path='/cart' element={<Cart/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/register' element={<Register/>}/>
      <Route path='/aboutus' element={<AboutUs/>}/>
      <Route path='/security' element={<Security/>}/>
      <Route path='/responsibility' element={<Responsibility/>}/>
      <Route path='/websiteTermsOfUse' element={<WebsiteTermsOfUse/>}/>
      <Route path='/purchasePolicies' element={<PurchasePolicies/>}/>
      <Route path='/parsLegal' element={<ParsLegal/>}/>
      <Route path='/ethics' element={<Ethics/>}/>
      <Route path='/sitemap' element={<SiteMap/>}/>

      <Route path='' element={<PrivateRoute/>}>
        <Route path='/shipping' element={<Shipping/>}/>
        <Route path='/payment' element={<Payment/>}/>
        <Route path='/placeorder' element={<PlaceOrder/>}/>
        <Route path='/order/:id' element={<Order/>}/>
        <Route path='/profile' element={<Profile/>}/>
      </Route>

      <Route path='' element={<AdminRoute/>}>
        <Route path='/admin/orderlist' element={<OrderListPage/>}/>
        <Route path='/admin/productlist' element={<ProductListPage/>}/>
        <Route path='/admin/productlist/page/:pageNumber' element={<ProductListPage/>}/>
        <Route path='/admin/product/:id/edit' element={<ProductEditPage/>}/>
        <Route path='/admin/eventlist' element={<EventListPage/>}/>
        <Route path='/admin/eventlist/page/:pageNumber' element={<EventListPage/>}/>
        <Route path='/admin/event/:id/edit' element={<EventEditPage/>}/>
        <Route path='/admin/userlist' element={<UserListPage/>}/>
        <Route path='/admin/user/:id/edit' element={<UserEditPage/>}/>
        <Route path='/admin/generallist' element={<GeneralListPage/>}/>
        <Route path='/admin/general/:id/edit' element={<GeneralEditPage/>}/>
        <Route path='/admin/agentlist' element={<AgentListPage/>}/>
        <Route path='/admin/agent/:id/edit' element={<AgentEditPage/>}/>
      </Route>
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <RouterProvider router={router}/>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
