import { useGetEventsQuery } from '../../slices/eventsApiSlice'; 
import Message from "./Message";
import Loader from "../Loader";
import { Link, useParams } from 'react-router-dom';

import { ImNewspaper } from "react-icons/im";


export default function NewsMenu() {
    const { pageNumber } = useParams();
    const { data, isLoading, error } = useGetEventsQuery({ pageNumber });

    return (
        <>
            <div className='h-[97vh] w-[360px] z-10 absolute right-0 my-[1.5vh] mr-[96px] rounded-lg bg-cl_bg_1 backdrop-blur-sm transition-colors persianFont'>
                <h1 className="flex text-gray-400 py-4 px-4 persianFont"><ImNewspaper className='ml-4' size={35}/>آخرین رویدادها</h1>
                { isLoading ? (
                    <>
                        <Loader/>
                    </>
                ) : error ? (
                    <Message variant='danger'>
                        { error?.data?.message || error.error}
                    </Message>
                ) : (    
                <ul>
                    {data.events.map(event => (
                        <li 
                            key={event._id} 
                                className="hidden persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all nthChild"
                        >
                            <Link to={`/event/${event._id}`}  className='block persianFont w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden truncate persianFont'>
                                {event.title} 
                            </Link>
                        </li>
                    ))
                    }
                </ul>
                
                )}
                <Link to='/event' className='block persianFont text-gray-500 text-xs absolute w-[360px] bottom-0 py-3 px-4 overflow-hidden truncate hover:text-gray-200 persianFont'>
                    <strong>آرشیو</strong>
                </Link>
            </div>  
        </> 
    )

}