
import React, { useState, useEffect } from 'react'
import { Topbar } from '../../components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/navigation/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import { useUpdateUserMutation, useGetUserDetailsQuery } from '../../slices/userApiSlice.js';
import { GrLinkNext } from "react-icons/gr";
import { MdUpdate } from "react-icons/md";

const UserEditPage = () => {

    const { id: userId } = useParams();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState(0);
    const [nID, setNID] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);

    const { 
        data: user, 
        isLoading, 
        refetch, 
        error,
    } = useGetUserDetailsQuery(userId);

    const [updateUser, 
        { isLoading: isUpdating }
    ] = useUpdateUserMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if(user) {
            setName(user.name);
            setEmail(user.email);
            setMobile(user.mobile);
            setNID(user.nID);
            setIsAdmin(user.isAdmin);
        }
    }, [user]);



    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };


    const submitHandler = async (e) => {

        e.preventDefault();
        try {
            await updateUser ({
                userId,
                name,
                email,
                mobile,
                nID,
                isAdmin,
            });
            toast.success(<span className='persianFont'>کاربر با موفقیت آپدیت شد</span>);
            refetch();
            navigate('/admin/userlist');
        } catch (err) {
            toast.error(err?.data?.message || err.error)
        }
    }


  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 bg-slate-900 overflow-scroll persianFont 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar '
            onScroll={handleScroll}
        >
            
            <Link to='/admin/userlist' className='flex align-middle btn btn-light my-3 w-fit'>
                <GrLinkNext className='m-auto' /><span className='persianFont '>برگشت</span>
            </Link>

            <FormContainer>
                <h1>نگارش کاربر</h1>
                { isUpdating && <Loader/> }

                {isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : (
                    <Form onSubmit={ submitHandler }>
                        <Form.Group controlId='name'>
                            <Form.Label>نام</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='نام / مدل'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='email'>
                            <Form.Label>ایمیل</Form.Label>
                            <Form.Control
                                type='email'
                                placeholder='a@b.com'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>


                        <Form.Group controlId='mobile'>
                            <Form.Label>همراه</Form.Label>
                            <Form.Control
                                type='Number'
                                placeholder='موبایل'
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='nID'>
                            <Form.Label>کدملی</Form.Label>
                            <Form.Control
                                type='Number'
                                placeholder='کدملی'
                                value={nID}
                                onChange={(e) => setNID(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='isAdmin'>
                            <Form.Check
                                type='checkbox'
                                label='Is Admin'
                                checked={isAdmin}
                                onChange={(e) => setIsAdmin(e.target.checked)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Check>
                        </Form.Group>

                        
                        <Button type='submit' variant='light' className='flex align-middle my-2 w-fit'>
                            <MdUpdate className='m-auto' /><span className='persianFont '>بروزرسانی</span>
                        </Button>
                    </Form>
                )}

            </FormContainer>

        </div>
    </>
  )
}

export default UserEditPage;