// import { useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, Table, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Message from '../../components/navigation/Message';
import Loader from '../Loader';
import { useGetEventsQuery, useCreateEventMutation, useDeleteEventMutation } from '../../slices/eventsApiSlice.js';
import EventPaginate from '../EventPaginate';
import { toast } from 'react-toastify';
// import ExcelExport from '../ExcelExport.jsx';

import { CiEdit } from "react-icons/ci";
import { TbTrashXFilled } from "react-icons/tb";

const EventList = () => {

    const { pageNumber, keyword } = useParams();

    const { data, isLoading, error, refetch } = useGetEventsQuery({ keyword, pageNumber });

    const [createEvent, { isLoading: loadingCreate }] = useCreateEventMutation();

    const [deleteEvent, { isLoading: loadingDelete }] = useDeleteEventMutation();

    const deletehandler = async(id) => {
        if (window.confirm('آیا از حذف کردن این رویداد مطمئن هستید؟')) {
            try {
                await deleteEvent(id);
                toast.success(<span className='persianFont'>رویداد با موفقیت حذف شد</span>);
                refetch();
            } catch (err) {
                toast.error(err?.data?.Message || err.error);
            }
        }
    };

    const createEventHandler = async () => {
        if(window.confirm('آیا از ایجاد رویداد جدید مطمئن هستید؟')){
            try {
                await createEvent();
                toast.success(<span className='persianFont'>رویداد با موفقیت اضافه شد</span>);
                refetch();
                console.log('fetch',createEvent)
            } catch (err) {
                toast.error(err?.data?.Message || err.error);
            }
        }
    }

  return (
    <>
        <Row>
            <Col>
                <h1>رویدادها</h1>
            </Col>
            
            <Col>
                {/* <div className="input-group w-full p-0">
                    <input 
                        className="form-control bg-transparent rounded-none border-slate-700 text-gray-200 "
                        placeholder="جستجوی عنوان..."
                        type="search" 
                        name="search" 
                        value={search} 
                        onChange={e => setSearch(e.target.value)}
                        disabled
                    />
                </div> */}
            </Col>
            <Col >
                
                {/* <ExcelExport data={data} fileName="Events" /> */}
                
            </Col>
            <Col dir='ltr' className='text-start ml-4 p-0'>
                <Button className='flex btn-sm' variant="light" onClick={ createEventHandler }>
                    <span className='mr-2'>اضافه کردن رویداد جدید</span><CiEdit size={18}/> 
                </Button>
            </Col>
            
        </Row>
        { loadingCreate && <Loader/> }
        { loadingDelete && <Loader/> }
        { isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : (
            <>
                <Row className='h-fit mt-2 overflow-y-auto scrollbar'>
                    <Table variant='dark' striped hover responsive className="table-sm border-dark text-sm text-center align-middle persianFont">
                        <thead>
                            <tr className='p-0 align-middle'>
                                <th>id</th>
                                <th>عنوان</th>
                                <th>نگارش / حذف</th>
                            </tr> 
                        </thead>
                        <tbody>
                            { 
                            // filteredUsers.events.length > 0 ?
                            //     (filteredUsers.events.map((event) => (
                                    data.events.map((event) => (
                                <tr key={ event._id }>
                                    <td>{ event._id }</td>
                                    <td className='max-w-64 truncate'>{ event.title }</td>
                                    <td>
                                        <LinkContainer to={`/admin/event/${event._id}/edit`}>
                                            <Button variant='light' className='btn-sm mx-2 !bg-transparent border-none text-gray-300 hover:text-sky-600'>
                                                <CiEdit size={18}/>
                                            </Button>
                                        </LinkContainer>
                                        <Button variant='light' onClick={ () => deletehandler(event._id) }
                                            className='btn-sm mx-2 !bg-transparent border-none text-red-300 hover:text-red-600'>
                                            <TbTrashXFilled size={18}/>
                                        </Button>
                                    </td>
                                    </tr>
                                ))
                                // ) : null
                                }
                        </tbody>
                    </Table>
                </Row>
                <EventPaginate pages={data.pages} page={data.page} isAdmin={true} keynote={keyword ? keyword : ''}/>
            </>
            )
        }
    
    </>
  )
}

export default EventList;