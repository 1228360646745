// import React, { useEffect, useMemo, useState } from 'react';
// import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row } from 'react-bootstrap';
import Message from '../../components/navigation/Message';
import Loader from '../../components/Loader';
import { useGetUsersQuery } from '../../slices/userApiSlice.js';


const UserAnalayze = () => {

    // const [search, setSearch] = useState();
    
    const { data: users, isLoading, error } = useGetUsersQuery();

    // -----------------------
    // const timeData = [
    //     new Date(2023, 7, 31),
    //     new Date(2023, 7, 31, 12),
    //     new Date(2023, 8, 1),
    //     new Date(2023, 8, 1, 12),
    //     new Date(2023, 8, 2),
    //     new Date(2023, 8, 2, 12),
    //     new Date(2023, 8, 3),
    //     new Date(2023, 8, 3, 12),
    //     new Date(2023, 8, 4),
    //   ];
      
    //   const y1 = [5, 5, 10, 90, 85, 70, 30, 25, 25];
    //   const y2 = [90, 85, 70, 25, 23, 40, 45, 40, 50];
      
    //   const valueFormatter = (date) =>
    //     date.getHours() === 0
    //       ? date.toLocaleDateString('fa-IR', {
    //           month: '2-digit',
    //           day: '2-digit',
    //         })
    //       : date.toLocaleTimeString('fa-IR', {
    //           hour: '2-digit',
    //         });
      
    //   const config = {
    //     series: [{ data: y1 }, { data: y2 }],
    //     height: 300,
    //     topAxis: 'half days',
    //     leftAxis: null,
    //   };
    //   const xAxisCommon = {
    //     data: timeData,
    //     scaleType: 'time',
    //     valueFormatter,
    //   };

  return (
    <>
        <Row>
            <Col>
                <h1>وضعیت</h1>
            </Col>
        </Row>
        { isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : (
            <>
                <Row>
                    { users && (users.length) }
                </Row>
            </>
            )
        }
    </>
  )
}


export default UserAnalayze;