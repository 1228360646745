import { useState, useEffect } from "react";
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Message from "../components/navigation/Message";
import Loader from "../components/Loader";
import { useProfileMutation } from "../slices/userApiSlice";
import { setCredentials } from "../slices/authSlice";
import { Topbar } from "../components";
import generateNo from "../utils/generateNo";
import { useGetMyOrdersQuery } from "../slices/ordersApiSlice";

import { BsClockHistory } from "react-icons/bs";
import { FaUserGear } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";

const Profile = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [mobile, setMobile] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [code, setCode] = useState('');
    const [sentCode, setSentCode] = useState('1');

    const dispatch = useDispatch();

    const { userInfo } = useSelector((state) => state.auth);

    const [updateProfile, { isLoading:loadingUpdateProfile }] = useProfileMutation();

    const { data: orders, isLoading, error } = useGetMyOrdersQuery();

    useEffect(() => {
        if (userInfo) {
            setName(userInfo.name);
            setEmail(userInfo.email);
            setMobile(userInfo.mobile);
        }
    }, [userInfo, userInfo.name, userInfo.email, userInfo.mobile]);
    
    const submitHandler = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error('از یکسان بدودن پسورد اطمینان حاصل نمائید')
        } else {
            try {
                const res = await updateProfile({ _id: userInfo._id, name, email, mobile, password }).unwrap();
                dispatch(setCredentials(res));
                toast.success('پروفایل با موفقیت بروزرسانی شد');
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    }
    
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };
    // compare code
    const compareCode = (code, sentCode) => {
        if (code/1 === sentCode/1) {
            return true;   
        } else {
            return false;
        }
    };

    const numberFormat = (value) =>
        new Intl.NumberFormat('fa-IR', {
            style: 'currency',
            currency: 'IRR'
        }).format(value);

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
                <Topbar />
            </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 accountBg overflow-scroll persianFont
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar'
            onScroll={handleScroll}
        >
            <Row className="text-gray-300 pb-20 mb-20">
                <Col md={3}>
                    <h2 className="flex align-middle"><FaUserGear size={25}/><span className="m-2">اطلاعات کاربری</span></h2>
                    <Form onSubmit={submitHandler}>
                        <Form.Group controlId="name" className="my-2">
                            <Form.Label>نام و نام خانوادگی</Form.Label>
                            <Form.Control
                                type="name"
                                placeholder="نام و نام خانوادگی را وارد نمائید"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group> 

                        <Form.Group controlId="email" className="my-2">
                            <Form.Label>ایمیل</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="یمیل را وارد نمائید"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group> 

                        <Form.Group controlId="mobile" className="my-2">
                            <Form.Label>تلفن همراه</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="موبایل را وارد نمائید"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group> 

                        <Form.Group controlId="password" className="my-2">
                            <Form.Label>پسورد</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="پسورد را وارد نمائید"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group> 

                        <Form.Group controlId="confirmPassword" className="my-2">
                            <Form.Label>تائید پسورد</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="پسورد را تائید نمائید"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                        </Form.Group> 

                        <Form.Group controlId="number" className="my-1">
                        <Row>
                            <Form.Label>احراز هویت</Form.Label>
                            <Col md={4}> 
                                <Button variant='light' className="my-1 text-xs pb-2" onClick={() => setSentCode(generateNo())}>ارسال کد</Button>
                                {/* ******* = ******* */}
                            </Col>  
                            <Col md={8}>
                            <Form.Control
                                type="text"
                                placeholder="کد ارسال شده را وارد نمائید"
                                value={code}
                                onChange={(e) => setCode(e.target.value)} 
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                            </Col>
                            {/* <Col md={2}> 
                                <Button className="my-1 symbol text-xs pb-2" onClick={() => compareCode(code, sentCode)}></Button>
                            </Col>   */}
                        </Row>
                    </Form.Group>

                        {
                            compareCode(code, sentCode) ? 
                            <Button type="submit" variant="primary" className="my-3 px-4 pb-2 text-xs" >بروزرسانی</Button> 
                            : null
                        }
                        { loadingUpdateProfile && <Loader/> }
                    </Form>
                </Col>
                <Col md={9} className="flex flex-col space-y-2">
                    <h2 className="flex align-middle"><BsClockHistory size={25}/><span className="m-2">تاریخچه سفارشها</span></h2>
                    { isLoading ? <Loader/> : error ? (
                        <Message variant='danger'>
                            { error?.data?.message || error.error }
                        </Message>
                    ) : (
                        <Table striped hover responsive className="table-sm table-dark text-sm text-center align-middle">
                            <thead>
                                <tr >
                                    <th>ID</th>
                                    <th>تاریخ</th>
                                    <th>جمع کل</th>
                                    <th>پرداخت</th>
                                    <th>ارسال</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { orders.map((order) => (
                                    <tr key={order._id}>
                                        <td>{ order._id }</td>
                                        <td>{ new Date (order.createdAt).toLocaleString('fa-IR', { timeZone: 'Asia/Tehran' })}</td>
                                        <td>{ numberFormat(order.totalPrice) }</td>
                                        <td>{ order.isPaid ? (new Date(order.paidAt).toLocaleString('fa-IR', { timeZone: 'Asia/Tehran' })) : (<IoIosCloseCircle size={18} className="m-auto text-red-700"/>) }</td>
                                        <td>{ order.isDelivered ? (new Date(order.deliveredAt).toLocaleString('fa-IR', { timeZone: 'Asia/Tehran' })) : (<IoIosCloseCircle size={18} className="m-auto text-red-700"/>) }</td>
                                        <td>
                                            <LinkContainer to={`/order/${order._id}`}>
                                                <Button className='btn-sm text-xs py-0' variant="light">
                                                    جزئیات
                                                </Button>
                                            </LinkContainer>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Col>
            </Row>

        </div>
    </>
  )
}

export default Profile;