import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Topbar } from '../components';
import FormContainer from '../components/FormContainer';
import Loader from '../components/Loader';
import { useLoginMutation } from '../slices/userApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [login, { isLoading }] = useLoginMutation();

    const { userInfo } = useSelector((state) => state.auth);

    const { search } = useLocation();
    const sp = new URLSearchParams(search);
    const redirect = sp.get('redirect') || '/';

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [userInfo, redirect, navigate]);

    const submitHandler = async (e) => {
        e.preventDefault()
        try {
            const res = await login({ email, password }).unwrap();
            dispatch(setCredentials({ ...res, }));
            navigate(redirect);
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    }

    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
                <Topbar />
            </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 accountBg overflow-scroll persianFont 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar'
            onScroll={handleScroll}
        >
            
            <FormContainer>
                <strong><h1 className="text-center">خوش آمدید!</h1></strong>
                <h2 className="text-center">ورود به حساب کاربری</h2>
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId="email" className="my-1">
                        <Form.Label>آدرس ایمیل</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="ایمیل را وارد نمائید"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} 
                            className="bg-transparent border-slate-400"
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="password" className="my-1">
                        <Form.Label>رمز ورود</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="پسورد را وارد نمائید"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)} 
                            className="bg-transparent border-slate-400"
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="number" className="my-1">
                        {/* <Row>
                            <Form.Label>رمز ارسال شده</Form.Label>
                            <Col md={4}> 
                                <Button className="my-1" onClick={() => setSentCode(generateNo())}>ارسال کد</Button>
                                {console.log(sentCode)}
                            </Col>  
                            <Col md={8}>
                            
                            <Form.Control
                                type="text"
                                placeholder="کد ارسال شده را وارد نمائید"
                                value={code}
                                onChange={(e) => setCode(e.target.value)} 
                                className="bg-transparent border-slate-400"
                            ></Form.Control>
                            {console.log(code)}
                            </Col>
                        </Row> */}
                    </Form.Group>
                    <Button type="submit" variant="outline-light" className="my-2 px-4 pb-2 text-xs " disabled={ isLoading }>ورود</Button>
                    { isLoading && <Loader/> }
                </Form>
                <Row className="py-1">
                    <Col>
                        مشتری جدید هستید؟ <Link to={ redirect ? `/register?redirect=${redirect}` : '/register' } className="hover:text-white transition-all">ایجاد حساب کاربری</Link>
                    </Col>
                </Row>
            </FormContainer>
        </div>
    </>
  )
}

export default Login;