import React, { useState } from 'react'
import { Topbar } from '../components'
import { useGetGeneralsQuery } from '../slices/generalApiSlice';
import { Col, NavDropdown, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const SiteMap = () => {

    const { data: generals } = useGetGeneralsQuery();

    // Check position of Y
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
        <Topbar /> 
        </header>
        
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
                xl:px-28 lg:px-28 2xl:px-28 bg-slate-900 overflow-scroll 
                overflow-x-hidden overflow-y-auto text-gray-400 scrollbar persianFont'
                onScroll={handleScroll}
        >
            <Row className='mb-20'>
                {
                    generals && generals.slice(0, 1).map(general => (
                        <>
                            <Row className='mb-20'>
                                <Col md={9} key={general._id} className='px-10 space-y-10 m-auto'> 
                                    <h1 className='persianFont text-2xl font-bold'>نقشه سایت</h1>
                                    <Row className='border-t-[1px] border-slate-500'>
                                        <h2 className='persianFont my-2'>تلویزیون</h2>
                                        <Col md={3}>
                                            <LinkContainer to='/product'>
                                                <NavDropdown.Item className='text-sky-400 hover:underline hover:text-sky-400 transition-all'>
                                                    <span className='p-2'>تلویزیون</span> 
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                        </Col>
                                    </Row>
                                    <Row className='border-t-[1px] border-slate-500'>
                                        <h2 className='persianFont my-2'>رویدادها</h2>
                                        <Col md={3}>
                                            <LinkContainer to='/events'>
                                                <NavDropdown.Item className='text-sky-400 hover:underline hover:text-sky-400 transition-all'>
                                                    <span className='p-2'>رویدادها</span> 
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                        </Col>
                                    </Row>
                                    <Row  className='border-t-[1px] border-slate-500'>
                                        <h2 className='persianFont my-2'>بورس</h2>
                                        <Col md={3}>
                                            <a href="https://codal.ir/ReportList.aspx?search&Symbol=%D9%84%D9%BE%D8%A7%D8%B1%D8%B3&LetterType=-1&Isic=323002&AuditorRef=-1&PageNumber=1&Audited&NotAudited&IsNotAudited=false&Childs&Mains&Publisher=false&CompanyState=0&Category=-1&CompanyType=1&Consolidatable&NotConsolidatable" className='p-2 text-sky-400 hover:underline hover:text-sky-400 transition-all'>سامانه کدال</a>
                                        </Col>
                                        <Col md={3}>
                                            <a href="https://psaapp.ir/login" className='p-2 text-sky-400 hover:underline hover:text-sky-400 transition-all'>سامانه امور سهام</a>
                                        </Col>
                                        <Col md={3}>
                                            <a href="http://www.tsetmc.com/Loader.aspx?ParTree=151311&i=5187018329202415" className='p-2 text-sky-400 hover:underline hover:text-sky-400 transition-all'>بورس تهران</a>
                                        </Col>
                                    </Row>
                                    <Row className='border-t-[1px] border-slate-500'>
                                        <h2 className='persianFont my-2'>خدمات پس از فروش</h2>
                                        <Col md={3}>
                                            <LinkContainer to='/service'>
                                                <NavDropdown.Item className='text-sky-400 hover:underline hover:text-sky-400 transition-all'>
                                                    <span className='p-2'>نمایندگی ها</span> 
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                        </Col>
                                        <Col md={3}>
                                                <a href="http://service.parselectric.ir/Consoles/VirtualReception/" className='p-2 text-sky-400 hover:underline hover:text-sky-400 transition-all'>درخواست نصب و تعمیر</a>
                                        </Col>
                                        <Col md={3}>
                                                <a href="http://service.parselectric.ir/Consoles/Recommend/" className='p-2 text-sky-400 hover:underline hover:text-sky-400 transition-all'>رسیدگی به شکایت</a>
                                        </Col>
                                    </Row>
                                    <Row className='border-t-[1px] border-slate-500'>
                                        <h2 className='persianFont my-2'>پارس</h2>
                                        <Col md={3}>
                                            <LinkContainer to='/aboutus'>
                                                <NavDropdown.Item className='text-sky-400 hover:underline hover:text-sky-400 transition-all'>
                                                    <span className='p-2'>درباره پارس</span> 
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                        </Col>
                                        <Col md={3}>
                                            <LinkContainer to='/responsibility'>
                                                <NavDropdown.Item className='text-sky-400 hover:underline hover:text-sky-400 transition-all'>
                                                    <span className='p-2'>مسئولیت ها</span> 
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                        </Col>
                                        <Col md={3}>
                                            <LinkContainer to='/security'>
                                                <NavDropdown.Item className='text-sky-400 hover:underline hover:text-sky-400 transition-all'>
                                                    <span className='p-2'>امنیت</span> 
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                        </Col>
                                        <Col md={3}>
                                            <LinkContainer to='/websiteTermsOfUse'>
                                                <NavDropdown.Item className='text-sky-400 hover:underline hover:text-sky-400 transition-all'>
                                                    <span className='p-2'>شرایط استفاده از سایت</span> 
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={3}>
                                            <LinkContainer to='/purchasePolicies'>
                                                <NavDropdown.Item className='text-sky-400 hover:underline hover:text-sky-400 transition-all'>
                                                    <span className='p-2'>شرایط خرید از سایت</span> 
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                        </Col>
                                        <Col md={3}>
                                            <LinkContainer to='/parsLegal'>
                                                <NavDropdown.Item className='text-sky-400 hover:underline hover:text-sky-400 transition-all'>
                                                    <span className='p-2'>قوانین</span> 
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                        </Col>
                                        <Col md={3}>
                                            <LinkContainer to='/ethics'>
                                                <NavDropdown.Item className='text-sky-400 hover:underline hover:text-sky-400 transition-all'>
                                                    <span className='p-2'>خط و مشی سازمان</span> 
                                                </NavDropdown.Item>
                                            </LinkContainer>
                                        </Col>
                                        <Col md={3}>
                                            <a href="https://www.shakhesban.com/markets/stock/%D9%84%D9%BE%D8%A7%D8%B1%D8%B3" className='p-2 text-sky-400 hover:underline hover:text-sky-400 transition-all'>گزارش سالیانه</a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    ))
                }
            </Row>
        </div>
    </>
  )
}


export default SiteMap;