import { useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.css';
import DisableDevtool from 'disable-devtool';
// import 'slick-carousel/slick/slick.css'
// import "slick-carousel/slick/slick-theme.css";
// import 'vazirmatn/Round-Dots/Vazirmatn-RD-font-face.css';
import { Footer, Sidebar } from "./components";

// import Footer from "./components/Footer";

import { Outlet } from "react-router-dom";
import QuickMenu from "./components/QuickMenu";
import { useSelector } from "react-redux";

// noto sans
// import { useLogoutMutation } from '../../slices/userApiSlice';

function App() {

  DisableDevtool();

  const [showSearch, setShowSearch] = useState(false);
  const [showTV, setShowTV] = useState(false);
  const [showStock, setShowStock] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const [showService, setShowService] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showCom, setShowCom] = useState(false);
  const [style, setStyle] = useState("closeSideBar");
  const [showMore, setShowMore] = useState(false);
  
  const { userInfo } = useSelector((state) => state.auth);
  

  return (
    <div className = 'h-screen w-full flex'>
      {/* darck layer for menus */}
      <div className={style}></div>
      <Outlet />

      {/* header */}
      {/* <header className="absolute">
        <Topbar/>
      </header> */}
      {/* Side bar */}
      {
        (!userInfo || (userInfo && !userInfo.isAdmin )) && (
          <Sidebar
            showSearch = { showSearch }
            setShowSearch = { setShowSearch }
            showTV = { showTV }
            setShowTV = { setShowTV }
            showStock = { showStock }
            setShowStock = { setShowStock }
            showNews = { showNews }
            setShowNews = { setShowNews }
            showService = { showService }
            setShowService = { setShowService }
            showAboutUs = { showAboutUs }
            setShowAboutUs = { setShowAboutUs }
            showCom = { showCom }
            setShowCom = { setShowCom }
            style = {style}
            setStyle = {setStyle}
            showMore = { showMore }
            setShowMore = { setShowMore }
        />)
      }
      <QuickMenu/>
      <Footer/>
      <ToastContainer/>
    </div>
  )
}

export default App;