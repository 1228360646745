import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Topbar } from '../components';
import { useGetEventsQuery } from '../slices/eventsApiSlice';
import EventChilds from '../components/EventChilds';
import EventPaginate from '../components/EventPaginate';
import Loader from '../components/Loader';
import Message from '../components/navigation/Message';
import { Col, Row } from 'react-bootstrap';


const Events = () => {

  const { pageNumber } = useParams();
    const { data, isLoading, error } = useGetEventsQuery({ pageNumber });

    // Check position of Y
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };
    
  return (
    <>
      <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
        <Topbar /> 
      </header>
      
      <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 eventsBackground overflow-scroll persianFont
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar '
            onScroll={handleScroll}
        >
      {
            isLoading ? (
              <Loader/>
            ) : error ? (
              <Message variant='danger'>
                { error?.data?.message || error.error }
              </Message>
            ) : (
              <>
                <h1 className='persianFont'>آخرین رویدادها</h1>
                <Row>
                  {
                    data.events.map((event) => (
                      <Col key= {event._id} sm={12} md={6} lg={4} xl={3}>
                        <EventChilds event={event}/>
                      </Col>

                    ))
                  }
                </Row>
                <Row className='mb-20'>
                  <EventPaginate pages={data.pages} page={data.page} />
                </Row>
                
              </>
            )
          }
        </div> 
    </>
  )
}

export default Events;