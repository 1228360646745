import React, { useState, useEffect, useRef } from 'react'
import { Topbar } from '../../components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/navigation/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import { useGetEventDetailsQuery, useUpdateEventMutation } from '../../slices/eventsApiSlice';
import { GrLinkNext } from "react-icons/gr";
import { MdUpdate } from 'react-icons/md';


const EventEditPage = () => {

    const { id: eventId } = useParams();

    const [title, setTitle] = useState('');
    const [lead, setLead] = useState('');
    const [structure, setStructure] = useState('');
    const [link, setLink] = useState('');
    const [figure, setFigure] = useState([]);
    const [images, setImages] = useState([]);
    const [file, setFile] = useState([]);

    const {
        data: event,
        isLoading,
        error,
    } = useGetEventDetailsQuery(eventId);

    const [updateEvent, 
        { isLoading: isUpdating }
    ] = useUpdateEventMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if(event) {
            setTitle(event.title);
            setLead(event.lead);
            setStructure(event.structure);
            setLink(event.link);
            setFigure(event.figure);
            setImages(event.images);
            setFile(event.file);
        }
    }, [event]);

    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

    const submitHandler = async (e) => {

        e.preventDefault();
        const updatedEvent = {
            eventId,
            title,
            lead,
            structure,
            link, 
            figure,
            images,
            file,
        };

        const result = await updateEvent(updatedEvent);
        if(result.error) {
            toast.error(result.error);
        } else {
            toast.success('رویداد با موفقیت آپدیت شد');
            navigate('/admin/eventlist');
        }
    }

    // image middleware
    function readmultifiles (files, cb) {
        let urls = [];
        const reader = new FileReader();  
        function readFile(index) {
          if( index >= files.length ) return cb(urls);
          const file = files[index];
          reader.onload = function(e) {  
            const bin = e.target.result;
            urls = [...urls,bin]
            readFile(index+1)
          }
          reader.readAsDataURL(file);
        }
        readFile(0);
    }

    const figureRef = useRef(null);
    const handleFigureUpload = (e) => {
        const oneFile = figureRef.current.files;
        readmultifiles(oneFile, function(urls) {
            setFigure(urls);
        })
    }
    
    const imageRef = useRef(null);
    const handleImagesUpload = (e) => {
        const allFiles = imageRef.current.files;
        readmultifiles(allFiles,function(urls){
            setImages(urls);
        })
    }

    const fileRef = useRef(null);
    const handleFileUpload = (e) => {
        const allFiles = fileRef.current.files;
        readmultifiles(allFiles,function(urls){
            setFile(urls);
        })
    }

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 bg-slate-900 overflow-scroll persianFont 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar '
            onScroll={handleScroll}
        >

            <Link to='/admin/eventlist' className='flex align-middle btn btn-light my-3 w-fit'>
                <GrLinkNext className='m-auto' /><span className='persianFont '>برگشت</span>
            </Link>

            <FormContainer>
                <h1>نگارش رویداد</h1>
                { isLoading && <Loader/> }
                
                {isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : (
                    <Form onSubmit={ submitHandler }>
                        <Form.Group controlId='title'>
                            <Form.Label>عنوان رویداد</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='عنوان رویداد'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='lead'>
                            <Form.Label>چکیده رویداد</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='چکیده رویداد'
                                value={lead}
                                onChange={(e) => setLead(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>


                        {/* image */}
                        <Form.Group controlId='figure'>
                            <Form.Label>تصویر اصلی</Form.Label>
                            <Form.Control
                                type='file'
                                multiple={true}
                                onChange={handleFigureUpload}
                                ref={figureRef}
                                accept='image/webp'
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>   
                        <Form.Group controlId='images'>
                            <Form.Label>گالری</Form.Label>
                            <Form.Control
                                type='file'
                                multiple={true}
                                onChange={handleImagesUpload}
                                ref={imageRef}
                                accept='image/webp'
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>   
                        {/* image */}


                        <Form.Group controlId='structure'>
                            <Form.Label>بدنه خبر</Form.Label>
                            <Form.Control
                                as="textarea" rows={4}
                                type='text'
                                placeholder='بدنه خبر'
                                value={structure}
                                onChange={(e) => setStructure(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='link'>
                            <Form.Label>لینک به بیرون</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='لینک به بیرون'
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='file'>
                            <Form.Label>file</Form.Label>
                            <Form.Control
                                type='file'
                                multiple={true}
                                onChange={handleFileUpload}
                                ref={fileRef}
                                accept='.pdf'
                                className="bg-transparent border-slate-400 "
                            ></Form.Control>
                        </Form.Group> 

                        {isUpdating && <Loader/>}
                        <Button type='submit' variant='light' className='flex align-middle my-2 w-fit'>
                            <MdUpdate className='m-auto' /><span className='persianFont '>بروزرسانی</span>
                        </Button>
                    </Form>
                )}
            </FormContainer>

        </div>
    </>
  )
}

export default EventEditPage;
