import { SlideShow, Topbar } from '../components';

const Home = () => {
  return (
    <>
        {/* Slide Shows */}
        <SlideShow/>
        <header className={`fixed`}>
          <Topbar />
        </header>
    </>
  )
}

export default Home;

