import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Paginate = ({ pages, page, isAdmin = false, keyword='' }) => {
    //  className="d-flex justify-content-center"
  return (
    pages > 1 && (
        <Pagination size="sm">
            <LinkContainer
                    key={1}
                    to={!isAdmin ? keyword ? `/product/search/${keyword}/page/${1}` : `/product/page/${1}` : `/admin/productlist/page/${1}`}
                >
                <Pagination.First />
            </LinkContainer>
            
            <LinkContainer
                    key={page - 1}
                    to={!isAdmin ? keyword ? `/product/search/${keyword}/page/${page - 1}` : `/product/page/${page - 1}` : `/admin/productlist/page/${page - 1}`}
                >
                <Pagination.Prev />
            </LinkContainer>
            
            { [...Array(pages).keys()].map((x) => (
                <LinkContainer
                    key={x + 1}
                    to={!isAdmin ? keyword ? `/product/search/${keyword}/page/${x + 1}` : `/product/page/${x + 1}` : `/admin/productlist/page/${x + 1}`}
                >
                    <Pagination.Item active={x + 1 === page}>
                        {x + 1}
                    </Pagination.Item>
                </LinkContainer>
            ))}
            <LinkContainer
                    key={page + 1}
                    to={!isAdmin ? keyword ? `/product/search/${keyword}/page/${page + 1}` : `/product/page/${page + 1}` : `/admin/productlist/page/${page + 1}`}
                >
                <Pagination.Next />
            </LinkContainer>
            
            <LinkContainer
                    key={pages}
                    to={!isAdmin ? keyword ? `/product/search/${keyword}/page/${pages}` : `/product/page/${pages}` : `/admin/productlist/page/${pages}`}
                >
                <Pagination.Last />
            </LinkContainer>
        </Pagination>
    )
  )
}

export default Paginate;