import React from 'react'
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { PiChatCircle } from "react-icons/pi";

const SocialMedia = () => {
  return (
    <div className='flex mt-3 text-2xl space-x-1'>
        <a href="https://ir.linkedin.com/company/parselectric"><FaLinkedinIn className='text-blue-400 hover:text-blue-800 transition-all'/></a>
        <a href="https://twitter.com/parselectric"><FaXTwitter className='text-gray-400 hover:text-gray-800 transition-all'/></a>
        <a href="https://www.instagram.com/parselectric_ir/?hl=en"><FaInstagram className='text-purple-400 hover:text-purple-800 transition-all'/></a>
        <a href="!#"><PiChatCircle className='text-green-400 hover:text-green-800 transition-all'/></a>
    </div>
  )
}

export default SocialMedia