import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, Button, Image } from 'react-bootstrap';
import Message from '../navigation/Message';
import Loader from '../Loader';
import { useGetGeneralsQuery } from '../../slices/generalApiSlice.js';
// import { toast } from 'react-toastify';
import { CiEdit } from "react-icons/ci";

const GeneralList = () => {

    const { data, isLoading, error } = useGetGeneralsQuery();

  return (
    <>
        <Row>
            <Col md={6}>
                <h1>اطلاعات پایه</h1>
            </Col>
            <Col md={6}>
                {
                    data && data.map(gnrl => (
                        <LinkContainer to={`/admin/general/${gnrl._id}/edit`}>
                            <Button variant='light' className='fixed btn-sm mx-2 !bg-transparent border-none text-gray-300 hover:text-sky-600'>
                                <CiEdit size={30}/>
                            </Button>
                        </LinkContainer>
                    ))
                }
            </Col>
        </Row>

        { isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : (
            <>
                <Row className='h-96 overflow-y-auto my-4 scrollbar persianFont'>  
                    {
                            data && data.map(gnrl => (
                                <>
                                    <Col md={12}>
                                        <ul key={ gnrl._id }>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>userID</h2>
                                                { gnrl._id }
                                            </li>
                                            <li className='p-2'>
                                                <h2>درباره ما</h2>
                                                { gnrl.aboutUs }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>مسئولیت اجتماعی</h2>
                                                { gnrl.socialRes }
                                            </li>
                                            <li className='p-2'>
                                                <h2>امنیت</h2>
                                                { gnrl.security }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>سیاست استفاده از سایت</h2>
                                                { gnrl.websiteTermsOfUse }
                                            </li>
                                            <li className='p-2'>
                                                <h2>سیاست فروش</h2>
                                                { gnrl.purchasePolicies }
                                            </li>
                                            <li className=' bg-cl_bg_4 p-2'>
                                                <h2>قوانین</h2>
                                                { gnrl.parsLegal }
                                            </li>
                                            <li className='p-2'>
                                                <h2>اخلاق</h2>
                                                { gnrl.ethics }
                                            </li>
                                            <li  className='bg-cl_bg_4 p-2'>
                                                <h2>آدرس کارخانه تهران</h2>
                                                { gnrl.addI }
                                            </li>
                                            <li className='p-2'>
                                                <h2>کد پستی تهران</h2>
                                                { gnrl.codalPostI }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>فکس تهران</h2>
                                                { gnrl.faxI }
                                            </li>
                                            <li className='p-2'>
                                                <h2>تلفن تهران</h2>
                                                { gnrl.phoneI }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>آدرس کارخانه قزوین</h2>
                                                { gnrl.addII }
                                            </li>
                                            <li className='p-2'>
                                                <h2>کد پستی قزوین</h2>
                                                { gnrl.codalPostII }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>فکس قزوین</h2>
                                                { gnrl.faxII }
                                            </li>
                                            <li className='p-2'>
                                                <h2>تلفن قزوین</h2>
                                                { gnrl.phoneII }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>عنوان تصویر اول</h2>
                                                { gnrl.mainIMGtitle }
                                            </li>
                                            <li className='p-2'>
                                                <h2>تصویر اول</h2>
                                              <Image src={ `${ gnrl.mainIMG }` } alt='' className='h-20'/>  
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>لینک مربوطه</h2>
                                                {gnrl.mainIMGLink }
                                            </li>
                                            <li className='p-2'>
                                                <h2>عنوان تصویر سوم</h2>
                                                { gnrl.secIMGtitle }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>تصویر سوم</h2>
                                               <Image src={ `${ gnrl.secIMG }` } alt='' className='h-20'/> 
                                            </li>  
                                            <li className='p-2'>
                                                <h2>لینک مربوطه</h2>
                                                {gnrl.secIMGLink }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>سوال 1</h2>
                                                { gnrl.sQ1 }
                                            </li>
                                            <li className='p-2'>
                                                <h2>پاسخ 1</h2>
                                                { gnrl.secQ1 }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>سوال 2</h2>
                                                { gnrl.sQ2 }
                                            </li>
                                            <li className='p-2'>
                                                <h2>پاسخ 2</h2>
                                                { gnrl.secQ2 }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>سوال 3</h2>
                                                { gnrl.sQ3 }
                                            </li>
                                            <li className='p-2'>
                                                <h2>پاسخ 3</h2>
                                                { gnrl.secQ3 }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>سوال 4</h2>
                                                { gnrl.sQ4 }
                                            </li>
                                            <li className='p-2'>
                                                <h2>پاسخ 4</h2>
                                                { gnrl.secQ4 }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>سوال 5</h2>
                                                { gnrl.sQ5 }
                                            </li>
                                            <li className='p-2'>
                                                <h2>پاسخ 5</h2>
                                                { gnrl.secQ5 }
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>سوال 6</h2>
                                                { gnrl.sQ6 }
                                            </li>
                                            <li className='p-2'>
                                                <h2>پاسخ 6</h2>
                                                { gnrl.secQ6 }
                                            </li>

                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>{ gnrl.chairMan }</h2>
                                                <p>{ gnrl.chairManPos }</p>
                                               <Image src={ `${ gnrl.chairManIMG }` } alt={ gnrl.chairMan } className='h-20'/> 
                                            </li>
                                            <li className='p-2'>
                                                <h2>{ gnrl.man1 }</h2>
                                                <p>{ gnrl.man1Pos }</p>
                                               <Image src={ `${ gnrl.man1IMG }` } alt={ gnrl.man1 } className='h-20'/> 
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>{ gnrl.man2 }</h2>
                                                <p>{ gnrl.man2Pos }</p>
                                                <Image src={ `${ gnrl.man2IMG }` } alt={ gnrl.man2} className='h-20'/>
                                            </li>
                                            <li className='p-2'>
                                                <h2>{ gnrl.man3 }</h2>
                                                <p>{ gnrl.man3Pos }</p>
                                                <Image src={ `${ gnrl.man3IMG }` } alt={ gnrl.man3} className='h-20'/>
                                            </li>
                                            <li className='bg-cl_bg_4 p-2'>
                                                <h2>{ gnrl.man4 }</h2>
                                                <p>{ gnrl.man4Pos }</p>
                                                <Image src={ `${ gnrl.man4IMG }` } alt={ gnrl.man4} className='h-20'/>
                                            </li>


                                        </ul>
                                    </Col>
                                </>
                                
                            ))
                        }
                    
                </Row>
            </>
            )
        }
    
    </>
  )
}

export default GeneralList;