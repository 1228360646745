import { EVENTS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const eventsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEvents: builder.query({
            query: ({ pageNumber }) => ({
                url: `${EVENTS_URL}`,
                params: {
                    pageNumber,
                },
            }),
            keepUnusedDataFor: 5,
            providesTags: ['events'],
        }),
        getEventDetails: builder.query({
            query: (eventId) => ({
                url: `${EVENTS_URL}/${eventId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createEvent: builder.mutation({
            query: () => ({
                url: `${EVENTS_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['Event'],
        }),
        updateEvent: builder.mutation({
            query: (data) => ({
                url: `${EVENTS_URL}/${data.eventId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Events'],
        }),
        deleteEvent: builder.mutation({
            query: (eventId) => ({
                url: `${EVENTS_URL}/${eventId}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const { 
    useGetEventsQuery, 
    useGetEventDetailsQuery,
    useCreateEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation,
} = eventsApiSlice;