
import React, { useState, useEffect, useRef } from 'react'
import { Topbar } from '../../components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Message from '../../components/navigation/Message';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { useUpdateProductMutation, useGetProductDetailsQuery } from '../../slices/productsApiSlice';
import { GrLinkNext } from "react-icons/gr";
import { MdUpdate } from "react-icons/md";

const ProductEditPage = () => {

    const { id: productId } = useParams();

    const [name, setName] = useState('');
    const [price, setPrice] = useState(0);
    const [image, setImage] = useState({});
    const [brand, setBrand] = useState('');
    const [category, setCategory] = useState('');
    const [countInStock, setCountInStock] = useState(0);
    const [description, setDescription] = useState('');
    const [energy, setEnergy] = useState('');
    const [refreshRate, setRefreshRate] = useState('');
    const [colorCoverage, setColorCoverage] = useState('');
    const [brightness, setBrightness] = useState('');
    const [uhd, setUhd] = useState('');
    const [sound, setSound] = useState('');
    const [cabinetColor, setCabinetColor] = useState('');
    const [standColor, setStandColor] = useState('');
    const [bezelType, setBezelType] = useState('');
    const [frontColor, setFrontColor] = useState('');
    const [screenSize, setScreenSize] = useState('');
    const [pictureEngin, setPictureEngin] = useState('');
    const [dimming, setDimming] = useState('');
    const [soundOutPut, setSoundOutPut] = useState('');
    const [hdr, setHdr] = useState('');
    const [modes, setModes] = useState('');
    const [speakerType, setSpeakerType] = useState('');
    const [bluetooth, setBluetooth] = useState('');
    const [slimType, setSlimType] = useState('');
    const [resolution, setResolution] = useState('');
    const [viewingAngle, setViewingAngle] = useState('');
    const [hlg, setHlg] = useState('');
    const [contrast, setContrast] = useState('');
    const [oneBillionColor, setOneBillionColor] = useState('');
    const [hbbTV, setHbbTV] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [epg, setEpg] = useState('');
    const [osdLanguage, setOsdLanguage] = useState('');
    const [aspect, setAspect] = useState('');
    const [os, setOs] = useState('');
    const [ui, setUi] = useState('');
    const [appStore, setAppStore] = useState('');
    const [mirroring, setMirroring] = useState('');
    const [dvbt, setDvbt] = useState('');
    const [analog, setAnalog] = useState('');
    const [xHDMI, setXHDMI] = useState('');
    const [arc, setArc] = useState('');
    const [cec, setCec] = useState('');
    const [usb, setUsb] = useState('');
    const [lan, setLan] = useState('');
    const [av, setAv] = useState('');
    const [future, setFuture] = useState('');
    const [airMouse, setAirMouse] = useState('');
    const [dimensionBase, setDimensionBase] = useState('');
    const [dimensionWbase, setDimensionWbase] = useState('');
    const [carton, setCarton] = useState('');
    const [weight, setWeight] = useState('');
    const [cartonWaight, setCartonWaight] = useState('');
    const [battery, setBattery] = useState('');
    const [rc, setRc] = useState('');
    const [userManual, setUserManual] = useState('');
    const [powerCord, setPowerCord] = useState('');
    const [wifi, setWifi] = useState('');
    const [bt, setBt] = useState('');
    const [rf, setRf] = useState('');
    const [text1head, setText1head] = useState('');
    const [text2head, setText2head] = useState('');
    const [text3head, setText3head] = useState('');
    const [text4head, setText4head] = useState('');
    const [text5head, setText5head] = useState('');
    const [text6head, setText6head] = useState('');
    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');
    const [text3, setText3] = useState('');
    const [text4, setText4] = useState('');
    const [text5, setText5] = useState('');
    const [text6, setText6] = useState('');
    const [text1img, setText1img] = useState({});
    const [text2img, setText2img] = useState({});
    const [text3img, setText3img] = useState({});
    const [text31img, setText31img] = useState({});
    const [text4img, setText4img] = useState({});
    const [text5img, setText5img] = useState({});
    const [text6img, setText6img] = useState({});
    const [top1, setTop1] = useState('');
    const [top1Body, setTop1Body] = useState('');
    const [top1img, setTop1img] = useState({});
    const [top2, setTop2] = useState('');
    const [top2Body, setTop2Body] = useState('');
    const [top2img, setTop2img] = useState({});
    const [top3, setTop3] = useState('');
    const [top3Body, setTop3Body] = useState('');
    const [top3img, setTop3img] = useState({});
    const [top4, setTop4] = useState('');
    const [top4Body, setTop4Body] = useState('');
    const [top4img, setTop4img] = useState({});

    const { 
        data: product, 
        isLoading, 
        error,
    } = useGetProductDetailsQuery(productId);

    const [updateProduct, 
        { isLoading: isUpdating }
    ] = useUpdateProductMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if(product) {
            setName(product.name);
            setPrice(product.price);
            setImage(product.image);
            setBrand(product.brand);
            setCategory(product.category);
            setCountInStock(product.countInStock);
            setDescription(product.description);
            setEnergy(product.energy);
            setRefreshRate(product.refreshRate);
            setColorCoverage(product.colorCoverage);
            setBrightness(product.brightness);
            setUhd(product.uhd);
            setSound(product.sound);
            setCabinetColor(product.cabinetColor);
            setStandColor(product.standColor);
            setBezelType(product.bezelType);
            setFrontColor(product.frontColor);
            setScreenSize(product.screenSize);
            setPictureEngin(product.pictureEngin);
            setDimming(product.dimming);
            setSoundOutPut(product.soundOutPut);
            setHdr(product.hdr);
            setModes(product.modes);
            setSpeakerType(product.speakerType);
            setBluetooth(product.bluetooth);
            setSlimType(product.slimType);
            setResolution(product.resolution);
            setViewingAngle(product.viewingAngle);
            setHlg(product.hlg);
            setContrast(product.contrast);
            setOneBillionColor(product.oneBillionColor);
            setHbbTV(product.hbbTV);
            setSubtitle(product.subtitle);
            setEpg(product.epg);
            setOsdLanguage(product.osdLanguage);
            setAspect(product.aspect);
            setOs(product.os);
            setUi(product.ui);
            setAppStore(product.appStore);
            setMirroring(product.mirroring);
            setDvbt(product.dvbt);
            setAnalog(product.analog);
            setXHDMI(product.xHDMI);
            setArc(product.arc);
            setCec(product.cec);
            setUsb(product.usb);
            setLan(product.lan);
            setAv(product.av);
            setFuture(product.future);
            setAirMouse(product.airMouse);
            setDimensionBase(product.dimensionBase);
            setDimensionWbase(product.dimensionWbase);
            setCarton(product.carton);
            setWeight(product.weight);
            setCartonWaight(product.cartonWaight);
            setBattery(product.battery);
            setRc(product.rc);
            setUserManual(product.userManual);
            setPowerCord(product.powerCord);
            setWifi(product.wifi);
            setBt(product.bt);
            setRf(product.rf);
            setText1head(product.text1head);
            setText1(product.text1);
            setText1img(product.text1img);
            setText2head(product.text2head);
            setText2(product.text2);
            setText2img(product.text2img);
            setText3head(product.text3head);
            setText3(product.text3);
            setText3img(product.text3img);
            setText31img(product.text31img);
            setText4head(product.text4head);
            setText4(product.text4);
            setText4img(product.text4img);
            setText5head(product.text5head);
            setText5(product.text5);
            setText5img(product.text5img);
            setText6head(product.text6head);
            setText6(product.text6);
            setText6img(product.text6img);
            setTop1(product.top1);
            setTop1Body(product.top1Body);
            setTop1img(product.top1img);
            setTop2(product.top2);
            setTop2Body(product.top2Body);
            setTop2img(product.top2img);
            setTop3(product.top3);
            setTop3Body(product.top3Body);
            setTop3img(product.top3img);
            setTop4(product.top4);
            setTop4Body(product.top4Body);
            setTop4img(product.top4img);
        }
    }, [product]);



    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };


    const submitHandler = async (e) => {

        e.preventDefault();
        const updatedProduct = {
            productId,
            name,
            price,
            image,
            brand,
            category,
            countInStock,
            description,
            energy,
            refreshRate,
            colorCoverage,
            brightness,
            uhd,
            sound,
            cabinetColor,
            standColor,
            bezelType,
            frontColor,
            screenSize,
            pictureEngin,
            dimming,
            soundOutPut,
            hdr,
            modes,
            speakerType,
            bluetooth,
            slimType,
            resolution,
            viewingAngle,
            hlg,
            contrast,
            oneBillionColor,
            hbbTV,
            subtitle,
            epg,
            osdLanguage,
            aspect,
            os,
            ui,
            appStore,
            mirroring,
            dvbt,
            analog,
            xHDMI,
            arc,
            cec,
            usb,
            lan,
            av,
            future,
            airMouse,
            dimensionBase,
            dimensionWbase,
            carton,
            weight,
            cartonWaight,
            battery,
            rc,
            userManual,
            powerCord,
            wifi,
            bt,
            rf,
            text1head,
            text1,
            text1img,
            text2head,
            text2,
            text2img,
            text3head,
            text3,
            text3img,
            text31img,
            text4head,
            text4,
            text4img,
            text5head,
            text5,
            text5img,
            text6head,
            text6,
            text6img,
            top1,
            top1Body,
            top1img,
            top2,
            top2Body,
            top2img,
            top3,
            top3Body,
            top3img,
            top4,
            top4Body,
            top4img,
            };

        const result = await updateProduct(updatedProduct);
        if(result.error) {
            toast.error(result.error);
        } else {
            toast.success(<span className='persianFont'>محصول با موفقیت آپدیت شد</span>);
            navigate('/admin/productlist');
        }
    }

    // image middleware
    function readmultifiles(files, cb) {
        let urls = [];
        const reader = new FileReader();  
        function readFile(index) {
          if( index >= files.length ) return cb(urls);
          const file = files[index];
          reader.onload = function(e) {  
            const bin = e.target.result;
            urls = [...urls,bin]
            readFile(index+1)
          }
          reader.readAsDataURL(file);
        }
        readFile(0);
      }

    const imageRef = useRef(null);
    const handleFileUpload = (e) => {
        const allFiles = imageRef.current.files;
        readmultifiles(allFiles,function(urls){
            setImage(urls);
        })
    }


    const text1imgRef = useRef(null);
    const handletext1imgUpload = (e) => {
        const allFiles = text1imgRef.current.files;
        readmultifiles(allFiles,function(urls){
            setText1img(urls);
        })
    }

    const text2imgRef = useRef(null);
    const handletext2imgUpload = (e) => {
        const allFiles = text2imgRef.current.files;
        readmultifiles(allFiles,function(urls){
            setText2img(urls);
        })
    }

    const text3imgRef = useRef(null);
    const handletext3imgUpload = (e) => {
        const allFiles = text3imgRef.current.files;
        readmultifiles(allFiles,function(urls){
            setText3img(urls);
        })
    }
    const text31imgRef = useRef(null);
    const handletext31imgUpload = (e) => {
        const allFiles = text31imgRef.current.files;
        readmultifiles(allFiles,function(urls){
            setText31img(urls);
        })
    }

    const text4imgRef = useRef(null);
    const handletext4imgUpload = (e) => {
        const allFiles = text4imgRef.current.files;
        readmultifiles(allFiles,function(urls){
            setText4img(urls);
        })
    }

    const text5imgRef = useRef(null);
    const handletext5imgUpload = (e) => {
        const allFiles = text5imgRef.current.files;
        readmultifiles(allFiles,function(urls){
            setText5img(urls);
        })
    }

    const text6imgRef = useRef(null);
    const handletext6imgUpload = (e) => {
        const allFiles = text6imgRef.current.files;
        readmultifiles(allFiles,function(urls){
            setText6img(urls);
        })
    }

    const top1imgRef = useRef(null);
    const handleTop1imgUpload = (e) => {
        const allFiles = top1imgRef.current.files;
        readmultifiles(allFiles,function(urls){
            setTop1img(urls);
        })
    }
    const top2imgRef = useRef(null);
    const handleTop2imgUpload = (e) => {
        const allFiles = top2imgRef.current.files;
        readmultifiles(allFiles,function(urls){
            setTop2img(urls);
        })
    }
    const top3imgRef = useRef(null);
    const handleTop3imgUpload = (e) => {
        const allFiles = top3imgRef.current.files;
        readmultifiles(allFiles,function(urls){
            setTop3img(urls);
        })
    }
    const top4imgRef = useRef(null);
    const handleTop4imgUpload = (e) => {
        const allFiles = top4imgRef.current.files;
        readmultifiles(allFiles,function(urls){
            setTop4img(urls);
        })
    }
    // image middleware


  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 bg-slate-900 overflow-scroll persianFont 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar '
            onScroll={handleScroll}
        >
            
            <Link to='/admin/productlist' className='flex align-middle btn btn-light my-3 w-fit'>
                <GrLinkNext className='m-auto' /><span className='persianFont '>برگشت</span>
            </Link>

            <Row>
                <h1>نگارش محصول</h1>
                { isUpdating && <Loader/> }

                {isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : (
                    <Form onSubmit={ submitHandler }>
                        <Row className='border border-gray-500 py-2 my-2'>
                            <Col md={3}>
                                <Form.Group controlId='top1'>
                                    <Form.Label>ویژگی اول</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='عنوان ویژگی اول'
                                        value={top1}
                                        onChange={(e) => setTop1(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='top1Body'>
                                    <Form.Label>توضیحات ویژگی اول</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='توضیحات ویژگی اول'
                                        value={top1Body}
                                        onChange={(e) => setTop1Body(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='top1img'>
                                    <Form.Label>لوگو</Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={false}
                                        onChange={handleTop1imgUpload}
                                        ref={top1imgRef}
                                        accept='image/webp image/avif'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId='top2'>
                                    <Form.Label>ویژگی دوم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='عنوان ویژگی دوم'
                                        value={top2}
                                        onChange={(e) => setTop2(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='top2Body'>
                                    <Form.Label>وضیحات ویژگی دوم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='توضیحات ویژگی دوم'
                                        value={top2Body}
                                        onChange={(e) => setTop2Body(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='top2img'>
                                    <Form.Label>لوگو</Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={false}
                                        onChange={handleTop2imgUpload}
                                        ref={top2imgRef}
                                        accept='image/webp image/avif'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId='top3'>
                                    <Form.Label>عنوان ویژگی سوم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='عنوان ویژگی سوم'
                                        value={top3}
                                        onChange={(e) => setTop3(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='top3Body'>
                                    <Form.Label>توضیحات ویژگی سوم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='توضیحات ویژگی سوم'
                                        value={top3Body}
                                        onChange={(e) => setTop3Body(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='top3img'>
                                    <Form.Label>لوگو</Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={false}
                                        onChange={handleTop3imgUpload}
                                        ref={top3imgRef}
                                        accept='image/webp image/avif'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId='top4'>
                                    <Form.Label>عنوان ویژگی چهارم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='عنوان ویژگی چهارم'
                                        value={top4}
                                        onChange={(e) => setTop4(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='top4Body'>
                                    <Form.Label>توضیحات ویژگی چهارم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='توضیحات ویژگی چهارم'
                                        value={top4Body}
                                        onChange={(e) => setTop4Body(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='top4img'>
                                    <Form.Label>لوگو</Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={false}
                                        onChange={handleTop4imgUpload}
                                        ref={top4imgRef}
                                        accept='image/webp image/avif'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Form.Group controlId='name'>
                                    <Form.Label>نام / مدل</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مدل محصول'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='price'>
                                    <Form.Label>قیمت</Form.Label>
                                    <Form.Control
                                        type='Number'
                                        placeholder='قیمت'
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>


                                {/* image */}
                                <Form.Group controlId='image'>
                                    <Form.Label>گالری تصاویر </Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={true}
                                        onChange={handleFileUpload}
                                        ref={imageRef}
                                        accept='image/webp'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>   
                                {/* image */}


                                <Form.Group controlId='brand'>
                                    <Form.Label>برند</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='برند'
                                        value={brand}
                                        onChange={(e) => setBrand(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='contInStock'>
                                    <Form.Label>موجودی</Form.Label>
                                    <Form.Control
                                        type='Number'
                                        placeholder='موجودی'
                                        value={countInStock}
                                        onChange={(e) => setCountInStock(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='category'>
                                    <Form.Label>دسته بندی</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='دسته بندی'
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='description'>
                                    <Form.Label>توضیحات محصول</Form.Label>
                                    <Form.Control
                                        as="textarea" rows={4}
                                        type='text'
                                        placeholder='توضیحات'
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='energy'>
                                    <Form.Label>مصرف انرژی</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا A+'
                                        value={energy}
                                        onChange={(e) => setEnergy(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='refreshRate'>
                                    <Form.Label>نرخ بروزرسانی</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Refresh rate'
                                        value={refreshRate}
                                        onChange={(e) => setRefreshRate(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='colorCoverage'>
                                    <Form.Label>پوشش رنگ</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Color Coverage'
                                        value={colorCoverage}
                                        onChange={(e) => setColorCoverage(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='brightness'>
                                    <Form.Label>روشنایی</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Brightness'
                                        value={brightness}
                                        onChange={(e) => setBrightness(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='uhd'>
                                    <Form.Label>UHD</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا UHD-4K'
                                        value={uhd}
                                        onChange={(e) => setUhd(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='sound'>
                                    <Form.Label>خروجی صدا</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Speaker Output'
                                        value={sound}
                                        onChange={(e) => setSound(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='cabinetColor'>
                                    <Form.Label>رنگ کابینت</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Back cabinet color'
                                        value={cabinetColor}
                                        onChange={(e) => setCabinetColor(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='standColor'>
                                    <Form.Label>رنگ پایه</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Base (Holder) color'
                                        value={standColor}
                                        onChange={(e) => setStandColor(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='bezelType'>
                                    <Form.Label>بزل</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Bezel typle'
                                        value={bezelType}
                                        onChange={(e) => setBezelType(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='frontColor'>
                                    <Form.Label>رنگ فرانت</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Front color'
                                        value={frontColor}
                                        onChange={(e) => setFrontColor(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='screenSize'>
                                    <Form.Label>سایز نمایشگر</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Screen size'
                                        value={screenSize}
                                        onChange={(e) => setScreenSize(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='pictureEngin'>
                                    <Form.Label>موتور پردازش</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Picture engine enhance'
                                        value={pictureEngin}
                                        onChange={(e) => setPictureEngin(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='dimming'>
                                    <Form.Label>دایمینگ</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Dimming'
                                        value={dimming}
                                        onChange={(e) => setDimming(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                

                            </Col>
                            <Col md={3}>
                                <Form.Group controlId='soundOutPut'>
                                    <Form.Label>نوع خروجی صدا</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Optical / Cox ...'
                                        value={soundOutPut}
                                        onChange={(e) => setSoundOutPut(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='hdr'>
                                    <Form.Label>HDR</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='HDR'
                                        value={hdr}
                                        onChange={(e) => setHdr(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='modes'>
                                    <Form.Label>مدهای تصویر</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا مد فیلم، موسیقی، ورزشی و ...'
                                        value={modes}
                                        onChange={(e) => setModes(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='speakerType'>
                                    <Form.Label>بلندگو وات</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا 2x8w'
                                        value={speakerType}
                                        onChange={(e) => setSpeakerType(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='bluetooth'>
                                    <Form.Label>بلوتوث</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='ورژن بلوتوث'
                                        value={bluetooth}
                                        onChange={(e) => setBluetooth(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='slimType'>
                                    <Form.Label>اسلیم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='عمق تلویزیون...'
                                        value={slimType}
                                        onChange={(e) => setSlimType(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='speakerType'>
                                    <Form.Label>رزولوشن</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا 3840x2160'
                                        value={resolution}
                                        onChange={(e) => setResolution(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='viewingAngle'>
                                    <Form.Label>زاویه دید</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا 178°'
                                        value={viewingAngle}
                                        onChange={(e) => setViewingAngle(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='hlg'>
                                    <Form.Label>HLG</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='High Logarithm gammute'
                                        value={hlg}
                                        onChange={(e) => setHlg(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='contrast'>
                                    <Form.Label>کنتراست</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Contrast'
                                        value={contrast}
                                        onChange={(e) => setContrast(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='oneBillionColor'>
                                    <Form.Label>عمق رنگ</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا 10bit'
                                        value={oneBillionColor}
                                        onChange={(e) => setOneBillionColor(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='hbbTV'>
                                    <Form.Label>hbbTV</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='ورژن HbbTV'
                                        value={hbbTV}
                                        onChange={(e) => setHbbTV(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='subtitle'>
                                    <Form.Label>زیرنویس</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='subtitle'
                                        value={subtitle}
                                        onChange={(e) => setSubtitle(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='epg'>
                                    <Form.Label>راهنمای الکترونیک برنامه ها</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='epg'
                                        value={epg}
                                        onChange={(e) => setEpg(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='osdLanguage'>
                                    <Form.Label>osdLanguage</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='زبان برنامه'
                                        value={osdLanguage}
                                        onChange={(e) => setOsdLanguage(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='aspect'>
                                    <Form.Label>aspect</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='aspect'
                                        value={aspect}
                                        onChange={(e) => setAspect(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='os'>
                                    <Form.Label>os</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='سیستم عامل'
                                        value={os}
                                        onChange={(e) => setOs(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='ui'>
                                    <Form.Label>لانچر</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='نام لانچر'
                                        value={ui}
                                        onChange={(e) => setUi(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='appStore'>
                                    <Form.Label>فروشگاه برنامه ها</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='appStore'
                                        value={appStore}
                                        onChange={(e) => setAppStore(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='mirroring'>
                                    <Form.Label>mirroring</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='mirroring'
                                        value={mirroring}
                                        onChange={(e) => setMirroring(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='dvbt'>
                                    <Form.Label>DVBT2</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='dvbt'
                                        value={dvbt}
                                        onChange={(e) => setDvbt(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                            
                                <Form.Group controlId='analog'>
                                    <Form.Label>Analog</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='analog'
                                        value={analog}
                                        onChange={(e) => setAnalog(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='xHDMI'>
                                    <Form.Label>xHDMI</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='تعداد HDMI'
                                        value={xHDMI}
                                        onChange={(e) => setXHDMI(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='arc'>
                                    <Form.Label>ARC</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='ARC'
                                        value={arc}
                                        onChange={(e) => setArc(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='cec'>
                                    <Form.Label>CEC</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='CEC'
                                        value={cec}
                                        onChange={(e) => setCec(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='usb'>
                                    <Form.Label>USB</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='USB'
                                        value={usb}
                                        onChange={(e) => setUsb(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='lan'>
                                    <Form.Label>lan</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='lan'
                                        value={lan}
                                        onChange={(e) => setLan(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='av'>
                                    <Form.Label>AV</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='av'
                                        value={av}
                                        onChange={(e) => setAv(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='future'>
                                    <Form.Label>future</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا MEMC / ALL ...'
                                        value={future}
                                        onChange={(e) => setFuture(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='airMouse'>
                                    <Form.Label>airMouse</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='airMouse'
                                        value={airMouse}
                                        onChange={(e) => setAirMouse(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='dimensionBase'>
                                    <Form.Label>ابعاد به همراه پایه</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا 1234x12x569 mm'
                                        value={dimensionBase}
                                        onChange={(e) => setDimensionBase(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='dimensionWbase'>
                                    <Form.Label>ابعاد بدون پایه</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا 1234x12x569 mm'
                                        value={dimensionWbase}
                                        onChange={(e) => setDimensionWbase(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='carton'>
                                    <Form.Label>سایز کارتن</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا 1234x12x569 mm'
                                        value={carton}
                                        onChange={(e) => setCarton(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='weight'>
                                    <Form.Label>وزن بدون کارتن</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='weight'
                                        value={weight}
                                        onChange={(e) => setWeight(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='cartonWaight'>
                                    <Form.Label>وزن با کارتن</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='cartonWaight'
                                        value={cartonWaight}
                                        onChange={(e) => setCartonWaight(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='battery'>
                                    <Form.Label>battery</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='battery'
                                        value={battery}
                                        onChange={(e) => setBattery(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='rc'>
                                    <Form.Label>ریموت کنترل</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مدل...'
                                        value={rc}
                                        onChange={(e) => setRc(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='userManual'>
                                    <Form.Label>دفترچه راهنما</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='userManual'
                                        value={userManual}
                                        onChange={(e) => setUserManual(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='powerCord'>
                                    <Form.Label>کابل برق</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='powerCord'
                                        value={powerCord}
                                        onChange={(e) => setPowerCord(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='wifi'>
                                    <Form.Label>نوع و ورژن وای فای</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='wifi'
                                        value={wifi}
                                        onChange={(e) => setWifi(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='bt'>
                                    <Form.Label>bt</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='bt'
                                        value={bt}
                                        onChange={(e) => setBt(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='rf'>
                                    <Form.Label>گیرنده تلویزیون</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='RF'
                                        value={rf}
                                        onChange={(e) => setRf(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                            </Col>

                            <Col md={3}>
                                
                                <Form.Group controlId='text1head'>
                                    <Form.Label>عنوان اول</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='مثلا فناوری HDR'
                                        value={text1head}
                                        onChange={(e) => setText1head(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text1'>
                                    <Form.Label>توضیحات عنوان اول</Form.Label>
                                    <Form.Control
                                        as="textarea" rows={4}
                                        type='text'
                                        placeholder='text1'
                                        value={text1}
                                        onChange={(e) => setText1(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text1img'>
                                    <Form.Label>تصویر عنوان اول</Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={false}
                                        onChange={handletext1imgUpload}
                                        ref={text1imgRef}
                                        accept='image/webp'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>  

                                <Form.Group controlId='text2head'>
                                    <Form.Label>عنوان دوم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='عنوان دوم'
                                        value={text2head}
                                        onChange={(e) => setText2head(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text2'>
                                    <Form.Label>توضیحات عنوان دوم</Form.Label>
                                    <Form.Control
                                        as="textarea" rows={4}
                                        type='text'
                                        placeholder='text2'
                                        value={text2}
                                        onChange={(e) => setText2(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text2img'>
                                    <Form.Label>تصویر عنوان دوم</Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={false}
                                        onChange={handletext2imgUpload}
                                        ref={text2imgRef}
                                        accept='image/webp'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='text3head'>
                                    <Form.Label>عنوان سوم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='عنوان سوم'
                                        value={text3head}
                                        onChange={(e) => setText3head(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text3'>
                                    <Form.Label>توضیحاتن عنوان سوم</Form.Label>
                                    <Form.Control
                                        as="textarea" rows={4}
                                        type='text'
                                        placeholder='این قسمت صرفا برای مقایسه دو تصویر استفاده شود'
                                        value={text3}
                                        onChange={(e) => setText3(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text3img'>
                                    <Form.Label>تصویر SDR</Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={false}
                                        onChange={handletext3imgUpload}
                                        ref={text3imgRef}
                                        accept='image/webp'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text31img'>
                                    <Form.Label>تصویر HDR</Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={false}
                                        onChange={handletext31imgUpload}
                                        ref={text31imgRef}
                                        accept='image/webp image/avif'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='text4head'>
                                    <Form.Label>عنوان چهارم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='عنوان چهارم'
                                        value={text4head}
                                        onChange={(e) => setText4head(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text4'>
                                    <Form.Label>توضیحات عنوان چهارم</Form.Label>
                                    <Form.Control
                                        as="textarea" rows={4}
                                        type='text'
                                        placeholder='text2'
                                        value={text4}
                                        onChange={(e) => setText4(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text4img'>
                                    <Form.Label>تصویر عنوان چهارم</Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={false}
                                        onChange={handletext4imgUpload}
                                        ref={text4imgRef}
                                        accept='image/webp image/gif'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='text5head'>
                                    <Form.Label>عنوان پنجم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='عنوان پنجم'
                                        value={text5head}
                                        onChange={(e) => setText5head(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text5'>
                                    <Form.Label>توضیحات عنوان پنجم</Form.Label>
                                    <Form.Control
                                        as="textarea" rows={4}
                                        type='text'
                                        placeholder='text5'
                                        value={text5}
                                        onChange={(e) => setText5(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text4img'>
                                    <Form.Label>تصویر عنوان چنجم</Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={false}
                                        onChange={handletext5imgUpload}
                                        ref={text5imgRef}
                                        accept='image/webp image/gif'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group controlId='text6head'>
                                    <Form.Label>عنوان ششم</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='عنوان م'
                                        value={text6head}
                                        onChange={(e) => setText6head(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text6'>
                                    <Form.Label>توضیحات عنوان ششم</Form.Label>
                                    <Form.Control
                                        as="textarea" rows={4}
                                        type='text'
                                        placeholder='text6'
                                        value={text6}
                                        onChange={(e) => setText6(e.target.value)}
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group controlId='text6img'>
                                    <Form.Label>تصویر عنوان ششم</Form.Label>
                                    <Form.Control
                                        type='file'
                                        multiple={false}
                                        onChange={handletext6imgUpload}
                                        ref={text6imgRef}
                                        accept='image/webp image/gif'
                                        className="bg-transparent border-slate-400 "
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        <Button type='submit' variant='light' className='flex align-middle my-2 w-fit'>
                            <MdUpdate className='m-auto' /><span className='persianFont '>بروزرسانی</span>
                        </Button>
                    </Form>
                )}
            </Row>
        </div>
    </>
  )
}

export default ProductEditPage;