import React, { useState, useEffect} from 'react'

import { FaEye } from "react-icons/fa";

const Counter = () => {

    const [count, setCount] = useState(0);

    useEffect(() => {
        const storedCount = localStorage.getItem('pageVisits');
        const initialCount = Number(storedCount) || 0;
        setCount(initialCount + 1);
        localStorage.setItem('pageVisits', initialCount + 1);
    },[])
  return (
    <div className='flex text-gray-400 text-sm'><FaEye size={18} className='ml-2'/> { count }</div>
  )
}

export default Counter; 