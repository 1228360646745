import React, { useState } from 'react';
import { useGetProductDetailsQuery, useCreateReviewMutation } from '../slices/productsApiSlice';
import Loader from '../components/Loader';
import Message from '../components/navigation/Message';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ListGroup, Button, Form, Image } from 'react-bootstrap';
import { Topbar } from '../components';
import { addToCart } from '../slices/cartSlice';

import ImageGallery from "react-image-gallery";
import Breadcrumbs from '../components/Breadcrumbs';

import { toast } from 'react-toastify';
import Rating from '../components/Rating';
import Meta from '../components/Meta';
// import Dolby from '../components/svg/Dolby';
// import Hdr from '../components/svg/Hdr';

import { ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage } from 'react-compare-slider';

import { FaChevronDown } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa";
import { MdHdrOn } from "react-icons/md";
import { MdOutlineHdrOff } from "react-icons/md";
import { GrNext } from "react-icons/gr";

const TVproduct = () => {

    
    const { id: productId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [qty, setQty] =useState(1);

    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');

    const { data: product, isLoading, refetch, error } = useGetProductDetailsQuery(productId);

    const [createReview, { isLoading: LoadingProductReview }] = useCreateReviewMutation();
    const { userInfo } = useSelector((state) => state.auth);
    
    const addToCartHandler = () => {
        dispatch(addToCart({ ...product, qty }));
        navigate('/cart')
    }
  
    const [scrollPosition, setScrollPosition] = useState(false);
 
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

    const numberFormat = (value) =>
    new Intl.NumberFormat('fa-IR', {
        style: 'currency',
        currency: 'IRR'
    }).format(value);


    // review form submit
    const submitHandler = async (e) => {
        e.preventDefault();
        
        try {
            await createReview({
                productId,
                rating,
                comment,
            }).unwrap();
            refetch();
            toast.success(<span className='persianFont'>دیدگاه با موفقیت ثبت شد</span>);
            setRating(0);
            setComment(''); 
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    }

    const [showMore, setShowMore] = useState(false);



        const [labelOpacity, setLabelOpacity] = React.useState(1);
        const labelStyle = {
          fontSize: '.75rem',
          position: 'absolute',
          padding: '.25rem',
          color: 'white',
          opacity: labelOpacity,
          borderRadius: '.25rem',
          border: '1px solid white',
          backdropFilter: 'blur(0.25rem) saturate(180%) contrast(80%) brightness(120%)',
          WebkitBackdropFilter: 'blur(0.25rem) saturate(180%) contrast(80%) brightness(120%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          transition: 'opacity 0.25s ease-in-out'
        };

    return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>

        { isLoading ? (
            <>
                {/* <BarLoader  color={'#e2e2e2'} size={20} aria-label="Loading Spinner" data-testid="loader" className='!flex mx-auto'/> */}
                <Loader/>
            </>
        ) : error ? (
            <Message variant='danger'>
                    { error?.data?.message || error.error}
            </Message>
        ) : (
            <>
            <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 tvBackground overflow-scroll 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar'
            onScroll={handleScroll}
            >
                <Meta title={`PARS: ${product.name}`} />
                <Row >
                    <Col md={5} className='mb-2'>
                        <div className='overflow-hidden'>
                            {
                                <ImageGallery 
                                    items={
                                        product.image && product.image.map(fig => (    
                                            {
                                                original: `${fig}`,
                                                thumbnail: `${fig}` 
                                            }
                                        ))    
                                    } 
                                    // thumbnailPosition={'right'} 
                                    // showNav={false}
                                    showPlayButton={false}
                                    isRTL={true}
                                />
                            }
                            
                        </div>     
                    </Col>
                    <Col md={4}>
                        <ListGroup variant='flush' >
                            <ListGroup.Item bg='transparent' className='text-gray-400'>
                            <strong><h1 className='text-3xl persianFont'>تلویزیون مدل { product.name }</h1></strong>
                            </ListGroup.Item>
                        </ListGroup>
                        <ListGroup.Item className='persianFont'>
                            قیمت: { numberFormat(product.price) }
                        </ListGroup.Item>
                        <ListGroup.Item className='text-justify h-64 overflow-y-auto scrollbar persianFont'>
                            {product.description}
                        </ListGroup.Item>
                    </Col>
                    <Col md={3}>
                        <ListGroup variant='flush' >
                            <ListGroup.Item>
                                <Row className='text-gray-400 '>
                                    <Col className='persianFont'>قیمت:</Col>
                                    <Col>
                                        <strong className='persianFont'>{ numberFormat(product.price) }</strong>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                        <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <Row className='text-gray-400'>
                                    <Col className='persianFont'>وضعیت:</Col>
                                    <Col>
                                    <strong className='persianFont'> { product.countInStock > 0 ? 'موجود' : 'ناموجود' } </strong></Col>
                                </Row>
                            </ListGroup.Item>
                            { product.countInStock > 0 && (
                                <ListGroup.Item>
                                    <Row className='text-gray-400 persianFont'>
                                        <Col className='persianFont'>تعداد</Col>
                                        <Col className='persianFont'>
                                            <Form.Control className='p-0 h-6 text-center bg-transparent border-gray-600'
                                                as='select'
                                                value={qty}
                                                onChange={(e) => setQty(Number(e.target.value))}>
                                                    {[...Array(product.countInStock).keys()].map((x) => (
                                                        <option key={ x + 1 } value={ x + 1 } className='text-gray-900 text-xs bg-violet-400'>
                                                            { x + 1 }
                                                        </option>
                                                    ))}
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            )}
                            <ListGroup.Item>
                                <Button
                                    variant="outline-light"
                                    className='persianFont'
                                    type='button'
                                    disabled={ product.countInStock === 0 }
                                    onClick={addToCartHandler}
                                >
                                    اضافه به کارت
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>

                <Row className='mb-8'>
                    <Col md={6} className='my-auto persianFont'>
                        <h2 className='my-2 persianFont font-black text-3xl'>{product.text1head}</h2>
                        <p className='persianFont'>
                            {product.text1}
                        </p>
                    </Col>
                    <Col md={6}>
                        <Image src={product.text1img[0]} alt={product.text1head}/>
                    </Col>
                </Row>
                <Row className='h-fit my-8'>
                    <h2 className='mb-4 persianFont font-black text-center text-3xl'>{product.text2head}</h2>
                    <p className='persianFont text-center'>
                        {product.text2}
                    </p>
                    <Image src={product.text2img[0]} alt={product.text2head} className='w-auto max-h-[400px] my-4 mx-auto'/>
                    
                </Row>
                {
                    product.text3 && product.text3head && product.text3img && (
                        <Row className='h-fit my-8'>
         
                            <Col md={6} className='my-auto'>
                                <h2 className='mb-4 persianFont font-black text-3xl'>{product.text3head}</h2>
                                <p className='persianFont'>
                                    {product.text3}
                                </p>
                            </Col>
                            <Col md={6}>
                                <>
                                    <ReactCompareSlider onPointerDown={() => setLabelOpacity(0)} onPointerUp={() => setLabelOpacity(1)} 
                                        itemOne={<ReactCompareSliderImage src={product.text3img} alt="PARS TV SDR" />} 
                                        itemTwo={<ReactCompareSliderImage src={product.text31img} alt="PARS TV HDR" />} 
                                        handle={<div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%'
                                        }}>
                                            <ReactCompareSliderHandle />
                                                <div style={{
                                                    ...labelStyle,
                                                    translate: '-100% 0',
                                                    left: 0
                                                    }}><MdOutlineHdrOff size={30}/>
                                                </div>
                                                <div style={{
                                                    ...labelStyle,
                                                    translate: '100% 0',
                                                    right: 0
                                                    }}><MdHdrOn size={30}/>
                                                </div>
                                            </div>} />
                                </>
                            </Col>
                        </Row>
                    )
                }
                <Row className='h-fit my-8'>
                    <Col md={6}>
                        <Image src={product.text4img[0]} alt={product.text4head}/>
                    </Col>
                    <Col md={6} className='my-auto'>
                        <h2 className='mb-8 persianFont font-black text-3xl'>{product.text4head}</h2>
                        <p className='persianFont'>
                           {product.text4} 
                        </p>
                    </Col>
                    
                </Row>
                <Row className='h-fit relative my-8'>
                    <h2 className='mb-4 persianFont font-black text-center text-3xl'>{product.text5head}</h2>
                    <p className='persianFont text-center'>{product.text5}</p>
                    <Image src={product.text5img[0]} alt={product.text5head} className='w-auto max-h-[400px] my-4 mx-auto'/>
                </Row>
                
                <Row className='h-fit relative my-8'>
                    <h2 className='mb-4 persianFont font-black text-center text-3xl'>{product.text6head}</h2>
                    <p className='persianFont text-center'>{product.text6}</p>
                    <Image src={product.text6img[0]} alt={product.text6head} className='w-auto max-h-[400px] my-4 mx-auto'/>
                </Row>

                <Row className = 'my-4 max-sm:mb-20'>

                    <Row className='mb-8 relative'>
                        <Image src={require('../svg/TV-1.webp')} alt={'تلویزیون پارس'}/>
                        <h1 className='absolute persianFont mt-44 text-2xl font-black text-center'>ویژگی های برتر</h1>

                        <Col md={3} className='p-2'>
                            <div className='p-2 grid justify-items-center persianFont space-y-1 text-2xl bg-cl_bg_5 rounded-xl outline outline-1 outline-slate-500'>
                                <h2>{product.top1}</h2>
                                <Image src={product.top1img[0]} alt={product.top1} className='w-full my-2'/>
                                <p className='persianFont text-sm'>{product.top1Body}</p>
                            </div>
                        </Col>
                        <Col md={3} className='p-2'>
                            <div className='p-2 grid justify-items-center persianFont space-y-1 text-2xl bg-cl_bg_5 rounded-xl outline outline-1 outline-slate-500'>
                                <h2>{product.top2}</h2>
                                <Image src={product.top2img[0]} alt={product.top2} className='w-full my-2'/>
                                <p className='persianFont text-sm'>{product.top2Body}</p>
                            </div>
                        </Col>
                        <Col md={3} className='p-2'>
                            <div className='p-2 grid justify-items-center persianFont space-y-1 text-2xl bg-cl_bg_5 rounded-xl outline outline-1 outline-slate-500'>
                                <h2>{product.top3}</h2>
                                <Image src={product.top3img[0]} alt={product.top3} className='w-full my-2'/>
                                <p className='persianFont text-sm'>{product.top3Body}</p>
                            </div> 
                        </Col>
                        <Col md={3} className='p-2'>
                            <div className='p-2 grid justify-items-center persianFont space-y-1 text-2xl bg-cl_bg_5 rounded-xl outline outline-1 outline-slate-500'>
                                <h2>{product.top4}</h2>
                                <Image src={product.top4img[0]} alt={product.top4} className='w-full my-2'/>
                                <p className='persianFont text-sm'>{product.top4Body}</p>
                            </div>
                        </Col>
                        <h1 className='text-2xl persianFont mt-4 font-bold text-center'>مشخصات فنی</h1>
                    </Row>
                    {
                        showMore ? (
                            <>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>محصول</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>{product.brand}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>سری</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>{product.name}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>نمایشگر</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>سایز: {product.screenSize}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>رزولوشن: {product.resolution}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>تصویر</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>موتور تصویر: {product.pictureEngin}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>نرخ بروزرسانی: {product.refreshRate}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>دامنه داینامیکی بالا: {product.hdr}</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'></ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>لگاریتم ترکیبی گاما: {product.hlg}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>کنتراست: {product.contrast}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>پوشش رنگ: {product.colorCoverage}</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'></ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>روشنایی: {product.brightness}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>کنتراست: {product.contrast}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>کنترل تاریکی: {product.dimming}</ListGroup.Item>
                                        </ListGroup>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'></ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>زاویه دید: {product.viewingAngle}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>عمق رنگ: {product.oneBillionColor}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>ابعاد تصویر: {product.aspect}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>صدا</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>تکنولوژی صدا: {product.sound}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>خروجی صدا: {product.soundOutPut}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>توان و تعداد بلندگو: {product.speakerType}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>هوشمند</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>سیستم عامل: {product.os}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>رابط کاربری: {product.ui}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>انتقال تصویر: {product.mirroring}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>انرژی</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>گرید انرژی: {product.energy}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>پخش / موج یاب</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>پخش دیجیتال: {product.dvbt}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>پخش آنالوگ: {product.analog}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>اتصال ها</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>HDMI: {product.xHDMI}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>ARC: {product.arc}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>CEC: {product.cec}</ListGroup.Item>
                                        </ListGroup>

                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'></ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>USB: {product.usb}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>lan: {product.lan}</ListGroup.Item>
                                        </ListGroup>
     
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'></ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>wifi: {product.wifi}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>bt: {product.bt}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>rf: {product.rf}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>قابلیت های بیشتر</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>حالت های پخش: {product.mode}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>HbbTV: {product.hbbTV}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>زیرنویس: {product.subtitle}</ListGroup.Item>
                                        </ListGroup>

                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'></ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>MEMC | ALL | VRR: {product.future}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>HbbTV: {product.hbbTV}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>زبان: {product.osdLanguage}</ListGroup.Item>
                                        </ListGroup>
      
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'></ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>EPG: {product.epg}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>طراحی</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>رنگ بدنه: {product.cabinetColor}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>رنگ پایه: {product.standColor}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>رنگ حاشیه: {product.frontColor}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>ابعاد</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>ابعاد: {product.dimensionBase}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>به همراه پایه: {product.dimensionWbase}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>کارتن: {product.carton}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>وزن</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>وزن: {product.weight}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>وزن کارتن: {product.cartonWaight}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='border-slate-500 persianFont'>
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none text-xl'>لوازم جانبی</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>ریموت کنترل: {product.rc}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>َAir Mouse: {product.airMouse}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>باتری: {product.battery}</ListGroup.Item>
                                        </ListGroup>
          
                                        <ListGroup horizontal>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'></ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>دفترچه راهنما: {product.userManual}</ListGroup.Item>
                                            <ListGroup.Item className='text-gray-300 w-1/4 border-none'>کابل برق: {product.powerCord}</ListGroup.Item>
                                        </ListGroup>
                                    </ListGroup.Item>
                                </ListGroup>
                            </>
                        ) : null
                    }
                    <Button className='w-fit mx-auto my-2 rounded-full px-4 bg-transparent transition-all border-gray-500 text-gray-500 hover:text-white' 
                            variant='light' 
                            onClick={ () => setShowMore(!showMore) }
                    >
                        {showMore ? <div className='flex align-middle persianFont'><FaChevronUp size={25}/><span className='mr-2'>کمتر</span></div> 
                                : <div className='flex align-middle persianFont'><FaChevronDown size={25}/><span className='mr-2'>بیشتر</span></div>}
                    </Button>
                </Row>
  
                <div dir='ltr' className='w-full inline-flex align-middle text-gray-400 rounded-md mb-4 p-2 text-xs borderStyle'>
                    <Breadcrumbs/><GrNext className='mx-2'/><span className='text-gray-600 persianFont'>{product.name}</span>
                </div>
                <Row className='review flex text-gray-300 text-sm mb-20'>
                    <Col md={4} className='text-3xl'>
                        {/* <h1 className='my-4 persianFont text-2xl font-black'>دیدگاه ها</h1> */}

                        <Rating
                            value={product.rating/10}
                            text={`${product.rating/10} (${product.numReviews})`}
                        />
                        
                    </Col>
                    <Col md={8} className='text-gray-300 text-sm'>

                        <ListGroup.Item className='text-gray-300 text-sm'>
                                <h2 className='my-2 persianFont'>نوشتن دیدگاه...</h2>
                                { LoadingProductReview && <Loader/> }
                                { userInfo ? (
                                    <Form onSubmit={ submitHandler }>
                                        <Form.Group controlId='rating' className='my-2 persianFont'>
                                            <Form.Label>امتیاز</Form.Label>
                                            <Form.Control 
                                                as='select'
                                                value={rating}
                                                onChange={(e) => setRating(Number(e.target.value))}
                                                className="bg-transparent border-slate-400 text-sm persianFont">
                                                    <option value='' className='bg-gray-800 text-sm'>انتخاب نمره</option>
                                                    <option value='1' className='bg-gray-800 text-sm'>ناکارآمد</option>
                                                    <option value='2' className='bg-gray-800 text-sm'>ضعیف</option>
                                                    <option value='3' className='bg-gray-800 text-sm'>متوسط</option>
                                                    <option value='4' className='bg-gray-800 text-sm'>خوب</option>
                                                    <option value='5' className='bg-gray-800 text-sm'>عالی</option>

                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId='comment' className='my-2 persianFont'>
                                            <Form.Label>نظر</Form.Label>
                                            <Form.Control
                                                as='textarea'
                                                row='3'
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}
                                                className="bg-transparent border-slate-400 text-sm persianFont">
                                            </Form.Control>
                                        </Form.Group>
                                        <Button disabled={LoadingProductReview} type='submit' variant='primary persianFont'>
                                            ارسال
                                        </Button>
                                    </Form>
                                ) : (
                                    <Message variant='light'>برای ارسال دیدگاه باید <Link to='/login' className='text-black underline persianFont'>وارد</Link> شوید</Message>
                                ) }
                            </ListGroup.Item>

                            <h2 className='my-2 persianFont'>دیدگاه ها</h2>

                        { product.reviews.length === 0 && <Message>بدون دیدگاه</Message>}
                        <ListGroup variant='flush' >
                            {product.reviews.map(review => (
                                review.publish && (
                                    <ListGroup.Item key={review._id} className='justify-content-between align-items-center persianFont text-gray-300  text-sm'>
                                        <strong className='persianFont'>{ review.name }</strong>
                                        <Rating value={review.rating} />
                                        <p className=' persianFont'>{ new Date (review.createdAt).toLocaleString('fa-IR', { timeZone: 'Asia/Tehran' }) }</p>
                                        <p className=' persianFont'>{ review.comment }</p>
                                    </ListGroup.Item>  
                                )
                            ))}
                        </ListGroup>
                    </Col>
                </Row>
            </div>
            </>
        ) }
        {/* header */}
    </>
  )
}

export default TVproduct;