import { Helmet } from "react-helmet-async";


const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
    </Helmet>
  )
}

Meta.defaultProps = {
    title: 'PARS Electric',
    description: 'Pars Electric Manufacturing Company, headquartered in Tehran, is a prominent public joint-stock enterprise that has been operational since its incorporation in 1963. As a leader in the manufacturing and assembly of televisions, radios, car audio systems, and components, the company serves both domestic and international markets. Committed to integrating advanced technologies and innovative designs into its product offerings, Pars Electric continuously adapts to the evolving demands of consumers, enriching their entertainment experiences while playing a vital role in the advancement of the electronics industry in the region. Over the years, the company has established a strong reputation for quality and reliability, ensuring that its products meet stringent industry standards. With a dedicated research and development team, Pars Electric focuses on innovation, aiming to launch cutting-edge products that resonate with modern consumers. The companys extensive distribution network enables it to efficiently reach customers, both locally and abroad. Additionally, Pars Electric prioritizes sustainable practices, striving to minimize its environmental footprint through eco-friendly manufacturing processes. As it looks to the future, the company remains committed to enhancing its product portfolio, leveraging emerging technologies, and fostering partnerships that drive growth in an increasingly competitive marketplace.',
    keywords: 'PARS, TV, Television, LED, QLED, miniLED, mLED, uLED, OLED, DLED, electronics, buy electronics, best TV, PARS TV, تلویزیون پارس, پارس,  پارس الکتریک, electronics store',
}

export default Meta;