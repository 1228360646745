export default function NotiMenu() {

    return (

        <div className='h-[97vh] w-[360px] persianFont z-10 absolute right-0 my-[1.5vh] mr-[96px] rounded-lg bg-cl_bg_1 backdrop-blur-sm transition-colors'>
        <ul>
            <li className="text-gray-400 persianFont w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                <a href="/" className="block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden">مورد1</a>
            </li>
        </ul>
        </div>
        
        
    )

}