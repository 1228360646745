import Carousel from 'react-bootstrap/Carousel';
import { Link, useParams } from 'react-router-dom';
import { useGetEventsQuery } from '../../../slices/eventsApiSlice'; 
import { useGetGeneralsQuery } from '../../../slices/generalApiSlice';
import { Image, Row, Container, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// import WeatherApp from '../../Weather';
import { useEffect, useState } from 'react';
import Counter from '../../Counter';
// import { LinkContainer } from 'react-router-bootstrap';
import { AiOutlineFundView } from "react-icons/ai";
import { AiFillProduct } from "react-icons/ai";
import { FaUsersCog } from "react-icons/fa";
import { FaCartFlatbed } from "react-icons/fa6";
import { TfiPencilAlt } from "react-icons/tfi";
import { BsHouseGearFill } from "react-icons/bs";
import { MdSupportAgent } from "react-icons/md";
import { BsCalendar2Date } from "react-icons/bs";


function SlideShow() {
  const { pageNumber } = useParams();
  const { data } = useGetEventsQuery({ pageNumber });
  const { data: generals } = useGetGeneralsQuery();

  const { userInfo } = useSelector((state) => state.auth);


  // time 
  const [time, setTime] = useState(new Date());

  useEffect(() => {
      const interval = setInterval(() => {
        setTime(new Date());
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
    

  return (
    <>
      {
        userInfo && userInfo.isAdmin ? (
          <Container className='top-20 left-0 h-screen z-0 persianFont'>
            <Row xs={2} md={8} className='px-3 pt-36 gap-2 persianFont'>
             
              <Card bg='primary' style={{ width: '12rem' }}>
                <BsCalendar2Date className='size-16'/>
                <Card.Body>
                  <Card.Title className='text-sm'>{time.toLocaleString('fa-IR', { timeZone: 'Asia/Tehran' })}</Card.Title>
                  <Card.Text>
                    {time.getDate()}/{time.getFullYear()}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card bg='secondary' style={{ width: '12rem' }}>
                <AiOutlineFundView className='size-16'/>
                <Card.Body>
                  <Card.Title  className='text-sm'>بازدیدها</Card.Title>
                  <Card.Text>
                    <Counter/>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Row>   
            <Row xs={2} md={8} className='px-3 pt-2 gap-2'>
              <Card className='bg-green-300 hover:bg-green-800 hover:text-gray-100' style={{ width: '12rem' }}>
                <Link to='/admin/productlist'>
                  <AiFillProduct className='size-16'/>
                  <Card.Body>
                    <Card.Title>محصولات</Card.Title>
                    <Card.Text>
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
              <Card className='bg-red-300 hover:bg-red-800 hover:text-gray-100' style={{ width: '12rem' }}>
                <Link to='/admin/userlist'>
                  <FaUsersCog className='size-16'/>
                  <Card.Body>
                    <Card.Title>کاربرها</Card.Title>
                    <Card.Text>
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
              <Card className='bg-blue-300 hover:bg-blue-800 hover:text-gray-100' style={{ width: '12rem' }}>
                <Link to='/admin/orderlist'>
                  <FaCartFlatbed className='size-16'/>
                  <Card.Body>
                    <Card.Title>سفارش ها</Card.Title>
                    <Card.Text>
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
              <Card className='bg-yellow-300 hover:bg-yellow-800 hover:text-gray-100' style={{ width: '12rem' }}>
                <Link to='/admin/eventlist'>
                  <TfiPencilAlt className='size-16'/>
                  <Card.Body>
                    <Card.Title>رویداد ها</Card.Title>
                    <Card.Text>
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
              <Card className='bg-gray-300 hover:bg-gray-800 hover:text-gray-100' style={{ width: '12rem' }}>
                <Link to='/admin/generallist'>
                  <BsHouseGearFill className='size-16'/>
                  <Card.Body>
                    <Card.Title>اطلاعات پایه</Card.Title>
                    <Card.Text>
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
              <Card className='bg-violet-300 hover:bg-violet-800 hover:text-gray-100' style={{ width: '14rem' }}>
                <Link to='/admin/agentlist'>
                  <MdSupportAgent className='size-16'/>
                  <Card.Body>
                    <Card.Title>نمایندگان</Card.Title>
                    <Card.Text>
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            </Row>
              
            
          </Container>

        ) : (
          <div className='absolute block top-0 left-0 w-screen h-screen z-0 bgwall'>  
            <Carousel fade controls={false} indicators={'left-0'}> 

              {
                generals && generals.slice(0, 1).map(general => (
                  <Carousel.Item interval={10000} key={general._id}> 
                    <Image rel='preload' fetchPriority='high' src={ `${general.mainIMG[0] }` } alt={ general.mainIMGtitle } className='d-block w-screen h-screen object-cover'/>
                    <Carousel.Caption className='absolute flex left-0 bottom-8 px-16 justify-end'> 
                      <a href={general.mainIMGLink} className='drop-shadow-[0px_0px_1px_var(--tw-shadow-color)] shadow-black no-underline persianFont text-start font-black text-2xl truncate'>{general.mainIMGtitle}</a> 
                    </Carousel.Caption> 
                  </Carousel.Item>
                  ))
              }    

              {
                data && data.events.slice(0, 1).map(event => (
                  <Carousel.Item interval={10000} key={event._id}> 
                    <Image rel='preload' fetchPriority='high' src={ `${event.figure[0] }` } alt={ event.title } className='d-block w-screen h-screen object-cover'/>
                    <Carousel.Caption className='absolute flex left-0 bottom-8 px-16 justify-end'> 
                      <a href={`/event/${event._id}`} className='drop-shadow-[0px_0px_1px_var(--tw-shadow-color)] shadow-black persianFont no-underline w-full text-left left-0 font-black text-2xl transition-all'>{event.title}</a>  
                    </Carousel.Caption> 
                  </Carousel.Item>
                ))
              }
              {
                generals && generals.slice(0, 1).map(general => (
                  <Carousel.Item interval={10000} key={general._id}> 
                    <Image rel='preload' fetchPriority='high' src={ `${general.secIMG[0] }` } alt={ general.secIMGtitle } className='d-block w-screen h-screen object-cover'/>
                    <Carousel.Caption className='absolute flex left-0 bottom-8 px-16 justify-end'> 
                      <a href={general.secIMGLink} className='drop-shadow-[0px_0px_1px_var(--tw-shadow-color)] shadow-black no-underline persianFont text-start font-bold text-2xl truncate'>{general.secIMGtitle}</a> 
                    </Carousel.Caption> 
                  </Carousel.Item>
                ))
              }
            </Carousel> 
          </div>
        )
      }
    </>
    
  )
}

export default SlideShow;