import React, { useMemo, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, Table, Button } from 'react-bootstrap';
import Message from '../../components/navigation/Message';
import Loader from '../../components/Loader';
import { useGetUsersQuery, useDeleteUserMutation } from '../../slices/userApiSlice.js';
import { toast } from 'react-toastify';

import { CiEdit } from "react-icons/ci";
import { TbTrashXFilled } from "react-icons/tb";
import { VscCheck } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const UserList = () => {

    const [search, setSearch] = useState();
    
    const { data: users, isLoading, error, refetch } = useGetUsersQuery();

    const [deleteUser, { isLoading: loadingDelete }] = useDeleteUserMutation();

    const deletehandler = async (id) => {
        if (window.confirm('آیا از حذف کردن این کاربر مطمئن هستید؟')) {
            try {
                await deleteUser(id);
                toast.success(<span className='persianFont'>کاربر با موفقیت حذف شد</span>);
                refetch();
            } catch (err) {
                toast.error(err?.data?.Message || err.error);
            }
        }
    }

    const filteredUsers = useMemo(() => {
        if (search) {
        return users.filter(
            (event) =>
            event.nID 
            .toLowerCase()
            .indexOf(search.toLocaleLowerCase()) > -1
                
        );
        }
        return users;
    }, [users, search]); 

  return (
    <>
    <Row>
            <Col>
                <h1>کاربرها</h1>
            </Col>
            <Col>
            <div className="input-group w-full p-0">
                    <input 
                        className="form-control bg-transparent rounded-none border-slate-700 text-gray-200 persianFont"
                        placeholder="جستجو بر اساس کد ملی..."
                        type='search' 
                        name='search' 
                        value={search} 
                        onChange={e => setSearch(e.target.value)}
                    />
                </div>
            </Col>

            {/* <Col >
                
                <ExcelExport data={data} fileName="Events" />
                
            </Col> */}
        </Row>
        { loadingDelete && <Loader/> }
        { isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : (
            <>
                {/* <h1>کاربرها</h1> */}
                <Row className='h-80 overflow-y-auto scrollbar'>
                    <Table variant='dark' striped hover responsive className='table-sm border-dark text-sm text-center align-middle mt-2 persianFont'>

                        <thead>
                            <tr>
                                <th>id</th>
                                <th>نام</th>
                                <th>ایمیل</th>
                                <th>کدملی</th>
                                <th>موبایل</th>
                                <th>ADMIN</th>
                                <th>نگارش / حذف</th>
                            </tr> 
                        </thead>
                        <tbody>
                            { filteredUsers.length > 0 ?
                                (filteredUsers && filteredUsers.map((user) => (
                                <tr key={ user._id }>
                                    <td>{ user._id }</td>
                                    <td>{ user.name }</td>
                                    <td><a href={ `mailto:${user.email}` }>{ user.email }</a></td>
                                    <td>{ user.nID }</td>
                                    <td>{ user.mobile }</td>
                                    <td>
                                        { 
                                            user.isAdmin ? (
                                                <VscCheck className='m-auto text-green-600'/>
                                            ) : (
                                                <VscChromeClose  className='m-auto text-red-600'/>
                                            )
                                        }
                                    </td>
                                    <td>
                                        <LinkContainer to={`/admin/user/${user._id}/edit`}>
                                            <Button variant='light' className='btn-sm mx-2 !bg-transparent border-none text-gray-300 hover:text-sky-600'>
                                                <CiEdit size={18}/>
                                            </Button>
                                        </LinkContainer>
                                        <Button variant='light' onClick={ () => deletehandler(user._id) }
                                            className='btn-sm mx-2 !bg-transparent border-none text-red-300 hover:text-red-600'>
                                            <TbTrashXFilled size={18}/>
                                        </Button>
                                    </td>
                                </tr>
                            ))) : null
                            }
                        </tbody>
                    </Table>
                </Row>
            </>
            )
        }
    </>
  )
}

export default UserList;