import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
// import axios  from 'axios';
import { Topbar } from '../components';
// import TVProducts from '../components/TVProducts';
import Loader from '../components/Loader';
import Message from '../components/navigation/Message';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import { Col, Row } from 'react-bootstrap';
import TVProducts from '../components/TVProducts';
import Paginate from '../components/Paginate';
import SearchBoxII from '../components/SearchBoxII';
import Meta from '../components/Meta';
import ProductCarousel from '../components/ProductCarousel';

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css';
import { GrLinkNext } from 'react-icons/gr';

const TVprd = () => {

  const { pageNumber, keyword } = useParams();
    const { data, isLoading, error } = useGetProductsQuery({ keyword, pageNumber });


    // Check position of Y
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

  return (
    <>
      <Meta title='PARS: TV Products' />
      <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 overflow-scroll tvsBackground
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar '
            onScroll={handleScroll}
        >
          {
            isLoading ? (
              <Loader/>
            ) : error ? (
              <Message variant='danger'>
                { error?.data?.message || error.error }
              </Message>
            ) : (
              <>
              <Row>
                <Col md={5} className='p-4'>
                    <h3 className='persianFont mb-4'>بهترین ها</h3>
                    <p className='persianFont text-4xl'>
                      برترین تلویزیون ها بر اساس دیدگاه کاربران
                    </p>
                </Col>
                <Col md={7}>
                  {!keyword ? <ProductCarousel/> : null}
                </Col>
              </Row>
                <Row className='my-2'>
                  <Col md={4} lg={3} className='mb-2'>
                    <h1 className='persianFont'>آخرین محصولات</h1>
                  </Col>
                  <Col md={4} lg={3} className='mb-2'>
                    <SearchBoxII/>
                  </Col>
                  <Col md={4} lg={3} className='mb-2'>
                    {!keyword ? null : (
                      <Link to='/product' className='btn btn-light mb-4 w-fit'>
                        <GrLinkNext className='my-auto mx-2'/>
                      </Link>
                    )}
                  </Col>
                </Row>
                <Row>
                  {
                    data.products.map((product) => (
                      <Col key= {product._id} sm={12} md={6} lg={4} xl={3}>
                        <TVProducts product={product}/>
                      </Col>

                    ))
                  }
                </Row>
                <Row className='mb-20'>
                  <Paginate pages={data.pages} page={data.page} keyword={keyword ? keyword : ''} />
                </Row>
                
              </>
            )
          }

        </div>
    </>
  )
}

export default TVprd;


















// import React, { useEffect, useState } from 'react'
// import axios  from 'axios';
// // import { useGetProductsQuery } from '../slices/productsApiSlice';
// import { Topbar } from '../components';
// // import { Image } from 'react-bootstrap';
// import TVProducts from '../components/TVProducts';
// import Pagination from '../components/Pagination';

// const TVprd = () => {

//     // const { data: products, isLoading, error } = useGetProductsQuery();
//     const [products, setProducts] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [productsPerPage] = useState(6);

//     useEffect(() => {
//       const fetchPosts = async () => {
//         setLoading(true);
//         const res = await axios.get('/api/products');
//         setProducts(res.data);
//         setLoading(false);
//       };

//       fetchPosts();
//     }, []);

//     // Get current posts
//     const indexOfLastProduct = currentPage * productsPerPage;
//     const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
//     const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

//     // Change page
//     const paginate = pageNumber => setCurrentPage(pageNumber);

//     // Check position of Y
//     const [scrollPosition, setScrollPosition] = useState(false);
//     const handleScroll = (e) => {
//         const { scrollTop } = e.target;
//         if (scrollTop > 20 ) {
//             setScrollPosition(true);
//         } else {
//             setScrollPosition(false);
//         }
//     };

//   return (
//     <>
//       <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
//             <Topbar />
//       </header>
      
//       <div className='w-screen h-full bg_gradiant_1 pt-28 pb-5 pr-24 pl-4 max-sm:p-4 overflow-x-scroll scrollbar' onScroll={handleScroll}>
//         <div className='container '>

//             <div className='container mt-2 mb-24'>
//               <h1 className='text-gray-200 mb-4'>تلویزیون</h1>
//               <TVProducts products={currentProducts} isLoading={loading} />
//               <Pagination
//                 productsPerPage={productsPerPage}
//                 totalProducts={products.length}
//                 paginate={paginate}
//                 currentPage={currentPage}
//                 setCurrentPage={setCurrentPage}
//               />
//             </div>

//         </div>
//       </div>
//     </>
//   )
// }

// export default TVprd;