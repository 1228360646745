import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ListGroup, Image, Form, Button, Card } from 'react-bootstrap';
import Message from '../components/navigation/Message';
import { Topbar } from '../components';
import { addToCart, removeFromCart } from '../slices/cartSlice';
import { BsBagXFill } from "react-icons/bs";
const Cart = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;

    // Check position of Y
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

    const numberFormat = (value) =>
        new Intl.NumberFormat('fa-IR', {
            style: 'currency',
            currency: 'IRR'
    }).format(value);
    // add to cart
    const addToCartHandler = async (product, qty) => {
        dispatch(addToCart({...product, qty}))
    };
    // remove from cart
    const removeFromCartHandler = async (id) => {
        dispatch(removeFromCart(id))
    };

    const checkoutHandler = () => {
        navigate('/login?redirect=/shipping')
    }

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>
 
        <div className='w-screen h-full bgservice pt-28 pb-5 pr-24 pl-4 max-sm:p-4 overflow-x-hidden overflow-y-scroll scrollbar' onScroll={handleScroll}>
            <div className='w-full px-8 mt-10 z-2 overflow-x-hidden overflow-y-scroll scrollbar text-gray-200 persianFont ' >
                <Row>
                    <Col md={8}>
                        <h1 className='mb-6'>لیست خرید</h1>
                        { cartItems.length === 0 ? (
                            <Message>
                                سبد خرید شما خالی می باشد
                                <Link to='/' className='mr-4 text-orange-400'>برگشت</Link>
                            </Message>
                        ) : (
                            <ListGroup variant='flush'>
                                { cartItems.map((item) => (
                                    <ListGroup.Item key={item._id}>
                                        <Row className='text-gray-200 items-center '>
                                            <Col md={2}>
                                                <Image src={ `data:image/jpeg;base64,${item.image[0]}` } alt={item.name} fluid rounded className='h-20'/>
                                            </Col>
                                            <Col md={3}>
                                                <Link to={`/product/${item._id}`}>{item.name}</Link>
                                            </Col>
                                            <Col md={2}>
                                                <strong>{ numberFormat(item.price) }</strong>
                                            </Col>
                                            <Col md={2}>
                                            <Form.Control className='p-0 h-6 text-center bg-transparent border-gray-600'
                                                as='select'
                                                value={item.qty}
                                                onChange={(e) => addToCartHandler(item, Number(e.target.value))}>
                                                    {[...Array(item.countInStock).keys()].map((x) => (
                                                        <option key={ x + 1 } value={ x + 1 } className='text-gray-900 text-xs bg-violet-400'>
                                                            { x + 1 }
                                                        </option>
                                                    ))}
                                            </Form.Control>
                                            </Col>
                                            <Col md={2}>
                                                <Button type='button' variant='light' className='flex align-middle' onClick={() => removeFromCartHandler(item._id) }>
                                                    <BsBagXFill />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        ) }
                    </Col>
                    <Col md={4}>
                        <Card className='bg-slate-300 '>
                            <ListGroup variant='flush'>
                                <ListGroup.Item >
                                    <h2>صورتحساب ({ cartItems.reduce((acc, item) => acc + item.qty, 0) }) مورد</h2>
                                    {numberFormat(cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2))}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Button type='button' 
                                            className='btn-block' 
                                            disabled={ cartItems.length === 0}
                                            onClick={ checkoutHandler }
                                            >
                                        پرداخت
                                    </Button>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    </>
  )
}

export default Cart;