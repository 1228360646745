import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Button, Card, ListGroupItem } from 'react-bootstrap';
import Message from '../components/navigation/Message';
import Loader from '../components/Loader';
import { 
    useGetOrderDetailsQuery,
    useDeliverOrderMutation
 } from '../slices/ordersApiSlice';
import { Topbar } from '../components';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IoIosClose } from "react-icons/io";
import { LiaEqualsSolid } from "react-icons/lia";

const Order = () => {

    const { id: orderId } = useParams();

    const { data: order, refetch, isLoading, error } = useGetOrderDetailsQuery(orderId);

    const [deliverOrder, {isLoading: loadingDeliver }] = useDeliverOrderMutation();

    const { userInfo } = useSelector((state) => state.auth);

    // const placeOrderHandler = async () => {
    //     try {
    //       await deliverOrder(orderId).unwrap();
    //     } catch (err) {
    //     }
    //   };

    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

    const numberFormat = (value) =>
        new Intl.NumberFormat('fa-IR', {
            style: 'currency',
            currency: 'IRR'
        }).format(value);

    const deliverOrderHandler = async () => {
        try {
            await deliverOrder(orderId);
            refetch();
            toast.success('ارسال شد');
        } catch (err) {
            toast.error(err?.data?.message || err.message);
        }
    }

  return isLoading ? <Loader/> : error ? <Message variant='danger'/> : (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
            <Topbar />
        </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 ordersBg overflow-scroll persianFont 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar'
            onScroll={handleScroll}
        >
            <h1>سفارش: {order._id}</h1>
            <Row>
                <Col md={8} className='flex flex-col space-y-2 persianFont'>
                    <ListGroup variant='flush' className='text-gray-300 flex flex-col space-y-2 persianFont'>
                        <h2>خرید</h2>
                        <p>
                            <strong>نام: </strong>{order.user.name}
                        </p>
                        <p>
                            <strong>شماره موبایل: </strong>{order.user.mobile}
                        </p>
                        <p>
                            <strong>آدرس: </strong>
                            {order.shippingAddress.city},{' '}{order.shippingAddress.address} 
                        </p>
                        { order.isDelivered ? (
                            <Message variant='success'>
                                ارسال شده است در زمان {order.deliveredAt}
                            </Message>
                        ) : (
                            <Message variant='danger'>
                                ارسال نشده است {order.deliveredAt}
                            </Message>
                        ) }
                    </ListGroup>
                    <ListGroup variant='flush' className='text-gray-300 flex flex-col space-y-2'>
                        <h2>روش پرداخت: </h2>
                        <p>
                            <strong>بانک: </strong>{order.paymentMethod}
                        </p>
                        { order.isPaid ? (
                            <Message variant='success'>
                                پرداخت شده است در زمان {order.paidAt}
                            </Message>
                        ) : (
                            <Message variant='danger'>
                                پرداخت نشده است {order.paidAt}
                            </Message>
                        ) }
                    </ListGroup>
                    <ListGroup variant='flush' className='text-gray-300 flex flex-col space-y-2'>
                        <h2>لیست سفارش: </h2>
                        <ListGroup.Item className='text-gray-300'>
                        { order.orderItems.map((item, index) => (
                            <Row key={index} className='p-1 border-gray-500 border-dashed border-b-[1px] items-center'>
                            <Col md={1}>
                                <Image src={`data:image/jpeg;base64,${item.image[0]}`} alt={item.name} fluid rounded/>
                            </Col>
                            <Col>
                                <Link to={`/product/${item._id}`}>
                                    {item.name}
                                </Link>
                            </Col>
                            <Col md={4} dir='ltr' className='flex align-middle'>
                                {item.qty} 
                                <IoIosClose className='m-auto'/> 
                                {item.price} 
                                <LiaEqualsSolid className='m-auto'/> 
                                {numberFormat(item.qty * item.price)}
                            </Col>
                        </Row>
                        ))}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={4}>
                    <Card>
                        <ListGroup variant='flush' className='text-sm'>
                        <ListGroup.Item >
                            <h2>خلاصه سفارش</h2>
                        </ListGroup.Item>
                        <ListGroup.Item >
                            <Row>
                                <Col>اقلام: </Col>
                                <Col>{numberFormat(order.itemsPrice)}</Col>
                            </Row>

                            <Row>
                                <Col>هزینه ارسال: </Col>
                                <Col>{numberFormat(order.shippingPrice)}</Col>
                            </Row>

                            <Row>
                                <Col>9% مالیات: </Col>
                                <Col>{numberFormat(order.taxPrice)}</Col>
                            </Row>

                            <Row>
                                <Col>جمع کل: </Col>
                                <Col>{numberFormat(order.totalPrice)}</Col>
                            </Row>
                        </ListGroup.Item>

                        {/* PAY ORDER PLACEHOLDER */}

                        {loadingDeliver && <Loader/>}
                        {userInfo && userInfo.isAdmin && (
                            <ListGroupItem>
                                <Button type='button' variant="outline-light" onClick={deliverOrderHandler}>
                                    ارسال
                                </Button>
                            </ListGroupItem>
                        )}

                        </ListGroup>
                    </Card>
                </Col>
            </Row>

        </div>
    </>
  )
}

export default Order;