import React, { useState } from "react"
import { Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';

import { IoSearchOutline } from "react-icons/io5";

const SearchBox = () => {

    const navigate = useNavigate();
    const { keyword: urlKeyword } = useParams();
    const [keyword, setKeyword] = useState(urlKeyword || '');

    const submitHandler = (e) => {
        e.preventDefault();
        if (keyword.trim()) {
            // setKeyword('');
            navigate(`/search/${keyword}`);
        } else {
            navigate('/');
        }
    }

  return (
    <Form onChange={submitHandler} className="d-flex w-full">
        <Form.Control
        type="text"
        name="q"
        onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
        placeholder="جستجوی محصول..."
        className="mr-sm-2 ml-sm-5 rounded-none bg-transparent border-slate-700 text-gray-200 persianFont"
        ></Form.Control>
        <Button type="submit" variant="outline-secondary" className="py-1 px-3 mx-2 rounded-none">
            <IoSearchOutline/>
        </Button>
    </Form>
  )
}

export default SearchBox;