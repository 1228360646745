import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const EventChilds = ({ event }) => {

  return (
    <>
      {/* { isLoading ? (
        <Loader/>
      ) : (
        <Row>
        {events.map(event => (
          <Col sm={12} md={4} lg={2} xl={2}>
            <Card className='my-3 p-3 bg-cl_bg_4 h-48 rounded  hover:outline hover:outline-blue-700 transition-all'>
              <a href={`/event/${event._id}`}  className='w-full '>
                <div className='h-24 mb-1'>
                  <Image src={`${event.figure}`} alt={ event.title } fluid className='m-auto h-full col-img object-cover'/>
                </div>
                <div >
                  <h3 className='text-gray-300  truncate'>{ event.title }</h3>
                </div>
                
              </a>
            </Card>
          </Col>
          
        ))}
      </Row>
      )} */}
      <Card className='my-3 p-3 bg-cl_bg_4 h-fit rounded hover:outline hover:outline-blue-700 transition-all persianFont'>
        <Link rel="preload" to={`/event/${event._id}`}>
          <Card.Img src={event.figure[0]}  alt={ event.title } variant='top' />
          <Card.Body>
            <Link rel="preload" to={`/event/${event._id}`}>
              <Card.Title as='div' className='product-title persianFont'>
                <strong className='persianFont'>{event.name}</strong>
              </Card.Title>
            </Link>

            <Card.Text as='h2' className='text-gray-300 truncate text-sm persianFont'>{event.title}</Card.Text>
            <Card.Text as='h2' className='text-gray-400 line-clamp-2 text-xs mt-2 truncate persianFont'>{event.lead}</Card.Text>
          </Card.Body>
        </Link>
      </Card>
      
      
    </>
  );
};

export default EventChilds;