import React, { useState } from 'react'
import { Topbar } from '../components'
import { useGetGeneralsQuery } from '../slices/generalApiSlice';
import { Col, Row } from 'react-bootstrap';

const Ethics = () => {

    const { data: generals } = useGetGeneralsQuery();

    // Check position of Y
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

    // const submitHandler = async (e) => {

    // }

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
        <Topbar /> 
        </header>
        
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
                xl:px-28 lg:px-28 2xl:px-28 bg-slate-900 overflow-scroll 
                overflow-x-hidden overflow-y-auto text-gray-400 scrollbar persianFont'
                onScroll={handleScroll}
        >
            <Row>
                
                {
                    generals && generals.slice(0, 1).map(general => (
                        <>
                            <Row className='mb-20'>
                                <Col md={8} key={general._id} className='px-10 space-y-10 m-auto'> 
                                    <h1 className='persianFont text-center'>اخلاق سازمانی</h1>
                                    <p className='persianFont text-justify whitespace-pre-line'> { general.ethics } </p>
                                </Col>
                            </Row>
                        </>
                    ))
                }
            </Row>
        </div>
    </>
  )
}


export default Ethics;