import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { Link } from "react-router-dom";

export default function ServiceMenu() {

    return (

            <div className='h-[97vh] w-[360px] persianFont z-10 absolute right-0 my-[1.5vh] mr-[96px] rounded-lg bg-cl_bg_1 backdrop-blur-sm transition-colors persianFont'>
                <h1 className="flex persianFont text-gray-400 py-4 px-4"><HiMiniWrenchScrewdriver className='ml-4' size={35}/>خدمات پس از فروش</h1>
                <ul>
                    <li className="persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                        <Link to='/service' className='persianFont block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden'>
                            نمایندگان خدمات پس از فروش
                        </Link>
                    </li>
                    <li className="persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                        <a href="http://service.parselectric.ir/Consoles/VirtualReception/" className=" persianFont block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden">
                            درخواست نصب یا تعمیر
                        </a>
                    </li>
                    <li className="persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                        <a href="http://service.parselectric.ir/Consoles/Recommend/" className="persianFont block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden">
                            رسیدگی به شکایات
                        </a>
                    </li>
                </ul>
            </div>

        
    )

}