import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Form, Button, Col } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import { savePaymentMethod } from '../slices/cartSlice';
import { Topbar } from '../components';

const Payment = () => {

    const [paymentMethod, setPaymentMethod] = useState('refah');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cart = useSelector((state) => state.cart);
    const { shippingAddress } = cart;

    useEffect(() => {
        if (!shippingAddress) {
            navigate('/shipping');
        }
    }, [shippingAddress, navigate])

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(savePaymentMethod(paymentMethod));
        navigate('/placeorder');
    }

    // scrolling to hide topbar
    const [scrollPosition, setScrollPosition] = useState(false);
    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        if (scrollTop > 20 ) {
            setScrollPosition(true);
        } else {
            setScrollPosition(false);
        }
    };

  return (
    <>
        <header className={`fixed z-[4] transition-all ${scrollPosition ? '!top-[-100px]' : '!top-[0]'}`}>
                <Topbar />
            </header>
        <div className='w-screen h-full pt-28 max-sm:px-8 max-md:px-8 md:p-28 
            xl:px-28 lg:px-28 2xl:px-28 ordersBg overflow-scroll 
            overflow-x-hidden overflow-y-auto text-gray-400 scrollbar'
            onScroll={handleScroll}
        >
            <FormContainer>
                <CheckoutSteps step1 step2 step3 />
                <h1>روش پرداخت</h1>
                <Form onSubmit={submitHandler}>
                    <Form.Group>
                        <Form.Label className='mt-3'>انتخاب روش پرداخت</Form.Label>
                        <Col>
                            <Form.Check
                                type='radio'
                                className='my-2 mr-8'
                                label='بانک رفاه'
                                id='refah'
                                name='paymentMethod'
                                value='refah'
                                checked
                                onChange={(e) => setPaymentMethod(e.target.value)}
                                >
                            </Form.Check>
                        </Col>
                    </Form.Group>

                    <Button type='submit' variant="outline-light" className='my-2 pb-2'>ادامه</Button>
                </Form>
            </FormContainer>
        </div>
    </>
  )
}

export default Payment