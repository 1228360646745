// import SearchBox from "../SearchBox";

// import { useGetProductsQuery } from "../../slices/productsApiSlice";
// import Message from "./Message";
// import Loader from "../Loader";
// import { useParams } from "react-router-dom";

export default function SearchMenu() {

    return (
  
            <div className='h-[97vh] w-[360px] persianFont z-10 absolute right-0 my-[1.5vh] mr-[96px] rounded-lg bg-cl_bg_1 backdrop-blur-sm transition-colors '>
                <div className="persianFont input-group w-full p-4">
                <input 
                    className="persianFont form-control bg-transparent rounded-none border-slate-700 text-gray-200 "
                    placeholder="جستجو..."
                    type="search" 
                    name="search" 
                />
                    {/* <SearchBox/> */}
                </div>
            </div>

        
    )

}