import { AGENTS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const agentsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAgents: builder.query({
            query: () => ({
                url: AGENTS_URL,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Agents'],
        }),
        getAgentDetails: builder.query({
            query: (agentId) => ({
                url: `${AGENTS_URL}/${agentId}`,
            }),
            keepUnusedDataFor: 5,
        }),
        createAgent: builder.mutation({
            query: () => ({
                url: `${AGENTS_URL}`,
                method: 'POST',
            }),
            invalidatesTags: ['Agent'],
        }),
        updateAgent: builder.mutation({
            query: (data) => ({
                url: `${AGENTS_URL}/${data.agentId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Agents'],
        }),
        deleteAgent: builder.mutation({
            query: (agentId) => ({
                url: `${AGENTS_URL}/${agentId}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const { 
    useGetAgentsQuery, 
    useGetAgentDetailsQuery,
    useCreateAgentMutation,
    useUpdateAgentMutation,
    useDeleteAgentMutation,
} = agentsApiSlice;