import { Container, Row, Col } from 'react-bootstrap';

const FormContainer = ({ children }) => {
  return (
    <Container>
        <Row className='justify-content-md-center'>
            <Col xs={11} md={5}>
                { children }
            </Col>
        </Row>
    </Container>
  )
}

export default FormContainer