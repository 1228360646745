import { FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function AboutUsMenu() {

    return (
            <div className='h-[97vh] w-[360px] z-10 absolute right-0 my-[1.5vh] mr-[96px] rounded-lg bg-cl_bg_1 backdrop-blur-sm transition-colors persianFont'>
                <h1 className="flex text-gray-400 py-4 px-4"><FaUsers className='ml-4' size={35}/>درباره ما</h1>
                <ul>
                    <li className="persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                        <Link to='/aboutus' className='block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden persianFont'>
                            در یک نگاه  
                        </Link>
                    </li>
                    <li className="persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                        <Link to='/responsibility' className='block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden persianFont'>
                            مسئولیت اجتماعی  
                        </Link>
                    </li>
                    <li className="persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                        <Link to='/https://www.shakhesban.com/markets/stock/%D9%84%D9%BE%D8%A7%D8%B1%D8%B3' className='block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden persianFont'>
                            گزارش سالیانه  
                        </Link>
                    </li>
                    <li className="persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                        <Link to='/security' className='block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden persianFont'>
                            امنیت و حریم خصوصی   
                        </Link>
                    </li>
                    <li className="persianFont text-gray-400 w-[0%] hover:w-[100%] hover:text-gray-900 bg-slate-400 transition-all">
                        <Link to='/sitemap' className='block w-[360px] py-3 px-4 border-b border-slate-700 overflow-hidden persianFont'>
                            Site Map  
                        </Link>
                    </li>
                </ul>
            </div>
    )
}